/** @format */

import React, { useEffect, useState } from "react";
import "./inventoryDashboard.css";
import axios from "axios";
import ViewMore from "./viewmore";
import Cookies from "js-cookie";
import { GrPrevious, GrNext } from "react-icons/gr";
import Papa from "papaparse";
import usePermissions from "../../../components/permissions/permission";

export default function InventoryDashboard() {
  const { hasPermission } = usePermissions();

  const currentDate = new Date(); // Get the current date and time
  const formattedDate = currentDate.toISOString(); // Format the date to ISO string

  //get all available items

  const [TotalAvailableItems, setTotalAvailableItems] = useState([]);
  const [viewMoreData, setViewMoreData] = useState(null);
  const [viewMoreOpen, setViewMoreOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10000);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const [searchStore, setSearchStore] = useState("");
  const [storeOptions, setStoreOptions] = useState([]);

  const GetAllAvailableItems = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/Dashboard/stock-data?page=${page}&pageSize=${pageSize}`
      );
      if (!res.ok) {
        // Log the response text for debugging purposes
        const errorText = await res.text();
        console.error("Error occurred while fetching items data:", errorText);
        throw new Error("Failed to fetch items data");
      }
      const data = await res.json();
      setTotalAvailableItems(data.items);
    } catch (error) {
      console.error("Error occurred while fetching items data:", error);
    }
  };

  useEffect(() => {
    GetAllAvailableItems();
    loadAllStores();
  }, []);

  const handleViewMore = (inventory) => {
    setViewMoreData(inventory);
    setViewMoreOpen(true);
  };
  // Close dialog
  const closeDialog = () => {
    setViewMoreData("");
    setViewMoreOpen(false);
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleStoreChange = (e) => {
    setSearchStore(e.target.value);
  };

  // Pagination Logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const filteredItems = TotalAvailableItems.filter((item) => {
    const searchMatch =
      !searchQuery ||
      item.item_name?.toLowerCase().includes(searchQuery.toLowerCase());

    const storeMatch =
      !searchStore ||
      item.batches.some((batch) => {
        return batch.store_id && searchStore
          ? batch.store_id.toLowerCase() === searchStore.toLowerCase()
          : false;
      });

    return searchMatch && storeMatch;
  });

  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // const ExcelExport = () => {
  //   const csvData = currentItems.map((inventory) => ({
  //     "Item ID": inventory.item_id,
  //     "Item Name": inventory.item_name,
  //     "Item Measure Unit": inventory.item_measure_unit,
  //     "Item Category ID": inventory.item_category_id,
  //     "Available Qty": inventory.total_shadow_qty,
  //   }));

  //   const csv = Papa.unparse(csvData);

  //   // Trigger file download
  //   const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  //   const link = document.createElement("a");
  //   const url = URL.createObjectURL(blob);
  //   link.setAttribute("href", url);
  //   link.setAttribute("download", "Inventory Dashboard.csv");
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  const ExcelExport = () => {
    const csvData = TotalAvailableItems.flatMap((item) => {
      return item.batches.map((batch) => ({
        "Item ID": item.item_id,
        "Item Name": item.item_name,
        "Item Measure Unit": item.item_measure_unit,
        "Item Category ID": item.item_category_id,
        "Available Qty": item.total_shadow_qty,
        "Item Batch ID": batch.inventory_batch_id,
        "Current Qty": batch.current_qty,
        "Manufacture Date": batch.manufacture_date,
        "Expiration Date": batch.expiration_date,
        Store: batch.store_id,
        Location: batch.location,
      }));
    });

    const csv = Papa.unparse(csvData);

    // Trigger file download
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "Inventory Dashboard.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const loadAllStores = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/store`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Fetched Data:", data); // For debugging

        // Assuming 'data' contains a 'store' array
        const stores = data.map((storeOptions) => ({
          value: storeOptions.store_id,
          label: storeOptions.store_name,
        }));

        console.log("Store Options:", stores); // Verify the store options
        setStoreOptions(stores);
      } else {
        const errorData = await response.json();
        console.error("Failed to fetch stores:", errorData);
      }
    } catch (error) {
      console.error("Error fetching stores:", error);
    }
  };

  // meke csv ekata title enne kohenda kiyannako

  return (
    <div className="p-10 bg-background">
      <p className="header-1">Inventory Dashboard</p>

      {/* <div className="flex justify-between items-center w-full">
        {hasPermission(1280) && (
          <Card1
            title1="Total "
            title2="Available"
            subTitle="Item Qty."
            value={TotalAvailableItems.length}
            img={Cube}
          />
        )}{" "}
        {hasPermission(1281) && (
          <Card1
            title1="Total "
            title2=""
            subTitle="Item Qty."
            value="22"
            img={Sphere}
          />
        )}
        {hasPermission(1282) && (
          <Card1
            title1="Total "
            title2=""
            subTitle="Item Qty."
            value="22"
            img={Toroid}
          />
        )}
      </div> */}

      <div className="flex justify-between overflow-hidden pb-6">
        <div>
          <div className="flex flex-col font-poppins font-bold ml-6 mt-4">
            <div className="relative font-sans group hidden sm:block">
              <input
                type="text"
                placeholder="Search by Item Name"
                value={searchQuery}
                onChange={handleSearchChange}
                className="w-[200px] sm:w-[300px] h-10 duration-300 rounded-full border border-white px-2 py-1"
              />
            </div>
          </div>
          <div className=" flex relative font-sans group sm:block">
            <select
              id="storeFilter"
              name="storeFilter"
              value={searchStore}
              onChange={handleStoreChange}
              className="w-[200px] font-bold ml-6 mt-4 sm:w-[300px] h-10 duration-300 rounded-full border border-white px-2 py-1"
            >
              <option value="">Filter By Store</option>
              {storeOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className=" flex relative font-sans group sm:block">
            <button
              className="w-[200px] h-[45px] flex items-center justify-center bg-newBackground border rounded-[23px] px-4 p2 text-white  mt-10 ml-6"
              onClick={ExcelExport}
            >
              Export CSV
            </button>
          </div>
        </div>
      </div>

      <div className="flex flex-col font-sans font-bold ml-6 mt-6 rounded-xl item">
        <table className="table-auto border-none w-[95%] ">
          <thead>
            <tr className="bg-white">
              <th className="text-primary px-4 py-2">Item ID</th>
              <th className="text-primary px-4 py-2">Item Name</th>
              <th className="text-primary px-4 py-2">Item Measure Unit</th>
              <th className="text-primary px-4 py-2">Item Category ID</th>
              <th className="text-primary px-4 py-2">Available Qty</th>
              <th className="text-primary px-4 py-2">Action</th>
            </tr>
          </thead>
          <tbody>
            {/* Error state */}

            {/* No data state */}
            {currentItems.length === 0 && (
              <tr>
                <td colSpan="6" className="bg-white px-4 py-2 text-center">
                  No data available
                </td>
              </tr>
            )}
            {/* Render data */}
            {currentItems.map((inventory) => (
              <tr key={inventory.item_id} className="">
                <td className="bg-white px-4 py-2 text-center">
                  {inventory.item_id}
                </td>
                <td className="bg-white px-4 py-2 text-center">
                  {inventory.item_name}
                </td>
                <td className="bg-white px-4 py-2 text-center">
                  {inventory.item_measure_unit}
                </td>
                <td className="bg-white px-4 py-2 text-center">
                  {inventory.item_category_id}
                </td>
                <td className="bg-white px-4 py-2 text-center">
                  {inventory.total_shadow_qty}
                </td>
                <td className="bg-white px-4 py-2 text-center">
                  <button
                    className="text-primary hover:underline"
                    onClick={() => handleViewMore(inventory)}
                  >
                    View More
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="mb-4 mt-4">
        <div
          className="flex justify-center mt-4"
          style={{ maxWidth: "80%", margin: "0 auto" }}
        >
          {/* Previous Button */}
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            className="bg-primary text-white px-4 py-2 mx-1 rounded-md"
          >
            <GrPrevious className="w-3 h-3" />
          </button>

          {/* First Page */}
          {currentPage > 3 && (
            <>
              <button
                onClick={() => paginate(1)}
                className={`${
                  currentPage === 1 ? "bg-primary text-white" : "bg-white"
                } px-4 py-2 mx-1 rounded-md`}
              >
                1
              </button>
              <span className="px-2 py-2">...</span>{" "}
              {/* Ellipsis before current page group */}
            </>
          )}

          {/* Dynamic Page Numbers */}
          {Array.from({
            length: Math.min(
              Math.ceil(TotalAvailableItems.length / itemsPerPage),
              5 // Adjust this number to control how many pages to show around the current page
            ),
          }).map((_, index) => {
            const pageIndex = currentPage - 2 + index;
            if (
              pageIndex < 1 ||
              pageIndex > Math.ceil(TotalAvailableItems.length / itemsPerPage)
            ) {
              return null;
            }
            return (
              <button
                key={pageIndex}
                onClick={() => paginate(pageIndex)}
                className={`${
                  currentPage === pageIndex
                    ? "bg-primary text-white"
                    : "bg-white"
                } px-4 py-2 mx-1 rounded-md`}
              >
                {pageIndex}
              </button>
            );
          })}

          {/* Last Page */}
          {currentPage <
            Math.ceil(TotalAvailableItems.length / itemsPerPage) - 2 && (
            <>
              <span className="px-2 py-2">...</span>{" "}
              {/* Ellipsis after current page group */}
              <button
                onClick={() =>
                  paginate(Math.ceil(TotalAvailableItems.length / itemsPerPage))
                }
                className={`${
                  currentPage ===
                  Math.ceil(TotalAvailableItems.length / itemsPerPage)
                    ? "bg-primary text-white"
                    : "bg-white"
                } px-4 py-2 mx-1 rounded-md`}
              >
                {Math.ceil(TotalAvailableItems.length / itemsPerPage)}
              </button>
            </>
          )}

          {/* Next Button */}
          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={
              currentPage ===
              Math.ceil(TotalAvailableItems.length / itemsPerPage)
            }
            className="bg-primary text-white px-4 py-2 mx-1 rounded-md"
          >
            <GrNext className="w-3 h-3" />
          </button>
        </div>
      </div>

      {viewMoreOpen && (
        <ViewMore
          onClose={closeDialog} // Pass the dispose_items as a prop
          batchItems={viewMoreData}
        />
      )}
    </div>
  );
}
