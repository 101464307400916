 

import React, { useState } from "react";
import WarehouseTable from "./WarehouseAdjustmentTable";
import WareHoseAdjustmentData from "./WarehouseAdjustmentData";

function WarehouseAdjustment() {
  const [selectedJobId, setSelectedJobId] = useState(null);

  const handleRowClick = (jobId) => {
    setSelectedJobId(jobId);
  };
  console.log("Job ID:", selectedJobId);

  return (
    <div>
      <p className="text-[32px] ml-6 mt-5 bg-newBackground2 w-[100%] rounded-xl">
        Warehouse Adjustment
      </p>
      <div className="grid grid-flow-row-dense grid-cols-5 h-screen overflow-y-auto">
        <div className="col-span-2">
          <WarehouseTable setSelectedJobId={handleRowClick} />{" "}
        </div>
        <div className="col-span-3 overflow-y">
          <WareHoseAdjustmentData jobId={selectedJobId} />
        </div>
      </div>
    </div>
  );
}

export default WarehouseAdjustment;
