/** @format */

import React, { useEffect, useState, useRef } from "react";
import AsyncSelect from "react-select/async";
import { useReactToPrint } from "react-to-print";

import Arrow from "../../../Assets/icons/drop down correct 1.png";
import Printer from "../../../Assets/icons/print 1.png";
import Cookies from "js-cookie";
import axios from "axios";
import IdGenerate from "../../../utils/id_generate";
import jsPDF from "jspdf";
import "jspdf-autotable";

export default function MobilePOSItemReturnReq() {
  const currentDate = new Date(); // Get the current date and time
  const formattedDate = currentDate.toISOString().split("T")[0];
  const userId = Cookies.get("employee_no"); // Format the date to ISO string
  const branchId = Cookies.get("branch");
  // const [userId, setUserId] = useState("USER-000000");
  const [userName, setUserName] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [tableData, setTableData] = useState({ items: [] });
  const [netTotal, setNetTotal] = useState(0);
  const [pos_id, setPosId] = useState("");

  const [alert, setAlert] = useState({
    status: "",
    alert: "",
    show: false,
  });

  // Function to handle search
  const handleItemSearch = async (inputValue) => {
    if (inputValue !== "") {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT}/v1/inventory/pos/mobile/getInvoicebyInvoiceID?pos_id=${inputValue}`
        );
        console.log(res.data);
        setTableData(res.data); // Update table data with API response
      } catch (error) {
        console.error(error);
      }
    } else {
      return [];
    }
  };

  // Counter to keep track of unique IDs

  const SubmitHandler = async () => {
    // Validation: Check if no items or any requested quantity is missing
    if (
      tableData.length === 0 ||
      tableData.some(
        (item) =>
          !item.item_requested_qty || parseFloat(item.item_requested_qty) <= 0
      )
    ) {
      window.alert(
        "Please input items and ensure each item has a requested quantity greater than 0."
      );
      return;
    }

    const items = tableData.map((item) => ({
      batch_id: item.inventory_batch_id,
      return_qty: parseFloat(item.item_requested_qty), // Ensure it's a number
      return_description: item.item_remarks,
    }));

    const data = {
      branch_id: branchId,
      return_by: userId,
      items,
    };

    console.log("send data", data);
    //check conditions

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/v1/inventory/BracnReturn/add-stock-return`,
        data
      );
      console.log(res.data);
      console.log(res.status);

      if (res.status === 200 || res.status === 201) {
        // console.log("hello:");
        window.alert("Successfully Added Branch Item Return Request");
        ResetHandler();
      }
    } catch (error) {
      setAlert({
        status: "error",
        alert: "Something Went Wrong",
        show: true,
      });
    }
  };
  const ResetHandler = () => {
    // setUserId("USER-000000");
    setDateFrom("");
    setSelectedItem(null);
    setTableData([]);
  };

  const contentToPrint = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => contentToPrint.current,
    documentTitle: "Print This Document",
    onBeforePrint: () => console.log("before printing..."),
    onAfterPrint: () => console.log("after printing..."),
    removeAfterPrint: true,
  });

  useEffect(() => {
    if (tableData?.items?.length) {
      const total = tableData.items.reduce(
        (acc, item) => acc + (item.line_amount || 0),
        0
      );
      setNetTotal(total);
    } else {
      setNetTotal(0);
    }
  }, [tableData]);

  const pdfExport = () => {
    const doc = new jsPDF();

    const columns = [
      "Item Name",
      "Released Qty",
      "Return Qty",
      "Units",
      "Price(LKR)",
      "Line Amount",
    ];

    const rows = tableData.map((item) => [
      item.item_name,
      item.inventory_release_item_qty,
      item.item_return_qty,
      item.item_measure_unit,
      item.batch_unit_price,
      item.line_amount,
    ]);

    doc.text("Mobile POS Item Return Request", 14, 10);

    doc.autoTable({
      head: [columns],
      body: rows,
      startY: 20,
    });

    doc.save("Mobile POS Item Return Request.pdf");
  };

  return (
    <div className="p-10 bg-background">
      {/* <TopNaw moduleName ='Inventory Items Purchase' userName={userName}/> */}
      <p className="header-1">Item Return Request From Mobile POS</p>

      <div className="w-full flex items-center justify-center px-12 mt-[5%]">
        <div className="bg-PrimaryColor1 w-full h-[82px] rounded-[100px] mx-20 flex items-center justify-between">
          <p className="label-1 ml-10 text-white">POS ID :</p>
          <input
            className="w-[400px] border border-[#656565] my-2 rounded-2xl"
            style={{ paddingLeft: "15px" }}
            value={pos_id}
            onChange={(e) => setPosId(e.target.value)} // Update pos_id state on input change
          />
          <button
            className="w-[120px] h-[46px] text-white label-1 rounded-[100px]"
            onClick={() => handleItemSearch(pos_id)} // Call handleItemSearch with pos_id when clicked
          >
            Search
          </button>
        </div>
      </div>

      {/* table */}
      <div className="w-full flex items-center justify-center my-10">
        <div className="bg-[#F3F8FF] w-full min-h-96 mx-16 rounded-t-[40px] rounded-b-[50px]">
          <div className="w-full h-20 bg-PrimaryColor2 rounded-[100px] flex items-center justify-around">
            <p className="w-10 p1 text-[#656565] text-center">#</p>
            <div className="w-[350px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Item Name</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[160px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">
                Released Qty
              </p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[160px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">
                Return Qty
              </p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[120px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Units</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[120px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">
                Price(LKR)
              </p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[170px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">
                Line Amount
              </p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
          </div>
          <div className="">
            {tableData?.items?.length > 0
              ? tableData.items.map((item, index) => (
                  <div
                    key={index}
                    className="w-full flex items-start justify-around my-3 "
                  >
                    <p className="w-10 p1 text-[#656565] text-center">
                      {index + 1}
                    </p>
                    <p className="w-[350px] p1 text-[#656565] text-center ">
                      {item.item_name}
                    </p>
                    <p className="w-[160px] p1 text-[#656565] text-center">
                      {item.inventory_release_item_qty}
                    </p>
                    <p className="w-[100px] p1 text-[#656565] text-center">
                      <input
                        type="number"
                        value={item.item_return_qty}
                        min="0"
                        className="focus:outline-none border-[#00000059] rounded-2xl w-full border-2 px-1"
                        onChange={(e) => {
                          let temp = [...tableData.items];
                          let inputValue = parseFloat(e.target.value);

                          if (inputValue > item.inventory_release_item_qty) {
                            inputValue = item.inventory_release_item_qty;
                          } else if (inputValue < 0) {
                            inputValue = 0;
                          }

                          temp[index].item_return_qty = inputValue;
                          temp[index].line_amount =
                            inputValue * item.batch_unit_price;

                          setTableData({ ...tableData, items: temp }); // Update the tableData with new values
                        }}
                      />
                    </p>
                    <p className="w-[120px] p1 text-[#656565] text-center">
                      {item.item_measure_unit}
                    </p>
                    <p className="w-[120px] p1 text-[#656565] text-center">
                      {item.batch_unit_price}
                    </p>
                    <p className="w-[120px] p1 text-[#656565] text-center">
                      {item.line_amount}
                    </p>
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>

      <div className="w-full flex justify-end items-start px-16 pt-3 ">
        <div className="w-[350px] h-full  flex flex-col gap-y-2">
          <div className="w-[350px] h-full rounded-[23px] bg-[#F3F8FF] flex flex-col px-4 py-3">
            <div className="flex items-center justify-between">
              <p className="p1 text-[#656565]"> Net Total</p>
              <p className="p1 text-[#656565]">{netTotal.toFixed(2)}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex w-full items-center justify-end gap-x-10 my-10">
        <button className="w-[138px] h-[41px] flex items-center justify-between bg-white border rounded-[23px] px-5">
          <img src={Printer} alt="" />
          <p
            className="p2 text-[#656565]"
            // onClick={() => {
            //   handlePrint(null, () => contentToPrint.current);
            // }}

            onClick={pdfExport}
          >
            Print
          </p>
        </button>
        <button className="w-[118px] h-[45px] flex items-center justify-center bg-[#AA4343] border rounded-[23px] px-4 text-white font-bold text-xl">
          Cancel
        </button>
        <button
          className="w-[118px] h-[45px] flex items-center justify-center bg-newBackground border rounded-[23px] px-4 text-white font-bold text-xl"
          onClick={SubmitHandler}
        >
          Submit
        </button>
      </div>

      {/* Print layout */}

      {/* <div className="p-10 hidden">
        <div className="p-10" ref={contentToPrint}>
          <p className="header-1">Inventory Purchase (GRN)</p>

          <div id="printable" className="formal-document">
            <h1 className="document-title">Goods Received Note</h1>
            <div className="document-section">
              <h2 className="section-title">Purchase Order Details</h2>
              <div className="section-content">
                <p>
                  <strong>Purchase Order Id:</strong> {PoData[0].po_id}
                </p>
                <p>
                  <strong>Supplier:</strong> {PoData[0].supplier_name}
                </p>
                <p>
                  <strong>PO Date:</strong> {PoData[0].po_issue_date}
                </p>
              </div>
            </div>

            <div className="document-section">
              <h2 className="section-title">Purchasing Items</h2>
             
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}
