import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import UserIcon from "../../../Assets/icons/user 5.png";
import axios from "axios";
import AddNewDependent from "./add_new_dependent";
import AddNewEmploymentQualification from "./add_new_employmentQualification";
import AddnewTraning from "./add_new_traning";
import Addnewdisciplinary from "./add_newdiscilinary";
import Addnewdocument from "./add_new_uploadfile";
import { IoCloseSharp } from "react-icons/io5";
import AddImage from "./add_image";

const UserProfile = () => {
  const [designations, setDesignations] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({});
  const [dependentIsOpen, setDependentIsOpen] = useState(false);
  const [AcadamicIsOpen, setAcadamicIsOpen] = useState(false);
  const [TraningIsOpen, setTraningIsOpen] = useState(false);
  const [disciplinaryIsOpen, sethandleToggledisciplinary] = useState(false);
  const [documentIsOpen, sethandleToggledocument] = useState(false);
  const [fetchKey, setFetchKey] = useState(0);
  const [profileIsOpen, sethandleToggleprofile] = useState(false);
  const { REACT_APP_API_ENDPOINT } = process.env;

  const { employee_no } = useParams();
  const [employeeData, setEmployeeData] = useState(null);
  const [TraningSections, setTraningSections] = useState(0);
  const [itemSections, setItemSections] = useState(0);

  const handledisciplinaryChange = (e, index, field) => {
    const { value } = e.target;
    const updatedDependents = [...employeeData.disciplinary_details];
    updatedDependents[index][`employee_dependent_${field}`] = value;
    const data = { ...employeeData };
    data.disciplinary_details[index].employee_disciplinary_call_date = value;
    setEmployeeData(data);
  };

  const handleTrainingChange = (e, index, field) => {
    const { value } = e.target;
    const updatedTraining = { ...employeeData };
    updatedTraining[index][field] = value;
    setEmployeeData((prevState) => ({
      ...prevState,
      training_details: updatedTraining,
    }));
  };

  const handleRemoveDependent = (index) => {
    const updatedDependents = [...employeeData.dependent_details];
    updatedDependents.splice(index, 1);
    setEmployeeData({ ...employeeData, dependent_details: updatedDependents });
  };

  const handleRemoveAcademicQualification = (index) => {
    const updatedQualifications = [...employeeData.academic_qualifications];
    updatedQualifications.splice(index, 1);
    setEmployeeData({
      ...employeeData,
      academic_qualifications: updatedQualifications,
    });
  };

  const handleRemoveTraningdevelopment = (index) => {
    const updatedtraning = [...employeeData.training_details];
    updatedtraning.splice(index, 1);
    setEmployeeData({
      ...employeeData,
      training_details: updatedtraning,
    });
  };

  useEffect(() => {
    if (employeeData && employeeData.length > 0) {
      setFormData(employeeData);
    }
  }, [employeeData]);

  // Event handler to toggle edit mode
  const toggleEditMode = () => {
    setEditMode((prevMode) => !prevMode);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEmployeeData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const qualificationNumber = (index) => {
    return index + 1;
  };

  const dependantNumber = (index) => {
    return index + 1;
  };

  const handleUpdateProfile = () => {
    const employeeNo = document.getElementById("employee_no").value;
    const fullName = document.getElementById("employee_fullname").value;
    const nameInitial = document.getElementById("employee_name_initial").value;
    const nic = document.getElementById("employee_nic").value;
    const dob = document.getElementById("employee_dob").value;
    const gender = document.getElementById("employee_gender").value;
    const maritalStatus = document.getElementById(
      "employee_marital_status"
    ).value;
    const contactNo = document.getElementById("employee_contact_no").value;
    const permanentAddress = document.getElementById(
      "employee_permanent_address"
    ).value;
    const temporaryAddress = document.getElementById(
      "employee_temparary_address"
    ).value;
    const policeDivision = document.getElementById(
      "employee_police_division"
    ).value;
    const gramaSewaDivision = document.getElementById(
      "employee_gramasewa_division"
    ).value;
    const email = document.getElementById("employee_email").value;
    const designation = document.getElementById("employee_designation").value;
    const department = document.getElementById("employee_department").value;
    const dateOfAppointmentElement =
      document.getElementById("date_of_appoinment");
    const dateOfAppointment = dateOfAppointmentElement
      ? dateOfAppointmentElement.value
      : null;
    const probationComplete =
      document.getElementById("probation_complete").value;
    const permanentAppointmentDate = document.getElementById(
      "permanent_appointment_date"
    ).value;
    const firstEBCompletionDate = document.getElementById(
      "first_eb_completion_date"
    ).value;
    const secondEBCompletionDate = document.getElementById(
      "second_eb_completion_date"
    ).value;
    const thirdEBCompletionDate = document.getElementById(
      "third_eb_completion_date"
    ).value;
    const epfNo = document.getElementById("employee_epf_no").value;
    const accountNo = document.getElementById("employee_account_no").value;
    const bankName = document.getElementById("employee_bank_name").value;
    const branchName = document.getElementById("employee_branch_name").value;
    const bankCode = document.getElementById("employee_bank_code").value;
    const branchCode = document.getElementById("employee_branch_code").value;
    const basicsalary = document.getElementById("employee_basic_salary").value;
    const editdescription = document.getElementById("edit_description").value;
    const designationgrade = document.getElementById("designation_grade").value;
    const salarycode = document.getElementById("salary_code").value;
    const sladcallowance = document.getElementById("sladc_allowance").value;
    const ajtallowance = document.getElementById("ajt_allowance").value;
    const costofallowance = document.getElementById("cost_of_allowance").value;
    const createRequestBody = () => {
      const dependents = [];

      // Collect existing dependent details from the database
      employeeData.dependent_details.forEach((dependent) => {
        dependents.push(dependent);
      });

      // Collect newly added dependent details from the frontend form
      const maxSections = Math.min(itemSections, 6);
      for (let i = 0; i < maxSections; i++) {
        const dependentNameElement = document.getElementById(
          `employee_dependent_name_${i}`
        );
        const dependentRelationshipElement = document.getElementById(
          `employee_dependent_relationship_${i}`
        );
        const dependentNICElement = document.getElementById(
          `employee_dependent_nic_${i}`
        );
        const dependentDOBElement = document.getElementById(
          `employee_dependent_dob_${i}`
        );

        if (
          !dependentNameElement ||
          !dependentRelationshipElement ||
          !dependentNICElement ||
          !dependentDOBElement
        ) {
          continue; // Skip iteration if any element is missing
        }

        const dependentName = dependentNameElement.value.trim();
        const dependentRelationship = dependentRelationshipElement.value.trim();
        const dependentNIC = dependentNICElement.value.trim();
        const dependentDOB = dependentDOBElement.value.trim();

        // Check if all fields for this dependent are empty
        if (
          !dependentName &&
          !dependentRelationship &&
          !dependentNIC &&
          !dependentDOB
        ) {
          continue; // Skip adding this dependent to the array
        }

        const dependentData = {
          employee_dependent_name: dependentName,
          employee_dependent_relationship: dependentRelationship,
          employee_dependent_nic: dependentNIC,
          employee_dependent_dob: dependentDOB,
        };
        dependents.push(dependentData);
      }
      return dependents;
    };

    const academicQualifications = () => {
      const qualifications = [];
      employeeData.academic_qualifications.forEach((qualification) => {
        qualifications.push(qualification);
      });
      const maxSections = Math.min(itemSections, 6);
      for (let i = 0; i < maxSections; i++) {
        const qualificationElement = document.getElementById(
          `employee_acadamic_qualification_${i}`
        );
        if (!qualificationElement) {
          continue;
        }
        const qualificationValue = qualificationElement.value.trim();
        if (!qualificationValue) {
          continue;
        }
        const newQualification = {
          employee_acadamic_qualification_id: 0,
          employee_acadamic_qualification: qualificationValue,
        };
        qualifications.push(newQualification);
      }
      return qualifications;
    };

    const createTrainingDevelopmentRequestBody = () => {
      const trainingDevelopment = [];
      // Check if employeeData and training_details exist
      if (
        employeeData &&
        Array.isArray(employeeData.training_details) &&
        employeeData.training_details.length > 0
      ) {
        // Loop through the training details in the employeeData
        employeeData.training_details.forEach((training) => {
          const trainingData = {
            type_of_training: training.type_of_training,
          };
          trainingDevelopment.push(trainingData);
        });
      }
      return trainingDevelopment;
    };

    const createDisciplinaryActionsRequestBody = () => {
      const disciplinaryActions = [];

      // Check if employeeData.disciplinary_details exists and has elements
      if (
        employeeData &&
        Array.isArray(employeeData.disciplinary_details) &&
        employeeData.disciplinary_details.length > 0
      ) {
        // Loop through the disciplinary details in the employeeData
        employeeData.disciplinary_details.forEach((disciplinary, index) => {
          // Check if the disciplinary action exists in the DOM (i.e., not removed in edit mode)
          const disciplinaryCallDateElement = document.getElementById(
            `employee_disciplinary_call_date_${index}`
          );

          // If the disciplinary action exists in the DOM, add it to the disciplinaryActions array
          if (disciplinaryCallDateElement) {
            const disciplinaryData = {
              employee_disciplinary_call_date:
                disciplinaryCallDateElement.value,
              employee_disciplinary_file_path: disciplinary.file_path,
              employee_disciplinary_original_file_name: disciplinary.file_path,
            };
            disciplinaryActions.push(disciplinaryData);
          }
        });
      }

      return disciplinaryActions;
    };

    const createUplaodfileRequestBody = () => {
      const uploadFiles = [];

      // Check if employeeData.upload_files exists and has elements
      if (
        employeeData &&
        Array.isArray(employeeData.upload_files) &&
        employeeData.upload_files.length > 0
      ) {
        // Loop through the uploaded files in the employeeData
        employeeData.upload_files.forEach((file) => {
          const uploadFileData = {
            employee_upload_path: file.employee_upload_path,
            original_file_name: file.original_file_name,
            // Keep the file path as it is
          };
          uploadFiles.push(uploadFileData);
        });
      }

      return uploadFiles;
    };

    const data = {
      employee_no: employeeNo,
      employee_fullname: fullName,
      employee_name_initial: nameInitial,
      employee_nic: nic,
      employee_dob: dob,
      employee_gender: gender,
      employee_marital_status: maritalStatus,
      employee_contact_no: contactNo,
      employee_permanent_address: permanentAddress,
      employee_temporary_address: temporaryAddress,
      employee_police_division: policeDivision,
      employee_gramasewa_division: gramaSewaDivision,
      employee_email: email,
      employee_designation: designation,
      employee_department: department,
      date_of_appoinment: dateOfAppointment,
      probation_complete: probationComplete,
      permanant_appoinment_date: permanentAppointmentDate,
      first_eb_completion_date: firstEBCompletionDate,
      second_eb_completion_date: secondEBCompletionDate,
      third_eb_completion_date: thirdEBCompletionDate,
      employee_epf_no: epfNo,
      employee_dependent_details: createRequestBody(),
      employee_acadamic_qualification_details: academicQualifications(),
      employee_training_details: createTrainingDevelopmentRequestBody(),
      employee_disciplinary_details: createDisciplinaryActionsRequestBody(),
      employee_upload_files: createUplaodfileRequestBody(),
      employee_account_no: accountNo,
      employee_bank_name: bankName,
      employee_branch_name: branchName,
      employee_bank_code: bankCode,
      employee_branch_code: branchCode,
      employee_basic_salary: basicsalary,
      edit_description: editdescription,
      salary_code: salarycode,
      designation_grade: designationgrade,
      cost_of_allowance: costofallowance,
      sladc_allowance: sladcallowance,
      ajt_allowance: ajtallowance,
    };
    console.log("Data to be sent:", data);

    // Send the PUT request with the constructed request body
    fetch(`${REACT_APP_API_ENDPOINT}/v1/hris/employees/employeeeditdata`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        alert("Form Updated successful!");

        console.log("Success:", data);
        setIsOpen(false);
        setEditMode(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle error here
      });
  };
  // Button text
  const buttonText = editMode ? "Save" : "Edit";

  //button to download
  const hndledownload = async () => {
    try {
      const response = await axios.get("YOUR_DOWNLOAD_URL", {
        responseType: "blob", // Important to get response as a blob
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "filename.extension"); // Set desired filename here
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  // Button onClick handler
  const handleButtonClick = editMode ? handleUpdateProfile : toggleEditMode;

  // Function to fetch employee data
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_ENDPOINT}/v1/hris/employees/getemployeeeditdetails?employee_no=${employee_no}`
      );
      console.log("Fetched Employee Data:", response.data);

      setEmployeeData(response.data[0]);
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchKey]); // Fetch data when fetchKey changes

  useEffect(() => {
    // Fetch designations when the component mounts
    fetch(`${REACT_APP_API_ENDPOINT}/v1/hris/designations/getdesignation`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Set the fetched designations to state
        setDesignations(data);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  }, []);

  const handleToggleDependentModal = () => {
    setDependentIsOpen(!dependentIsOpen);
  };
  const handleToggleAcadamic = () => {
    setAcadamicIsOpen(!AcadamicIsOpen);
  };
  const handleToggleTraning = () => {
    setTraningIsOpen(!TraningIsOpen);
  };
  const handleToggledisciplinary = () => {
    sethandleToggledisciplinary(!disciplinaryIsOpen);
  };
  const handleToggledocument = () => {
    sethandleToggledocument(!documentIsOpen);
  };

  const downloadDisciplinaryFile = (file_path) => {
    const downloadUrl = `${REACT_APP_API_ENDPOINT}/v1/hris/employees/downloadfile?file_path=${file_path}`;
    window.open(downloadUrl, "_blank");
  };

  const downloadFile = (filePath) => {
    const downloadUrl = `${REACT_APP_API_ENDPOINT}/v1/hris/employees/downloadfile?file_path=${filePath}`;
    window.open(downloadUrl, "_blank");
  };
  const handleToggleprofile = () => {
    sethandleToggleprofile(!profileIsOpen);
  };

  return (
    <div className="bg-background h-screen overflow-y-auto">
      <div>
        <p className="text-[40px] font-sans ml-6 mt-6 font-bold text-headerColor">
          Edit User Details
        </p>
      </div>

      <div>
        <div className="bg-primary mr-[10%] mt-5 ml-[10%] flex justify-center h-[150px] rounded-[39px]">
          <div className="bg-[#EBEBEE] w-full rounded-[35px] h-[700px] mt-[5%] text-[32px] ml-[5%] mr-[5%] font-bold ">
            {employeeData && employeeData && (
              <div className="flex items-center justify-between">
                <div>
                  <p className="ml-10 font-sans text-[25px] font-semibold mt-10">
                    {employeeData.employee_no} - User Profile
                  </p>
                  <p className="text-[20px] font-normal ml-10 font-sans">
                    {employeeData.employee_designation}
                  </p>
                </div>

                <div className="mr-10 mt-[15px]">
                  <img
                    className="w-[100px] h-[100px] ml-10 mt-5 rounded-full object-cover cursor-pointer " // Fixed width and height
                    src={employeeData.profile_path_url}
                    alt=".img"
                  />
                </div>
              </div>
            )}

            {isOpen && (
              <div className="fixed top-0 left-0 h-full flex items-center justify-center bg-black bg-opacity-50 z-50 w-full">
                <div className="overflow-y-auto h-[100%] bg-[#ffffff] font-sans w-[50%]">
                  <div className="flex items-center justify-center mt-[1%] ">
                    <div className="w-[100%]  bg-[#ffffff]  rounded-xl p-5 mb-[100px]">
                      <div className="flex justify-end ">
                        <button
                          className="bg-white text-[#797C80] mt-2 rounded-full  "
                          onClick={() => {
                            setIsOpen(false);
                            setEditMode(false);
                          }}
                        >
                          <div className="bg-[#EFA5A5] rounded-full p-0.5 ">
                            <IoCloseSharp className="w-6 h-6" />
                          </div>
                        </button>
                      </div>
                      <div className="flex ">
                        <div>
                          <p className="text-4xl mb-4 font-bold text-[#797C80] ">
                            Personal Details
                          </p>
                          <hr className="line border-t border-gray-300 w-[25%]"></hr>{" "}
                        </div>

                        <div className="flex justify-end item-center ml-[52%]">
                          <div>
                            <button
                              className="rounded-full bg-newBackground w-[150px] h-[50px] text-sm text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 font-sans"
                              onClick={handleButtonClick}
                            >
                              {buttonText}
                            </button>
                          </div>
                          <div>
                            {/* {editMode && (
                              <button
                                className="rounded-full  bg-[rgb(171,175,166)] w-[150px] h-[50px]  text-sm text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 font-sans"
                                onClick={() => setEditMode(false)}
                              >
                                Cancel
                              </button>
                            )} */}
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <form>
                          <div className="flex flex-col ">
                            <div></div>
                          </div>

                          <div className="flex items-center flex-col md:flex-row w-[100%] gap-10">
                            <div className="flex flex-col w-[50%]  ">
                              <label
                                htmlFor="employee_no"
                                className="text-xl mb-2  font-bold text-[#797C80] font-sans"
                              >
                                Employee Number
                              </label>

                              <input
                                type="text"
                                name="employee_no"
                                id="employee_no"
                                autoComplete="employee_no"
                                value={employeeData.employee_no}
                                className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-sans"
                                style={{ borderRadius: "45px" }}
                                disabled
                              />
                            </div>
                            <div className="flex flex-col w-[50%] items-center ">
                              <img
                                src={employeeData.profile_path_url}
                                alt="Profile Preview"
                                className="w-[150px] h-[150px] ml-10 mt-5 rounded-full object-cover cursor-pointer"
                              />
                              {profileIsOpen && (
                                <div className="fixed top-0 left-0 h-full flex items-center justify-center bg-black bg-opacity-50 z-50 w-full">
                                  <AddImage
                                    employee_no={employee_no}
                                    setFetchData={setFetchKey}
                                    onClose={handleToggleprofile}
                                  />
                                </div>
                              )}
                              {editMode && (
                                <div>
                                  <button
                                    type="button"
                                    onClick={handleToggleprofile}
                                    className="rounded-full ml-10 bg-newBackground mb-5 px-3 py-2 text-sm text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 font-sans"
                                    style={{ borderRadius: "45px" }}
                                  >
                                    Add a Profile Image
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="flex flex-col mt-5">
                            <label
                              htmlFor="full_name"
                              className="text-xl mb-2   text-[#797C80] font-sans"
                            >
                              Full name
                            </label>
                            {editMode ? (
                              <input
                                type="text"
                                name="employee_fullname"
                                id="employee_fullname"
                                placeholder="Enter full name"
                                autoComplete="employee_fullname"
                                value={employeeData.employee_fullname || ""}
                                onChange={handleInputChange}
                                className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                                style={{ borderRadius: "45px" }}
                              />
                            ) : (
                              <p className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans">
                                <p className="ml-5">
                                  {employeeData.employee_fullname || ""}
                                </p>
                              </p>
                            )}
                          </div>
                          <div className="flex mt-5 flex-col md:flex-row w-[100%] gap-10">
                            <div className="flex flex-col w-[50%] ">
                              <label
                                htmlFor="name_with_initials"
                                className="text-xl mb-2  font-bold text-[#797C80] font-sans"
                              >
                                Name with Initials{" "}
                              </label>
                              {editMode ? (
                                <input
                                  type="text"
                                  name="employee_name_initial"
                                  id="employee_name_initial"
                                  autoComplete="employee_name_initial"
                                  placeholder="Enter name with initials"
                                  value={
                                    employeeData.employee_name_initial || ""
                                  }
                                  onChange={handleInputChange}
                                  className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                                  style={{ borderRadius: "45px" }}
                                />
                              ) : (
                                <p className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans">
                                  <p className="ml-5">
                                    {employeeData.employee_name_initial || ""}
                                  </p>
                                </p>
                              )}
                            </div>
                            <div className="flex flex-col w-[50%]">
                              <label
                                htmlFor="nic_number"
                                className="text-xl mb-2  font-bold text-[#797C80] font-sans"
                              >
                                NIC Number
                              </label>
                              {editMode ? (
                                <input
                                  type="text"
                                  name="employee_nic"
                                  id="employee_nic"
                                  autoComplete="employee_nic"
                                  placeholder="Enter NIC number"
                                  value={employeeData.employee_nic || ""}
                                  onChange={handleInputChange}
                                  className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                                  style={{ borderRadius: "45px" }}
                                />
                              ) : (
                                <p className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans">
                                  <p className="ml-5">
                                    {employeeData.employee_nic || ""}
                                  </p>
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="flex mt-5 flex-col md:flex-row font-bold w-[100%] gap-10">
                            <div className="flex flex-col w-[50%] ">
                              <label
                                htmlFor="name_with_initials"
                                className="text-xl mb-2  font-bold text-[#797C80] font-sans"
                              >
                                Date of Birth
                              </label>
                              {editMode ? (
                                <input
                                  type="date"
                                  name="employee_dob"
                                  id="employee_dob"
                                  autoComplete="employee_dob"
                                  value={employeeData.employee_dob || ""}
                                  onChange={handleInputChange}
                                  className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                                  style={{ borderRadius: "45px" }}
                                />
                              ) : (
                                <p className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans">
                                  <p className="ml-5">
                                    {employeeData.employee_dob || ""}
                                  </p>
                                </p>
                              )}
                            </div>
                            <div className="flex flex-col w-[50%] ">
                              <label
                                htmlFor="nic_number"
                                className="text-xl mb-2  font-bold text-[#797C80] font-sans"
                              >
                                Gender{" "}
                              </label>
                              {editMode ? (
                                <select
                                  id="employee_gender"
                                  name="employee_gender"
                                  autoComplete="employee_gender"
                                  value={employeeData.employee_gender || ""}
                                  onChange={handleInputChange}
                                  className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                                  style={{ borderRadius: "45px" }}
                                >
                                  <option value="Male">Male</option>
                                  <option value="Female">Female</option>
                                  <option value="Other">Other</option>
                                </select>
                              ) : (
                                <p className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans">
                                  <p className="ml-5">
                                    {employeeData.employee_gender || ""}
                                  </p>
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="flex mt-5 flex-col md:flex-row font-bold w-[100%] gap-10">
                            <div className="flex flex-col w-[50%]">
                              <label
                                htmlFor="name_with_initials"
                                className="text-xl mb-2  font-bold text-[#797C80] font-sans"
                              >
                                Marital Status{" "}
                              </label>
                              {editMode ? (
                                <select
                                  id="employee_marital_status"
                                  name="employee_marital_status"
                                  autoComplete="employee_marital_status"
                                  value={
                                    employeeData.employee_marital_status || ""
                                  }
                                  onChange={handleInputChange}
                                  className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                                  style={{ borderRadius: "45px" }}
                                >
                                  <option value="Single">Single</option>
                                  <option value="Married">Married</option>
                                  <option value="Divorced">Divorced</option>
                                  <option value="Widowed">Widowed</option>
                                </select>
                              ) : (
                                <p className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans">
                                  <p className="ml-5">
                                    {employeeData.employee_marital_status || ""}
                                  </p>
                                </p>
                              )}
                            </div>
                            <div className="flex flex-col w-[50%]">
                              <label
                                htmlFor="contact_number"
                                className="text-xl mb-2  font-bold text-[#797C80] font-sans"
                              >
                                Contact Number
                              </label>
                              {editMode ? (
                                <input
                                  type="number"
                                  name="employee_contact_no"
                                  id="employee_contact_no"
                                  autoComplete="employee_contact_no"
                                  value={employeeData.employee_contact_no || ""}
                                  onChange={handleInputChange}
                                  className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                                  style={{ borderRadius: "45px" }}
                                />
                              ) : (
                                <p className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans">
                                  <p className="ml-5">
                                    {employeeData.employee_contact_no || ""}
                                  </p>
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="flex flex-col  font-bold mt-5">
                            <label
                              htmlFor="full_name"
                              className="text-xl mb-2  font-bold text-[#797C80] font-sans"
                            >
                              Permanent Address{" "}
                            </label>
                            {editMode ? (
                              <input
                                type="text"
                                name="employee_permanent_address"
                                id="employee_permanent_address"
                                placeholder="Enter Permanent Address"
                                value={
                                  employeeData.employee_permanent_address || ""
                                }
                                onChange={handleInputChange}
                                className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-sans"
                                style={{ borderRadius: "45px" }}
                              />
                            ) : (
                              <p className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans">
                                <p className="ml-5">
                                  {employeeData.employee_permanent_address ||
                                    ""}
                                </p>
                              </p>
                            )}
                          </div>

                          <div className="flex flex-col  font-bold mt-5">
                            <label
                              htmlFor="employee_temparary_address"
                              className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                            >
                              Temporary Address
                            </label>
                            {editMode ? (
                              <input
                                type="text"
                                name="employee_temparary_address"
                                id="employee_temparary_address"
                                placeholder="Enter Temporary Address"
                                value={
                                  employeeData.employee_temporary_address || ""
                                }
                                onChange={handleInputChange}
                                className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white font-normal placeholder-white sm:text-[20px] px-2 font-sans"
                                style={{ borderRadius: "45px" }}
                              />
                            ) : (
                              <p className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans">
                                <p className="ml-5">
                                  {employeeData.employee_temporary_address ||
                                    ""}
                                </p>
                              </p>
                            )}
                          </div>

                          <div className="flex mt-5 flex-col md:flex-row font-bold w-[100%] gap-10">
                            <div className="flex flex-col w-[50%] ">
                              <label
                                htmlFor="police_division"
                                className="text-xl mb-2  font-bold text-[#797C80] font-sans"
                              >
                                Police Division
                              </label>
                              {editMode ? (
                                <input
                                  type="text"
                                  name="employee_police_division"
                                  id="employee_police_division"
                                  placeholder="Enter Police Division"
                                  autoComplete="employee_police_division"
                                  value={
                                    employeeData.employee_police_division || ""
                                  }
                                  onChange={handleInputChange}
                                  className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                                  style={{ borderRadius: "45px" }}
                                />
                              ) : (
                                <p className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans">
                                  <p className="ml-5">
                                    {employeeData.employee_police_division ||
                                      ""}
                                  </p>
                                </p>
                              )}
                            </div>
                            <div className="flex flex-col w-[50%]">
                              <label
                                htmlFor="gramasewa_division"
                                className="text-xl mb-2  font-bold text-[#797C80] font-sans"
                              >
                                Gramasewa Division{" "}
                              </label>
                              {editMode ? (
                                <input
                                  type="text"
                                  name="employee_gramasewa_division"
                                  id="employee_gramasewa_division"
                                  placeholder="Enter Gramasewa Division"
                                  autoComplete="employee_gramasewa_division"
                                  value={
                                    employeeData.employee_gramasewa_division ||
                                    ""
                                  }
                                  onChange={handleInputChange}
                                  className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                                  style={{ borderRadius: "45px" }}
                                />
                              ) : (
                                <p className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans">
                                  <p className="ml-5">
                                    {employeeData.employee_gramasewa_division ||
                                      ""}
                                  </p>
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="flex flex-col  font-bold mt-5">
                            <label
                              htmlFor="gramasewa_division"
                              className="text-xl mb-2  font-bold text-[#797C80] font-sans"
                            >
                              Email
                            </label>
                            {editMode ? (
                              <input
                                type="text"
                                name="employee_email"
                                id="employee_email"
                                placeholder="Enter email"
                                autoComplete="employee_email"
                                value={employeeData.employee_email || ""}
                                onChange={handleInputChange}
                                className="w-[100%] font-normal h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-sans"
                                style={{ borderRadius: "45px" }}
                              />
                            ) : (
                              <p className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans">
                                <p className="ml-5">
                                  {employeeData.employee_email || ""}
                                </p>
                              </p>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div>
                    {dependentIsOpen && (
                      <div className="fixed top-0 left-0 h-full flex items-center justify-center bg-black bg-opacity-50 z-50 w-full">
                        <div>
                          <AddNewDependent
                            employee_no={employee_no}
                            onClose={handleToggleDependentModal}
                            setFetchData={setFetchKey}
                          />
                        </div>
                      </div>
                    )}

                    <div className="ml-[4%]">
                      <div className="flex items-center mt-[2%] justify-between ">
                        <div>
                          <p className="text-4xl   font-bold text-[#797C80] ">
                            Dependant Details{" "}
                          </p>
                        </div>
                        <div>
                          {" "}
                          {editMode && (
                            <div className=" flex justify-end mr-5  ">
                              <button
                                onClick={handleToggleDependentModal}
                                className="rounded-full bg-newBackground px-3 py-2 text-sm text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 font-sans"
                              >
                                Add New Dependent
                              </button>
                            </div>
                          )}
                        </div>
                      </div>

                      <hr className="line border-t border-gray-300 w-[25%]"></hr>
                    </div>

                    {employeeData.dependent_details.map((dependent, index) => (
                      <div key={index}>
                        <div className="flex items-center justify-center">
                          <div className="w-[100%] bg-[#ffffff] rounded-xl p-8 mt-5">
                            <div className="flex items-center">
                              <p className="text-2xl  font-bold text-[#797C80]">
                                Dependent {dependantNumber(index)}
                              </p>
                              {editMode && (
                                <button
                                  className=" bg-white text-[#797C80] ml-10 rounded-full p-1"
                                  onClick={handleRemoveDependent}
                                >
                                  <div className="bg-[#EFA5A5] rounded-full ">
                                    <IoCloseSharp />
                                  </div>
                                </button>
                              )}
                            </div>

                            <div>
                              <form>
                                <div className="flex mt-5 flex-col md:flex-row  w-[100%] gap-10">
                                  <div className="flex flex-col w-[50%] ">
                                    <label
                                      htmlFor={`employee_dependent_relationship_${index}`}
                                      className="text-xl mb-2  font-bold text-[#797C80] font-sans"
                                    >
                                      Relationship
                                    </label>
                                    <input
                                      type="text"
                                      name={`employee_dependent_relationship_${index}`}
                                      id={`employee_dependent_relationship_${index}`}
                                      placeholder="Enter Relationship"
                                      value={
                                        dependent.employee_dependent_relationship
                                      }
                                      onChange={(e) => {
                                        const data = { ...employeeData };
                                        data.dependent_details[
                                          index
                                        ].employee_dependent_relationship =
                                          e.target.value;
                                        setEmployeeData(data);
                                      }}
                                      disabled={!editMode}
                                      className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                                    />
                                  </div>
                                  <div className="flex flex-col w-[50%] ">
                                    <label
                                      htmlFor={`employee_dependent_name_${index}`}
                                      className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                                    >
                                      Name
                                    </label>
                                    <input
                                      type="text"
                                      name={`employee_dependent_name_${index}`}
                                      id={`employee_dependent_name_${index}`}
                                      autoComplete={`employee_dependent_name_${index}`}
                                      placeholder="Enter Name"
                                      value={dependent.employee_dependent_name}
                                      // Enable input field only in edit mode
                                      disabled={!editMode}
                                      onChange={(e) => {
                                        const data = { ...employeeData };
                                        data.dependent_details[
                                          index
                                        ].employee_dependent_name =
                                          e.target.value;
                                        setEmployeeData(data);
                                      }}
                                      className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                                    />
                                  </div>
                                </div>
                                <div className="flex mt-5 flex-col md:flex-row font-bold w-[100%] gap-10">
                                  <div className="flex flex-col w-[50%] ">
                                    <label
                                      htmlFor={`employee_dependent_nic_${index}`}
                                      className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                                    >
                                      NIC
                                    </label>
                                    <input
                                      type="text"
                                      name={`employee_dependent_nic_${index}`}
                                      id={`employee_dependent_nic_${index}`}
                                      autoComplete={`employee_dependent_nic_${index}`}
                                      placeholder="Enter NIC Number"
                                      value={dependent.employee_dependent_nic}
                                      // Enable input field only in edit mode
                                      disabled={!editMode}
                                      onChange={(e) => {
                                        const data = { ...employeeData };
                                        data.dependent_details[
                                          index
                                        ].employee_dependent_nic =
                                          e.target.value;
                                        setEmployeeData(data);
                                      }}
                                      className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                                    />
                                  </div>

                                  <div className="flex flex-col w-[50%] ">
                                    <label
                                      htmlFor={`employee_dependent_dob_${index}`}
                                      className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                                    >
                                      Date of Birth
                                    </label>
                                    <input
                                      type="date"
                                      name={`employee_dependent_dob_${index}`}
                                      id={`employee_dependent_dob_${index}`}
                                      value={dependent.employee_dependent_dob}
                                      // Enable input field only in edit mode
                                      disabled={!editMode}
                                      onChange={(e) => {
                                        const data = { ...employeeData };
                                        data.dependent_details[
                                          index
                                        ].employee_dependent_dob =
                                          e.target.value;
                                        setEmployeeData(data);
                                      }}
                                      className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                                    />
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  {/* employee Details Section */}
                  <div className="flex items-center justify-center  ">
                    <div className="w-[100%] text-xl   bg-[#ffffff] rounded-xl p-8 ">
                      <p className="text-4xl mb-4  font-bold text-[#797C80] ">
                        Employment Details{" "}
                      </p>
                      <hr className="line border-t border-gray-300 w-[25%]"></hr>
                      <div className="mt-10">
                        <form>
                          <div className="flex mt-5 flex-col md:flex-row font-bold w-[100%] gap-10">
                            <div className="flex flex-col w-[50%]">
                              <label
                                htmlFor="designation"
                                className="text-xl mb-2  font-bold text-[#797C80] font-sans"
                              >
                                Designation{" "}
                              </label>
                              {editMode ? (
                                <select
                                  id="employee_designation"
                                  name="employee_designation"
                                  value={employeeData.employee_designation}
                                  onChange={handleInputChange}
                                  className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                                >
                                  <option value="">Select</option>
                                  {designations.map((designation) => (
                                    <option
                                      key={designation.designation}
                                      value={designation.designation}
                                    >
                                      {designation.designation}
                                    </option>
                                  ))}
                                </select>
                              ) : (
                                <input
                                  type="text"
                                  id="employee_designation"
                                  name="employee_designation"
                                  autoComplete="employee_designation"
                                  value={employeeData.employee_designation}
                                  className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                                  readOnly
                                />
                              )}
                            </div>
                            <div className="flex flex-col w-[50%] ">
                              <label
                                htmlFor="employee_department"
                                className="text-xl mb-2  font-bold text-[#797C80] font-sans"
                              >
                                Department{" "}
                              </label>
                              {editMode ? (
                                <select
                                  id="employee_department"
                                  name="employee_department"
                                  value={employeeData.employee_department}
                                  onChange={handleInputChange}
                                  className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                                >
                                  <option value="">Select</option>
                                  {designations.map((designation) => (
                                    <option
                                      key={designation.department}
                                      value={designation.department}
                                    >
                                      {designation.department}
                                    </option>
                                  ))}
                                </select>
                              ) : (
                                <input
                                  type="text"
                                  name="employee_department"
                                  id="employee_department"
                                  value={employeeData.employee_department}
                                  autoComplete="employee_department"
                                  className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                                  readOnly
                                />
                              )}
                            </div>
                          </div>
                          <div className="flex mt-5 flex-col md:flex-row font-bold w-[100%] gap-10">
                            <div className="flex flex-col w-[50%] ">
                              <label
                                htmlFor="date_of_appoinment
"
                                className="text-xl mb-2  font-bold text-[#797C80] font-sans"
                              >
                                Date of Appoinment{" "}
                              </label>
                              {editMode ? (
                                <input
                                  type="date"
                                  name="date_of_appoinment"
                                  id="date_of_appoinment"
                                  value={
                                    employeeData.date_of_appoinment ||
                                    employeeData.date_of_appoinment
                                  }
                                  autoComplete="date_of_appoinment"
                                  onChange={handleInputChange}
                                  className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                                />
                              ) : (
                                <input
                                  type="date"
                                  name="date_of_appointment"
                                  id="date_of_appointment"
                                  value={employeeData.date_of_appoinment}
                                  autoComplete="date_of_appointment"
                                  className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                                  disabled
                                />
                              )}
                            </div>
                            <div className="flex flex-col w-[50%] ">
                              <label
                                htmlFor="name_with_initials"
                                className="text-xl-2 mb-2  font-bold text-[#797C80] font-sans"
                              >
                                Probation Period (Complete or Not){" "}
                              </label>
                              {editMode ? (
                                <select
                                  id="probation_complete"
                                  name="probation_complete"
                                  value={employeeData.probation_complete || ""}
                                  onChange={handleInputChange}
                                  className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                                >
                                  <option value="">Select</option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                </select>
                              ) : (
                                <input
                                  type="text"
                                  id="probation_complete"
                                  name="probation_complete"
                                  value={employeeData.probation_complete}
                                  autoComplete="probation_complete"
                                  readOnly
                                  className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                                />
                              )}
                            </div>
                          </div>
                          <div className="flex mt-5 flex-col md:flex-row font-bold w-[100%] gap-10">
                            <div className="flex flex-col w-[50%] ">
                              <label
                                htmlFor="contact_number"
                                className="text-xl-2 mb-2  font-bold text-[#797C80] font-sans"
                              >
                                Permanent Appointment Date{" "}
                              </label>
                              {editMode ? (
                                <input
                                  type="date"
                                  name="permanent_appointment_date"
                                  id="permanent_appointment_date"
                                  value={
                                    employeeData.permanent_appointment_date ||
                                    ""
                                  }
                                  autoComplete="permanent_appointment_date"
                                  onChange={handleInputChange}
                                  className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                                />
                              ) : (
                                <input
                                  type="date"
                                  name="permanent_appointment_date"
                                  id="permanent_appointment_date"
                                  value={
                                    employeeData.permanent_appointment_date
                                  }
                                  autoComplete="permanent_appointment_date"
                                  className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                                  disabled
                                />
                              )}
                            </div>
                            <div className="flex flex-col w-[50%] ">
                              <label
                                htmlFor="name_with_initials"
                                className="text-xl-2 mb-2  font-bold text-[#797C80] font-sans"
                              >
                                1st EB Completion Date
                              </label>
                              {editMode ? (
                                <input
                                  type="date"
                                  name="first_eb_completion_date"
                                  id="first_eb_completion_date"
                                  autoComplete="first_eb_completion_date"
                                  value={
                                    employeeData.first_eb_completion_date || ""
                                  }
                                  onChange={handleInputChange}
                                  className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                                />
                              ) : (
                                <input
                                  type="date"
                                  name="first_eb_completion_date"
                                  id="first_eb_completion_date"
                                  disabled
                                  autoComplete="first_eb_completion_date"
                                  value={employeeData.first_eb_completion_date}
                                  className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                                />
                              )}
                            </div>
                          </div>
                          <div className="flex mt-5 flex-col md:flex-row font-bold w-[100%] gap-10">
                            <div className="flex flex-col w-[50%]">
                              <label
                                htmlFor="contact_number"
                                className="text-xl-2 mb-2  font-bold text-[#797C80] font-sans"
                              >
                                2nd EB Completion Date{" "}
                              </label>
                              <input
                                type="date"
                                name="second_eb_completion_date"
                                id="second_eb_completion_date"
                                autoComplete="second_eb_completion_date"
                                value={
                                  editMode
                                    ? employeeData.second_eb_completion_date ||
                                      ""
                                    : employeeData.second_eb_completion_date
                                }
                                onChange={handleInputChange}
                                className={`w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans  ${
                                  editMode ? "text-gray-900" : "text-white"
                                } placeholder-white  px-2 font-sans`}
                                disabled={!editMode}
                              />
                            </div>
                            <div className="flex flex-col w-[50%] ">
                              <label
                                htmlFor="name_with_initials"
                                className="text-xl-2 mb-2  font-bold text-[#797C80] font-sans"
                              >
                                3rd EB Completion Date{" "}
                              </label>
                              <input
                                type="date"
                                name="third_eb_completion_date"
                                id="third_eb_completion_date"
                                value={
                                  editMode
                                    ? employeeData.third_eb_completion_date ||
                                      ""
                                    : employeeData.third_eb_completion_date
                                }
                                autoComplete="third_eb_completion_date"
                                className={`w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans  ${
                                  editMode ? "text-gray-900" : "text-white"
                                } placeholder-white  px-2 font-sans`}
                                disabled={!editMode}
                              />
                            </div>
                          </div>
                          <div className="flex mt-5 flex-col md:flex-row font-bold w-[100%] gap-10">
                            <div className="flex flex-col w-[50%] ">
                              <label
                                htmlFor="employee_epf_no"
                                className="text-xl-2 mb-2 font-bold text-[#797C80] font-sans"
                              >
                                EPF No{" "}
                              </label>
                              <input
                                type="text"
                                id="employee_epf_no"
                                name="employee_epf_no"
                                autoComplete="employee_epf_no"
                                value={
                                  editMode
                                    ? employeeData.employee_epf_no || ""
                                    : employeeData.employee_epf_no
                                }
                                onChange={editMode ? handleInputChange : null}
                                className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                                readOnly={!editMode}
                              />
                            </div>
                            <div className="flex flex-col w-[50%] ">
                              <label
                                htmlFor="employee_basic_salary"
                                className="text-xl-2 mb-2 font-bold text-[#797C80] font-sans"
                              >
                                Basic Salary
                              </label>
                              <input
                                type="text"
                                id="employee_basic_salary"
                                name="employee_basic_salary"
                                autoComplete="employee_basic_salary"
                                value={
                                  editMode
                                    ? employeeData.employee_basic_salary || ""
                                    : employeeData.employee_basic_salary
                                }
                                onChange={editMode ? handleInputChange : null}
                                className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                                readOnly={!editMode}
                              />
                            </div>
                          </div>
                          <div className="flex mt-5 flex-col md:flex-row font-bold w-[100%] gap-10">
                            <div className="flex flex-col w-[50%] ">
                              <label
                                htmlFor="designation_grade"
                                className="text-xl-2 mb-2 font-bold text-[#797C80] font-sans"
                              >
                                Designation Grade{" "}
                              </label>
                              <input
                                type="text"
                                id="designation_grade"
                                name="designation_grade"
                                autoComplete="designation_grade"
                                value={
                                  editMode
                                    ? employeeData.sladc_allowance || ""
                                    : employeeData.sladc_allowance
                                }
                                onChange={editMode ? handleInputChange : null}
                                className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                                readOnly={!editMode}
                              />
                            </div>
                            <div className="flex flex-col w-[50%] ">
                              <label
                                htmlFor="salary_code"
                                className="text-xl-2 mb-2 font-bold text-[#797C80] font-sans"
                              >
                                Salary Code
                              </label>
                              <input
                                type="text"
                                id="salary_code"
                                name="salary_code"
                                autoComplete="salary_code"
                                value={
                                  editMode
                                    ? employeeData.salary_code || ""
                                    : employeeData.salary_code
                                }
                                onChange={editMode ? handleInputChange : null}
                                className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                                readOnly={!editMode}
                              />
                            </div>
                          </div>
                          <div className="flex mt-5 flex-col md:flex-row font-bold w-[100%] gap-10">
                            <div className="flex flex-col w-[50%] ">
                              <label
                                htmlFor="sladc_allowance"
                                className="text-xl-2 mb-2 font-bold text-[#797C80] font-sans"
                              >
                                Sladc Allowance
                              </label>
                              <input
                                type="text"
                                id="sladc_allowance"
                                name="sladc_allowance"
                                autoComplete="sladc_allowance"
                                value={
                                  editMode
                                    ? employeeData.sladc_allowance || ""
                                    : employeeData.sladc_allowance
                                }
                                onChange={editMode ? handleInputChange : null}
                                className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                                readOnly={!editMode}
                              />
                            </div>
                            <div className="flex flex-col w-[50%] ">
                              <label
                                htmlFor="ajt_allowance"
                                className="text-xl-2 mb-2 font-bold text-[#797C80] font-sans"
                              >
                                Ajt Allowance{" "}
                              </label>
                              <input
                                type="text"
                                id="ajt_allowance"
                                name="ajt_allowance"
                                autoComplete="ajt_allowance"
                                value={
                                  editMode
                                    ? employeeData.ajt_allowance || ""
                                    : employeeData.ajt_allowance
                                }
                                onChange={editMode ? handleInputChange : null}
                                className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                                readOnly={!editMode}
                              />
                            </div>
                          </div>
                          <div className="flex flex-col w-[50%] mt-5">
                            <label
                              htmlFor="cost_of_allowance"
                              className="text-xl-2 mb-2 font-bold text-[#797C80] font-sans"
                            >
                              Cost of Allowance{" "}
                            </label>
                            <input
                              type="text"
                              id="cost_of_allowance"
                              name="cost_of_allowance"
                              autoComplete="cost_of_allowance"
                              value={
                                editMode
                                  ? employeeData.cost_of_allowance || ""
                                  : employeeData.cost_of_allowance
                              }
                              onChange={editMode ? handleInputChange : null}
                              className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                              readOnly={!editMode}
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="ml-[4%]">
                      <div className="flex items-center mt-[5%] justify-between ">
                        <div>
                          <p className="text-[36px] font-bold text-[#797C80] ">
                            Employment qualification
                          </p>
                        </div>
                        <div>
                          {editMode && (
                            <div className=" flex justify-end mr-5  ">
                              <button
                                onClick={handleToggleAcadamic}
                                className="rounded-full bg-newBackground px-3 py-2 text-sm text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 font-sans"
                              >
                                Add Academic Qualifications
                              </button>
                            </div>
                          )}
                        </div>
                      </div>

                      <hr className="line border-t border-gray-300 w-[25%]"></hr>
                    </div>{" "}
                    {AcadamicIsOpen && (
                      <div className="fixed top-0 left-0 h-full flex items-center justify-center bg-black bg-opacity-50 z-50 w-full">
                        <div>
                          <AddNewEmploymentQualification
                            employee_no={employee_no}
                            onClose={handleToggleAcadamic}
                            setFetchData={setFetchKey}
                          />
                        </div>
                      </div>
                    )}
                    {employeeData && employeeData.academic_qualifications && (
                      <div className=" mt-5">
                        <div className=" bg-[#ffffff] rounded-xl p-8 ">
                          {employeeData.academic_qualifications.map(
                            (academic_qualifications, index) => (
                              <div key={index}>
                                <form>
                                  <div className=" flex items-center mt-10">
                                    <label
                                      htmlFor={`qualification_${index}`}
                                      className="text-xl  font-bold text-[#797C80] font-sans"
                                    >
                                      Qualification {qualificationNumber(index)}
                                    </label>
                                    {editMode && (
                                      <button
                                        className="  bg-white text-[#797C80] ml-10 rounded-full "
                                        onClick={() =>
                                          handleRemoveAcademicQualification(
                                            index
                                          )
                                        }
                                      >
                                        <div className=" bg-[#EFA5A5] rounded-full p-1">
                                          <IoCloseSharp className=" w-5 h-5" />
                                        </div>
                                      </button>
                                    )}
                                  </div>

                                  <input
                                    type="text"
                                    name={`employee_acadamic_qualification_${index}`}
                                    id={`employee_acadamic_qualification_${index}`}
                                    placeholder="Enter Academic Qualification"
                                    value={
                                      academic_qualifications.employee_acadamic_qualification
                                    }
                                    onChange={(e) => {
                                      const data = { ...employeeData };
                                      data.academic_qualifications[
                                        index
                                      ].employee_acadamic_qualification =
                                        e.target.value;
                                      setEmployeeData(data);
                                    }}
                                    disabled={!editMode}
                                    className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                                  />
                                </form>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div>
                    <div className="ml-[4%]">
                      <div className="flex items-center justify-between ">
                        <div>
                          <p className="text-4xl font-bold text-[#797C80] ">
                            Training Development
                          </p>
                        </div>
                        <div>
                          {editMode && (
                            <div className=" flex justify-end mr-5  ">
                              <button
                                onClick={handleToggleTraning}
                                className="rounded-full bg-newBackground px-3 py-2 text-sm text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 font-sans"
                              >
                                Add New Training Development
                              </button>
                            </div>
                          )}
                        </div>
                      </div>

                      <hr className="line border-t border-gray-300 w-[25%]"></hr>
                    </div>{" "}
                    {TraningIsOpen && (
                      <div className="fixed top-0 left-0 h-full flex items-center justify-center bg-black bg-opacity-50 z-50 w-full">
                        <div>
                          <AddnewTraning
                            employee_no={employee_no}
                            onClose={handleToggleTraning}
                            setFetchData={setFetchKey}
                          />
                        </div>
                      </div>
                    )}
                    {employeeData && employeeData.training_details && (
                      <div className=" items-center justify-center  ">
                        {employeeData.training_details.map(
                          (training, index) => (
                            <div key={index} className="mt-10 ml-7">
                              <form>
                                <div className=" mt-5 flex-col md:flex-row  font-bold">
                                  <div className="flex items-center">
                                    <label
                                      htmlFor="type_of_training"
                                      className="text-xl mb-2  font-bold text-[#797C80] font-sans"
                                    >
                                      Type of Training
                                    </label>
                                    {editMode && (
                                      <button
                                        className="  bg-white text-[#797C80] ml-10 rounded-full "
                                        onClick={() =>
                                          handleRemoveTraningdevelopment(index)
                                        }
                                      >
                                        <div className=" bg-[#EFA5A5] rounded-full p-1">
                                          <IoCloseSharp className=" w-5 h-5" />
                                        </div>
                                      </button>
                                    )}
                                  </div>
                                  <div className="flex flex-col mr-10">
                                    <input
                                      type="text"
                                      name={`type_of_training_${index}`}
                                      id={`type_of_training_${index}`}
                                      autoComplete={`type_of_training_${index}`}
                                      placeholder="Enter Type"
                                      value={training.type_of_training}
                                      onChange={(e) =>
                                        handleTrainingChange(
                                          e,
                                          index,
                                          "type_of_training"
                                        )
                                      }
                                      className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                                    />
                                  </div>
                                </div>
                              </form>
                            </div>
                          )
                        )}
                      </div>
                    )}
                  </div>
                  <div className="ml-[4%]">
                    <div className="flex items-center mt-[5%] justify-between ">
                      <div>
                        <p className="text-4xl font-bold text-[#797C80] ">
                          Disciplinary Actions
                        </p>
                      </div>
                      <div>
                        {editMode && (
                          <div className=" flex justify-end mr-5  ">
                            <button
                              onClick={handleToggledisciplinary}
                              className="rounded-full bg-newBackground px-3 py-2 text-sm text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 font-sans"
                            >
                              Add New Disciplinary Details
                            </button>
                          </div>
                        )}
                      </div>
                    </div>

                    <hr className="line border-t border-gray-300 w-[25%]"></hr>
                  </div>{" "}
                  {disciplinaryIsOpen && (
                    <div className="fixed top-0 left-0 h-full flex items-center justify-center bg-black bg-opacity-50 z-50 w-full">
                      <div>
                        <Addnewdisciplinary
                          employee_no={employee_no}
                          onClose={handleToggledisciplinary}
                          setFetchData={setFetchKey}
                        />
                      </div>
                    </div>
                  )}
                  <div className="mt-10 ml-7">
                    {employeeData && employeeData.disciplinary_details && (
                      <div>
                        <div>
                          <form>
                            {employeeData.disciplinary_details.map(
                              (disciplinary_details, index) => (
                                <div key={index}>
                                  <div className="flex items-center justify-center  ">
                                    <div className="w-[100%] bg-[#ffffff] rounded-xl p-8 ">
                                      <div>
                                        <form>
                                          <div className="flex  flex-col md:flex-row  font-bold">
                                            <div className="flex flex-col mr-10">
                                              <label
                                                htmlFor="name"
                                                className="text-xl mb-2  font-bold text-[#797C80] font-sans"
                                              >
                                                Date of Explanation Call{" "}
                                              </label>

                                              <input
                                                type="date"
                                                name={`employee_disciplinary_call_date_${index}`}
                                                id={`employee_disciplinary_call_date_${index}`}
                                                placeholder="Enter disciplinary call date"
                                                value={
                                                  disciplinary_details.employee_disciplinary_call_date
                                                } // Change to disciplinary
                                                onChange={(e) =>
                                                  handledisciplinaryChange(
                                                    e,
                                                    index,
                                                    "employee_disciplinary_call_date"
                                                  )
                                                } // Pass the correct field name
                                                disabled={!editMode}
                                                className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                                              />
                                            </div>
                                            <div className="flex flex-col">
                                              <label
                                                htmlFor="nic_number"
                                                className="text-xl mb-2  font-bold text-[#797C80] font-sans"
                                              >
                                                Uploaded Letter{" "}
                                              </label>
                                              <div className="flex items-center">
                                                {disciplinary_details.employee_disciplinary_original_file_name &&
                                                  disciplinary_details.employee_disciplinary_original_file_name.endsWith(
                                                    ".pdf"
                                                  ) && (
                                                    <svg
                                                      height="50px"
                                                      width="50px"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      viewBox="0 0 56 56"
                                                    >
                                                      <path
                                                        style={{
                                                          fill: "#E9E9E0",
                                                        }}
                                                        d="M36.985,0H7.963C7.155,0,6.5,0.655,6.5,1.926V55c0,0.345,0.655,1,1.463,1h40.074
      c0.808,0,1.463-0.655,1.463-1V12.978c0-0.696-0.093-0.92-0.257-1.085L37.607,0.257C37.442,0.093,37.218,0,36.985,0z"
                                                      />
                                                      <path
                                                        style={{
                                                          fill: "#CC4B4C",
                                                        }}
                                                        d="M19.514,33.324L19.514,33.324c-0.348,0-0.682-0.113-0.967-0.326
      c-1.041-0.781-1.181-1.65-1.115-2.242c0.182-1.628,2.195-3.332,5.985-5.068c1.504-3.296,2.935-7.357,3.788-10.75
      c-0.998-2.172-1.968-4.99-1.261-6.643c0.248-0.579,0.557-1.023,1.134-1.215c0.228-0.076,0.804-0.172,1.016-0.172
      c0.504,0,0.947,0.649,1.261,1.049c0.295,0.376,0.964,1.173-0.373,6.802c1.348,2.784,3.258,5.62,5.088,7.562
      c1.311-0.237,2.439-0.358,3.358-0.358c1.566,0,2.515,0.365,2.902,1.117c0.32,0.622,0.189,1.349-0.39,2.16
      c-0.557,0.779-1.325,1.191-2.22,1.191c-1.216,0-2.632-0.768-4.211-2.285c-2.837,0.593-6.15,1.651-8.828,2.822
      c-0.836,1.774-1.637,3.203-2.383,4.251C21.273,32.654,20.389,33.324,19.514,33.324z M22.176,28.198
      c-2.137,1.201-3.008,2.188-3.071,2.744c-0.01,0.092-0.037,0.334,0.431,0.692C19.685,31.587,20.555,31.19,22.176,28.198z
      M35.813,23.756c0.815,0.627,1.014,0.944,1.547,0.944c0.234,0,0.901-0.01,1.21-0.441c0.149-0.209,0.207-0.343,0.23-0.415
      c-0.123-0.065-0.286-0.197-1.175-0.197C37.12,23.648,36.485,23.67,35.813,23.756z M28.343,17.174
      c-0.715,2.474-1.659,5.145-2.674,7.564c2.09-0.811,4.362-1.519,6.496-2.02C30.815,21.15,29.466,19.192,28.343,17.174z
      M27.736,8.712c-0.098,0.033-1.33,1.757,0.096,3.216C28.781,9.813,27.779,8.698,27.736,8.712z"
                                                      />
                                                      <path
                                                        style={{
                                                          fill: "#CC4B4C",
                                                        }}
                                                        d="M48.037,56H7.963C7.155,56,6.5,55.345,6.5,54.537V39h43v15.537C49.5,55.345,48.845,56,48.037,56z"
                                                      />
                                                      <g>
                                                        <path
                                                          style={{
                                                            fill: "#FFFFFF",
                                                          }}
                                                          d="M17.385,53h-1.641V42.924h2.898c0.428,0,0.852,0.068,1.271,0.205
      c0.419,0.137,0.795,0.342,1.128,0.615c0.333,0.273,0.602,0.604,0.807,0.991s0.308,0.822,0.308,1.306
      c0,0.511-0.087,0.973-0.26,1.388c-0.173,0.415-0.415,0.764-0.725,1.046c-0.31,0.282-0.684,0.501-1.121,0.656
      s-0.921,0.232-1.449,0.232h-1.217V53z M17.385,44.168v3.992h1.504c0.2,0,0.398-0.034,0.595-0.103
      c0.196-0.068,0.376-0.18,0.54-0.335c0.164-0.155,0.296-0.371,0.396-0.649c0.1-0.278,0.15-0.622,0.15-1.032
      c0-0.164-0.023-0.354-0.068-0.567c-0.046-0.214-0.139-0.419-0.28-0.615c-0.142-0.196-0.34-0.36-0.595-0.492
      c-0.255-0.132-0.593-0.198-1.012-0.198H17.385z"
                                                        />
                                                        <path
                                                          style={{
                                                            fill: "#FFFFFF",
                                                          }}
                                                          d="M32.219,47.682c0,0.829-0.089,1.538-0.267,2.126s-0.403,1.08-0.677,1.477s-0.581,0.709-0.923,0.937
      s-0.672,0.398-0.991,0.513c-0.319,0.114-0.611,0.187-0.875,0.219C28.222,52.984,28.026,53,27.898,53h-3.814V42.924h3.035
      c0.848,0,1.593,0.135,2.235,0.403s1.176,0.627,1.6,1.073s0.74,0.955,0.95,1.524C32.114,46.494,32.219,47.08,32.219,47.682z
       M27.352,51.797c1.112,0,1.914-0.355,2.406-1.066s0.738-1.741,0.738-3.09c0-0.419-0.05-0.834-0.15-1.244
      c-0.101-0.41-0.294-0.781-0.581-1.114s-0.677-0.602-1.169-0.807s-1.13-0.308-1.914-0.308h-0.957v7.629H27.352z"
                                                        />
                                                        <path
                                                          style={{
                                                            fill: "#FFFFFF",
                                                          }}
                                                          d="M36.266,44.168v3.172h4.211v1.121h-4.211V53h-1.668V42.924H40.9v1.244H36.266z"
                                                        />
                                                      </g>
                                                      <polygon
                                                        style={{
                                                          fill: "#D9D7CA",
                                                        }}
                                                        points="37.5,0.151 37.5,12 49.349,12 	"
                                                      />{" "}
                                                    </svg>
                                                  )}
                                                <div
                                                  className="w-[100%] h-10 rounded-[73px] py-1.5 text-black placeholder-white sm:text-[20px] px-2 font-normal font-sans cursor-pointer"
                                                  onClick={() =>
                                                    downloadDisciplinaryFile(
                                                      disciplinary_details.file_path
                                                    )
                                                  }
                                                >
                                                  {
                                                    disciplinary_details.employee_disciplinary_original_file_name
                                                  }
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            )}{" "}
                          </form>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="flex items-center justify-center  ">
                    <div className=" w-[100%] bg-[#ffffff] rounded-xl p-8 mt-5">
                      <p className="text-4xl mb-4  font-bold text-[#797C80] ">
                        Bank Details{" "}
                      </p>
                      <hr className="line border-t border-gray-300 w-[25%]"></hr>

                      <div className="mt-10">
                        <form>
                          <div className="flex mt-5 flex-col md:flex-row w-[100%] gap-10">
                            <div className="flex flex-col w-[50%]">
                              <label
                                htmlFor="name"
                                className="text-xl mb-2  font-bold text-[#797C80] font-sans"
                              >
                                Account No{" "}
                              </label>
                              {editMode ? (
                                <input
                                  type="text"
                                  name="employee_account_no"
                                  id="employee_account_no"
                                  autoComplete="employee_account_no"
                                  placeholder="Enter Account No"
                                  value={employeeData.employee_account_no || ""}
                                  onChange={handleInputChange}
                                  className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                                />
                              ) : (
                                <input
                                  type="text"
                                  name="employee_account_no"
                                  id="employee_account_no"
                                  autoComplete="employee_account_no"
                                  placeholder="Enter Account No"
                                  value={employeeData.employee_account_no}
                                  readOnly
                                  className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                                />
                              )}
                            </div>
                            <div className="flex flex-col w-[50%]">
                              <label
                                htmlFor="employee_bank_name"
                                className="text-xl mb-2  font-bold text-[#797C80] font-sans"
                              >
                                Bank Name{" "}
                              </label>
                              {editMode ? (
                                <input
                                  type="text"
                                  name="employee_bank_name"
                                  id="employee_bank_name"
                                  autoComplete="employee_bank_name"
                                  placeholder="Enter Bank"
                                  value={employeeData.employee_bank_name || ""}
                                  onChange={handleInputChange}
                                  className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                                />
                              ) : (
                                <input
                                  type="text"
                                  name="employee_bank_name"
                                  id="employee_bank_name"
                                  autoComplete="employee_bank_name"
                                  placeholder="Enter Bank"
                                  value={employeeData.employee_bank_name}
                                  readOnly
                                  className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                                />
                              )}
                            </div>
                          </div>
                          <div className="flex mt-5 flex-col md:flex-row w-[100%] gap-10">
                            <div className="flex flex-col w-[50%] ">
                              <label
                                htmlFor="employee_branch_name"
                                className="text-xl mb-2  font-bold text-[#797C80] font-sans"
                              >
                                Branch Name{" "}
                              </label>
                              {editMode ? (
                                <input
                                  type="text"
                                  name="employee_branch_name"
                                  id="employee_branch_name"
                                  autoComplete="employee_branch_name"
                                  placeholder="Enter Branch Name"
                                  value={
                                    employeeData.employee_branch_name || ""
                                  }
                                  onChange={handleInputChange}
                                  className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                                />
                              ) : (
                                <input
                                  type="text"
                                  name="employee_branch_name"
                                  id="employee_branch_name"
                                  autoComplete="employee_branch_name"
                                  placeholder="Enter Branch Name"
                                  value={employeeData.employee_branch_name}
                                  readOnly
                                  className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                                />
                              )}
                            </div>
                            <div className="flex flex-col w-[50%] ">
                              <label
                                htmlFor="nic_number"
                                className="text-xl mb-2  font-bold text-[#797C80] font-sans"
                              >
                                Bank code{" "}
                              </label>
                              {editMode ? (
                                <input
                                  type="text"
                                  name="employee_bank_code"
                                  id="employee_bank_code"
                                  autoComplete="employee_bank_code"
                                  placeholder="Enter Bank Code"
                                  value={employeeData.employee_bank_code || ""}
                                  onChange={handleInputChange}
                                  className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                                />
                              ) : (
                                <input
                                  type="text"
                                  name="employee_bank_code"
                                  id="employee_bank_code"
                                  autoComplete="employee_bank_code"
                                  placeholder="Enter Bank Code"
                                  value={employeeData.employee_bank_code}
                                  readOnly
                                  className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                                />
                              )}
                            </div>
                          </div>
                          <div className="flex mt-5 flex-col w-[50%]">
                            <label
                              htmlFor="nic_number"
                              className="text-xl mb-2  font-bold text-[#797C80] font-sans"
                            >
                              Branch code{" "}
                            </label>
                            {editMode ? (
                              <input
                                type="text"
                                name="employee_branch_code"
                                id="employee_branch_code"
                                autoComplete="employee_branch_code"
                                placeholder="Enter Branch Code"
                                value={employeeData.employee_branch_code || ""}
                                onChange={handleInputChange}
                                className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                              />
                            ) : (
                              <input
                                type="text"
                                name="employee_branch_code"
                                id="employee_branch_code"
                                autoComplete="employee_branch_code"
                                placeholder="Enter Branch Code"
                                value={employeeData.employee_branch_code}
                                readOnly
                                className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                              />
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  {/* uploded files  */}
                  <div>
                    <div className="ml-[4%]">
                      <div className="flex items-center mt-[5%] justify-between ">
                        <div>
                          <p className="text-4xl font-bold text-[#797C80] ">
                            Upload Personal Files{" "}
                          </p>
                        </div>
                        <div>
                          {editMode && (
                            <div className=" flex justify-end mr-5  ">
                              <button
                                onClick={handleToggledocument}
                                className="rounded-full bg-newBackground px-3 py-2 text-sm text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 font-sans"
                              >
                                Add New Upload Personal Documents
                              </button>
                            </div>
                          )}
                        </div>
                      </div>

                      <hr className="line border-t border-gray-300 w-[25%]"></hr>
                    </div>{" "}
                    {documentIsOpen && (
                      <div className="fixed top-0 left-0 h-full flex items-center justify-center bg-black bg-opacity-50 z-50 w-full">
                        <div>
                          <Addnewdocument
                            employee_no={employee_no}
                            onClose={handleToggledocument}
                            setFetchData={setFetchKey}
                          />
                        </div>
                      </div>
                    )}
                    <div className="flex items-center justify-center  ">
                      <div className=" w-[100%] bg-[#ffffff] rounded-xl p-8 mt-5">
                        <div className="mt-5">
                          {employeeData && employeeData.upload_files && (
                            <div>
                              <ul>
                                {employeeData.upload_files.map(
                                  (file, index) => (
                                    <li
                                      key={index}
                                      className="flex items-center mt-5"
                                    >
                                      <svg
                                        height="50px"
                                        width="50px"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 56 56"
                                      >
                                        <path
                                          style={{
                                            fill: "#E9E9E0",
                                          }}
                                          d="M36.985,0H7.963C7.155,0,6.5,0.655,6.5,1.926V55c0,0.345,0.655,1,1.463,1h40.074
      c0.808,0,1.463-0.655,1.463-1V12.978c0-0.696-0.093-0.92-0.257-1.085L37.607,0.257C37.442,0.093,37.218,0,36.985,0z"
                                        />
                                        <path
                                          style={{
                                            fill: "#CC4B4C",
                                          }}
                                          d="M19.514,33.324L19.514,33.324c-0.348,0-0.682-0.113-0.967-0.326
      c-1.041-0.781-1.181-1.65-1.115-2.242c0.182-1.628,2.195-3.332,5.985-5.068c1.504-3.296,2.935-7.357,3.788-10.75
      c-0.998-2.172-1.968-4.99-1.261-6.643c0.248-0.579,0.557-1.023,1.134-1.215c0.228-0.076,0.804-0.172,1.016-0.172
      c0.504,0,0.947,0.649,1.261,1.049c0.295,0.376,0.964,1.173-0.373,6.802c1.348,2.784,3.258,5.62,5.088,7.562
      c1.311-0.237,2.439-0.358,3.358-0.358c1.566,0,2.515,0.365,2.902,1.117c0.32,0.622,0.189,1.349-0.39,2.16
      c-0.557,0.779-1.325,1.191-2.22,1.191c-1.216,0-2.632-0.768-4.211-2.285c-2.837,0.593-6.15,1.651-8.828,2.822
      c-0.836,1.774-1.637,3.203-2.383,4.251C21.273,32.654,20.389,33.324,19.514,33.324z M22.176,28.198
      c-2.137,1.201-3.008,2.188-3.071,2.744c-0.01,0.092-0.037,0.334,0.431,0.692C19.685,31.587,20.555,31.19,22.176,28.198z
      M35.813,23.756c0.815,0.627,1.014,0.944,1.547,0.944c0.234,0,0.901-0.01,1.21-0.441c0.149-0.209,0.207-0.343,0.23-0.415
      c-0.123-0.065-0.286-0.197-1.175-0.197C37.12,23.648,36.485,23.67,35.813,23.756z M28.343,17.174
      c-0.715,2.474-1.659,5.145-2.674,7.564c2.09-0.811,4.362-1.519,6.496-2.02C30.815,21.15,29.466,19.192,28.343,17.174z
      M27.736,8.712c-0.098,0.033-1.33,1.757,0.096,3.216C28.781,9.813,27.779,8.698,27.736,8.712z"
                                        />
                                        <path
                                          style={{
                                            fill: "#CC4B4C",
                                          }}
                                          d="M48.037,56H7.963C7.155,56,6.5,55.345,6.5,54.537V39h43v15.537C49.5,55.345,48.845,56,48.037,56z"
                                        />
                                        <g>
                                          <path
                                            style={{
                                              fill: "#FFFFFF",
                                            }}
                                            d="M17.385,53h-1.641V42.924h2.898c0.428,0,0.852,0.068,1.271,0.205
      c0.419,0.137,0.795,0.342,1.128,0.615c0.333,0.273,0.602,0.604,0.807,0.991s0.308,0.822,0.308,1.306
      c0,0.511-0.087,0.973-0.26,1.388c-0.173,0.415-0.415,0.764-0.725,1.046c-0.31,0.282-0.684,0.501-1.121,0.656
      s-0.921,0.232-1.449,0.232h-1.217V53z M17.385,44.168v3.992h1.504c0.2,0,0.398-0.034,0.595-0.103
      c0.196-0.068,0.376-0.18,0.54-0.335c0.164-0.155,0.296-0.371,0.396-0.649c0.1-0.278,0.15-0.622,0.15-1.032
      c0-0.164-0.023-0.354-0.068-0.567c-0.046-0.214-0.139-0.419-0.28-0.615c-0.142-0.196-0.34-0.36-0.595-0.492
      c-0.255-0.132-0.593-0.198-1.012-0.198H17.385z"
                                          />
                                          <path
                                            style={{
                                              fill: "#FFFFFF",
                                            }}
                                            d="M32.219,47.682c0,0.829-0.089,1.538-0.267,2.126s-0.403,1.08-0.677,1.477s-0.581,0.709-0.923,0.937
      s-0.672,0.398-0.991,0.513c-0.319,0.114-0.611,0.187-0.875,0.219C28.222,52.984,28.026,53,27.898,53h-3.814V42.924h3.035
      c0.848,0,1.593,0.135,2.235,0.403s1.176,0.627,1.6,1.073s0.74,0.955,0.95,1.524C32.114,46.494,32.219,47.08,32.219,47.682z
       M27.352,51.797c1.112,0,1.914-0.355,2.406-1.066s0.738-1.741,0.738-3.09c0-0.419-0.05-0.834-0.15-1.244
      c-0.101-0.41-0.294-0.781-0.581-1.114s-0.677-0.602-1.169-0.807s-1.13-0.308-1.914-0.308h-0.957v7.629H27.352z"
                                          />
                                          <path
                                            style={{
                                              fill: "#FFFFFF",
                                            }}
                                            d="M36.266,44.168v3.172h4.211v1.121h-4.211V53h-1.668V42.924H40.9v1.244H36.266z"
                                          />
                                        </g>
                                        <polygon
                                          style={{
                                            fill: "#D9D7CA",
                                          }}
                                          points="37.5,0.151 37.5,12 49.349,12 	"
                                        />{" "}
                                      </svg>
                                      <div
                                        className="text-xl mb-2 font-bold text-[#797C80] font-sans cursor-pointer"
                                        onClick={() =>
                                          downloadFile(
                                            file.employee_upload_path
                                          )
                                        }
                                      >
                                        {file.original_file_name}
                                      </div>

                                      <button
                                        className="w-[15%] font-sans text-black bg-crudBtn text-[15px] p-2 rounded-[50px] ml-10"
                                        onClick={hndledownload}
                                      >
                                        Download
                                      </button>
                                    </li>
                                  )
                                )}
                              </ul>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {editMode && (
                      <div className="ml-[4%] mb-[5%]">
                        <div className="flex mt-5 flex-col md:flex-row w-[100%] gap-10">
                          <div className="flex flex-col w-[50%] ">
                            <label
                              htmlFor="name_with_initials"
                              className="text-xl mb-2  font-bold text-[#797C80] font-sans"
                            >
                              Edit discription{" "}
                            </label>

                            <textarea
                              name="edit_description"
                              id="edit_description"
                              autoComplete="edit_description"
                              placeholder="Enter Edit Description"
                              className="w-[100%] h-[100px] rounded-[10px] py-2 px-3 bg-[#9CAC8B] text-white placeholder-white text-sm font-normal font-sans"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            {employeeData && (
              <div className="h-screen ">
                <div className="ml-10 mr-10">
                  <div className=" ">
                    <div className=" ">
                      <div className="flex w-[100%] justify-between">
                        <div>
                          <p className="font-sans text-[20px] ">
                            Personal Details
                          </p>
                        </div>{" "}
                        <div className="mt-2 mr-1 ">
                          <button
                            className="font-sans text-black bg-crudBtn text-[15px] p-2 rounded-[72px]"
                            onClick={() => setIsOpen(true)}
                          >
                            View More
                          </button>
                        </div>
                      </div>
                      <hr
                        className="w-[20%] my-2 border-none bg-gray-400 rounded-xl"
                        style={{ height: "2px" }}
                      />
                      <div className="grid grid-cols-3 gap-6">
                        <div className="mt-2">
                          <div className="mb-6">
                            <p className="text-[17px] font-[500] font-sans">
                              Full Name
                            </p>
                            <p className="text-[14px] font-[700] mt-1 font-sans">
                              <p className="text-[14px] font-[700] mt-1 font-sans">
                                {employeeData.employee_fullname}
                              </p>
                            </p>
                          </div>

                          <div className="mt-2 mb-6">
                            <div className="mb-4">
                              <p className="text-[17px] font-[500] font-sans">
                                Email
                              </p>
                            </div>

                            <div>
                              <p className="text-[14px] font-[700] mt-1 font-sans">
                                {employeeData.employee_email}
                              </p>
                            </div>
                          </div>

                          <div className="mt-2 mb-6">
                            <p className="text-[17px] font-[500] font-sans">
                              Phone
                            </p>
                            <p className="text-[14px] font-[700] font-sans mt-1">
                              {employeeData.employee_contact_no}
                            </p>
                          </div>
                          <div className="mt-2">
                            <p className="text-[17px] font-[500] font-sans">
                              Permanent Address
                            </p>
                            <p className="text-[14px] font-[700] font-sans mt-1">
                              {employeeData.employee_permanent_address}
                            </p>
                          </div>
                        </div>

                        <div className="mt-2">
                          <div className="mb-6">
                            <p className="text-[17px] font-[500] font-sans">
                              Name with Initials
                            </p>
                            <p className="text-[14px] font-[700] font-sans mt-1">
                              {employeeData.employee_name_initial}{" "}
                            </p>
                          </div>

                          <div className="mt-2 mb-6">
                            <p className="text-[17px] font-[500] font-sans">
                              NIC Number{" "}
                            </p>
                            <p className="text-[14px] font-[700] font-sans mt-1">
                              {employeeData.employee_nic}
                            </p>
                          </div>

                          <div className="mt-2 mb-6">
                            <p className="text-[17px] font-[500] font-sans">
                              Date of Birth
                            </p>
                            <p className="text-[14px] font-[700] mt-1">
                              {employeeData.employee_dob}
                            </p>
                          </div>
                          <div className="mt-2 mb-6">
                            <p className="text-[17px] font-[500] font-sans">
                              Temporary Address
                            </p>
                            <p className="text-[14px] font-[700] mt-1">
                              {employeeData.employee_temporary_address}
                            </p>
                          </div>
                        </div>

                        <div className="mt-2">
                          <div className="mb-6">
                            <p className="text-[17px] font-[500]">Gender</p>
                            <p className="text-[14px] font-[700] mt-1">
                              {employeeData.employee_gender}
                            </p>
                          </div>

                          <div className="mt-2 mb-6">
                            <p className="text-[17px] font-[500] font-sans">
                              Marital Status
                            </p>
                            <p className="text-[14px] font-[700] mt-1">
                              {employeeData.employee_marital_status}
                            </p>
                          </div>
                          <div className="mt-2 mb-6">
                            <p className="text-[17px] font-[500] font-sans">
                              Gramasewa Division
                            </p>
                            <p className="text-[14px] font-[700] font-sans mt-1">
                              {employeeData.employee_gramasewa_division}
                            </p>
                          </div>

                          <div className="mt-2 mb-6">
                            <p className="text-[17px] font-[500] font-sans">
                              Police Division
                            </p>
                            <p className="text-[14px] font-[700] font-sans mt-1">
                              {employeeData.employee_police_division}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
