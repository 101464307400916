/** @format */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FiFile } from "react-icons/fi";

const DialogBidderDetails = ({ onClose, id }) => {
  const navigate = useNavigate();
  const [suppliers, setSuppliers] = useState([]);
  const { REACT_APP_API_ENDPOINT } = process.env;

  const fetchComMembers = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/supplyChain/tender_evaluation_criteria/suppliers-by-pr_id/${id}`
      );
      const data = await response.json();
      setSuppliers(data);
      console.log("Filtered setSuppliers:", data);
    } catch (error) {
      console.error("Error fetching setSuppliers data:", error);
    }
  };

  useEffect(() => {
    console.log("DialogBidderDetails ID:", id);
    if (id) {
      fetchComMembers();
    }
  }, [id]);

  const HandleOnClick = (supplier) => {
    navigate("/committess/openingPriceFormQuoView", {
      state: { selectedRecord: supplier },
    });
  };
  const HandleSelect = async (supplier) => {
    try {
      // Check if the file is uploaded
      if (!supplier.decision_file_path) {
        console.log("doc", supplier.decision_file_path);
        alert("Please upload a file before selecting a supplier.");
        return;
      }

      // Create FormData
      const formData = new FormData();
      formData.append("pr_id", supplier.pr_id);
      formData.append("supplier_id", supplier.supplier_id);
      formData.append("decision_document", supplier.decision_file_path); // Ensure the file is attached
      formData.append("status", "APPROVE");

      // Debugging: Log form data
      for (let pair of formData.entries()) {
        console.log(`${pair[0]}: ${pair[1]}`);
      }

      // Send PATCH request
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/supplyChain/tender_evaluation_criteria/choose-supplier`,
        {
          method: "PATCH",
          body: formData,
        }
      );

      // Check if the response is OK (status code 200-299)
      if (response.ok) {
        const responseText = await response.text();
        console.log("Response:", responseText);
        alert("Supplier successfully selected.");
        onClose(); // Call any close function if needed
      } else {
        // If response is not OK, parse the error message as text
        const errorText = await response.text();
        console.error("Error Response:", errorText);

        // Show the error message directly
        alert(
          errorText || "Failed to select supplier due to an unknown error."
        );
      }
    } catch (error) {
      // Handle errors that occur during the fetch or parsing stages
      console.error("Error:", error);
      alert(
        error.message || "Failed to add tender due to an unexpected error."
      );
    }
  };

  const handleFileUpload = (index, file) => {
    const updatedTableData = [...suppliers];
    updatedTableData[index].decision_file_path = file; // Make sure the file is assigned correctly
    setSuppliers(updatedTableData);
  };

  // Function to remove the uploaded file
  const handleFileRemove = (index) => {
    const updatedTableData = [...suppliers];
    updatedTableData[index].decision_file_path = null; // Remove the file for that item
    setSuppliers(updatedTableData);
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center z-50">
      <div className="bg-gray-800 bg-opacity-75 fixed inset-0"></div>
      <div className="bg-background rounded-[24px] p-8 z-10 w-[80%] h-[75%]">
        <div className="ml-[97%]">
          <button
            onClick={onClose}
            className="absolute flex justify-center border-none rounded-[22px] bg-clsDialog w-[30.359px] h-[28px] flex-shrink-0"
          >
            <span className="text-white font-bold text-xl">X</span>
          </button>
        </div>
        <div>
          <h2 className="text-[#071C50] font-poppins font-semibold text-[26px] ml-[5%] mt-4">
            Supplier Details
          </h2>
          <hr className="w-1/12 border-t-4 border-newBackground ml-[5%] rounded-[22px]" />
        </div>
        <div className="overflow-y-auto max-h-[calc(100vh-200px)] ml-5 mr-5 rounded-[22px] ">
          <table className="table-auto border-none overflow-x-auto w-full">
            <thead>
              <tr className="bg-white font-sans text-[16px]">
                <th className="text-primary px-6 py-4">Quotation No</th>
                <th className="text-primary px-6 py-4">Supplier ID</th>
                <th className="text-primary px-6 py-4">Supplier Name</th>
                <th className="text-primary px-6 py-4">Net Total</th>
                <th className="text-primary px-6 py-4">Status</th>
                <th className="text-primary px-6 py-4">Upload</th>
                <th className="text-primary px-6 py-4">Action</th>
              </tr>
            </thead>
            <tbody>
              {suppliers && suppliers.length > 0 ? (
                suppliers.map((supplier, index) => (
                  <tr key={index}>
                    <td className="bg-white px-6 py-4 text-center">
                      {supplier.qoutation_id}
                    </td>
                    <td className="bg-white px-6 py-4 text-center">
                      {supplier.supplier_id}
                    </td>
                    <td className="bg-white px-6 py-4 text-center">
                      {supplier.supplier_name}
                    </td>
                    <td className="bg-white px-6 py-4 text-center">
                      {supplier.net_total}
                    </td>
                    <td className="bg-white px-6 py-4 text-center">
                      {supplier.status}
                    </td>

                    <td className="bg-white px-6 py-4 text-center">
                      {/* Show the file upload input when status is not "APPROVE" */}
                      {supplier.status !== "APPROVE" ? (
                        <input
                          type="file"
                          onChange={(e) =>
                            handleFileUpload(index, e.target.files[0])
                          }
                          className="text-gray-600 bg-white border border-gray-300 rounded-md px-3 py-2 focus:outline-none w-[230px]"
                        />
                      ) : (
                        /* Show the file icon and file name when the status is "APPROVE" */
                        supplier.decision_file_path && (
                          <div className="flex items-center justify-center bg-[#E7F1FF] rounded-lg p-2">
                            <a
                              href={`${REACT_APP_API_ENDPOINT}/v1/supplyChain/tender_evaluation_criteria/get-desition-doc-by-name/${supplier.decision_file_path}`}
                              download={supplier.decision_file_path} // Make the file downloadable
                              className="flex items-center cursor-pointer"
                            >
                              <FiFile className="text-gray-600 mr-2" />
                              <span>
                                {supplier.decision_file_path.split("/").pop()}
                              </span>{" "}
                              {/* Display the file name */}
                            </a>
                          </div>
                        )
                      )}
                    </td>

                    <td className="bg-white px-6 py-4 text-center">
                      <button
                        className="bg-gray-300 p-2 items-center rounded-md w-[80px] mr-2"
                        onClick={() => HandleOnClick(supplier)}
                      >
                        View
                      </button>
                      <button
                        className="bg-gray-300 p-2 items-center rounded-md w-[80px]"
                        onClick={() => HandleSelect(supplier)}
                      >
                        Select
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="7" // Adjusted to cover all columns
                    className="text-center border-b border-gray-300 p-4 text-gray-500"
                  >
                    No supplier details available.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default DialogBidderDetails;
