/** @format */

import React, { useState, useEffect } from "react";
import { FaEye } from "react-icons/fa";
import usePermissions from "../../../components/permissions/permission";

const CreatedRecipe = () => {
  const { hasPermission } = usePermissions();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 7;
  const [data, setData] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const { REACT_APP_API_ENDPOINT } = process.env;

  useEffect(() => {
    fetch(
      `${REACT_APP_API_ENDPOINT}/v1/production/job/createdjobapproveORreject?status=APPROVE`
    )
      .then((response) => response.json())
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        console.error("Error fetching created jobs:", error);
      });
  }, []);

  const totalPages = Math.ceil(data.length / itemsPerPage);
  const maxPageNumbers = 5;

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(1, currentPage - Math.floor(maxPageNumbers / 2));
    const endPage = Math.min(totalPages, startPage + maxPageNumbers - 1);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`px-3 py-1 rounded-md mr-2 ${
            i === currentPage ? "bg-gray-300" : "bg-gray-200"
          }`}
        >
          {i}
        </button>
      );
    }

    return pageNumbers;
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // New function to handle recipe click
  const handleRecipeClick = (item) => {
    setSelectedJob(item);
  };

  // Function to close the popup
  const handleClosePopup = () => {
    setSelectedJob(null);
  };

  // Filter data based on pagination
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const filteredData = data.slice(startIndex, endIndex);

  return (
    <div className=" bg-white">
      <p className="text-[32px] ml-6 mt-5 bg-newBackground2 w-[100%] rounded-xl">
        Approved Jobs
      </p>
      <div className="flex mt-10">
        <table className="overflow-y-auto border-collapse border border-gray-200 rounded-lg ml-6">
          <thead>
            <tr className="bg-newBackground">
              <th className="border border-gray-200 px-10 py-4 w-[520px] text-center">
                Job Name
              </th>
              <th className="border border-gray-200 px-10 py-4 w-[520px] text-center">
                Made By
              </th>
              <th className="border border-gray-200 px-10 py-4 w-[520px] text-center">
                View
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => (
              <tr key={index} className="border border-gray-200 px-4 py-2">
                <td className="text-center">{item.job_name}</td>
                <td className="text-center">{item.recipe_dr}</td>
                <td className="text-center">
                  <button
                    onClick={() => handleRecipeClick(item)}
                    className="text-gray-400 hover:text-gray-800 focus:outline-none"
                  >
                    <FaEye />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* Pagination */}
      <div className="flex justify-center mt-4">
        <button
          onClick={handlePrevious}
          className="px-3 py-1 rounded-md mr-2 bg-gray-200 cursor-pointer"
          disabled={currentPage === 1}
        >
          {"<"}
        </button>
        {renderPageNumbers()}
        <button
          onClick={handleNext}
          className="px-3 py-1 rounded-md mr-2 bg-gray-200 cursor-pointer"
          disabled={currentPage === totalPages}
        >
          {">"}
        </button>
      </div>
      {/* End Pagination */}

      {selectedJob && (
        <div className="fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-75 flex justify-center items-center">
          <div className="relative bg-white p-8 rounded-lg max-w-md overflow-y-auto h-[500px]">
            <button
              onClick={handleClosePopup}
              className="absolute top-2 right-2 text-gray-600 hover:text-gray-800 focus:outline-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <h2 className="text-2xl font-bold mb-4">Job Details</h2>
            <p className="text-lg">Job Name: {selectedJob.job_name}</p>
            <p className="text-lg">
              Priority Level: {selectedJob.job_priority_level}
            </p>
            <p className="text-lg">Created By: {selectedJob.recipe_dr}</p>
            <p className="text-lg">Recipe Name: {selectedJob.recipe_name}</p>
            <p className="text-lg">Job Quantity: {selectedJob.job_quantity}</p>
            <p className="text-lg">Units: {selectedJob.unit_symbol}</p>
            <p className="text-lg">
              Created at: {new Date(selectedJob.job_create_at).toLocaleString()}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreatedRecipe;
