/** @format */

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import usePermissions from "../../../components/permissions/permission";
import { IoMdCloseCircle } from "react-icons/io";

export default function UpdateStores() {
  const { hasPermission } = usePermissions();
  const location = useLocation();
  const navigate = useNavigate();
  const currentDate = new Date(); // Get the current date and time
  const formattedDate = currentDate.toISOString(); // Format the date to ISO string
  const [userId, setUserId] = useState("USER-000000");
  const selectedRecord = location.state?.selectedRecord || {};
  const [mainCategories, setMainCategories] = useState([]);
  const [subItem, setSubItem] = useState("");
  const [selectedSubItems, setSelectedSubItems] = useState(
    selectedRecord.store_sub_category || []
  );
  const [subItemList, setSubItemList] = useState([]);

  const [tableData, setTableData] = useState({
    store_id: "",
    store_name: "",
    store_main_category: "",
    store_sub_category: [],
    store_description: "",
    store_update_date: "",
    store_update_user_id: "",
    store_status: "",
  });

  // Fetch main categories on component mount
  useEffect(() => {
    fetchMainCategories();
  }, []);

  // Set tableData once selectedRecord is available
  useEffect(() => {
    if (selectedRecord) {
      setTableData({
        store_id: selectedRecord.store_id,
        store_name: selectedRecord.store_name,
        store_main_category: selectedRecord.store_main_category,
        store_sub_category: selectedRecord.store_sub_category,
        store_description: selectedRecord.store_description,
        store_update_date: selectedRecord.store_update_date,
        store_update_user_id: selectedRecord.store_update_user_id,
        store_status: selectedRecord.store_status,
      });
      if (selectedRecord.store_main_category) {
        fetchSubItems(selectedRecord.store_main_category);
      }
    }
  }, [selectedRecord]);

  const fetchMainCategories = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/itemCategory/get-category`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      if (
        result.item_category_names &&
        Array.isArray(result.item_category_names)
      ) {
        setMainCategories(result.item_category_names);
        console.log("main categories:", mainCategories);
      } else {
        console.error("Unexpected response format:", result);
      }
    } catch (error) {
      console.error("Error fetching main categories:", error);
    }
  };

  const fetchSubItems = async (storeMainCategory) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/itemCategory/get-sub-category?item_category_name=${storeMainCategory}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      if (
        data.item_sub_category_names &&
        Array.isArray(data.item_sub_category_names)
      ) {
        setSubItemList(
          data.item_sub_category_names.map((subItem) => ({
            store_sub_category: subItem,
          }))
        );
      } else {
        console.error("Unexpected response format:", data);
      }
    } catch (error) {
      console.error("Error fetching sub-items:", error);
    }
  };

  const handleAddSubItem = () => {
    if (subItem && !selectedSubItems.includes(subItem)) {
      setSelectedSubItems((prevSelectedSubItems) => [
        ...prevSelectedSubItems,
        subItem,
      ]);
      setSubItem(""); // Reset selected sub-item after adding
    }
  };

  const handleSubItemChange = (event) => {
    setSubItem(event.target.value);
  };

  const handleDeleteSubItem = (index) => {
    setSelectedSubItems((prevSelectedSubItems) =>
      prevSelectedSubItems.filter((_, i) => i !== index)
    );
  };

  const UpdateItemHandler = async () => {
    if (tableData.store_id !== "") {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/store/${tableData.store_id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              ...tableData,
              store_sub_category: selectedSubItems, // Update the sub-items here
              store_update_date: formattedDate,
              store_update_user_id: "USER-00001",
            }),
          }
        );

        if (response.ok) {
          alert("Store Updated Successfully");
          navigate("/stores/view");
        } else {
          const errorData = await response.json();
          if (response.status === 409) alert("Store Already Exists");
          else if (response.status === 400) alert("Store Not Updated");
          else if (response.status === 500) alert("Internal Server Error");
          else if (response.status === 404) alert("Store Not Found");
          else if (response.status === 403) alert("Forbidden");
          else if (response.status === 401) alert("Unauthorized");
          else alert(`Error: ${errorData.message}`);
        }
      } catch (error) {
        console.error("Error updating store:", error);
        alert("An error occurred while updating the store.");
      }
    }
  };

  const DeleteItemHandler = async () => {
    if (tableData.store_id !== "") {
      if (tableData.store_status === "INACTIVE") {
        alert("Store is already inactive.");
        return; // Exit the function if the store is already inactive
      }
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/store/${tableData.store_id}`,
          {
            method: "DELETE",
          }
        );

        if (response.ok) {
          alert("Store Deleted Successfully");
          navigate(-1);
        } else {
          const errorData = await response.json();

          if (response.status === 409) alert("Store Already Exists");
          else if (response.status === 400)
            alert(
              "Cannot Delete store as it has associated items in inventory"
            );
          else if (response.status === 500) alert("Internal Server Error");
          else if (response.status === 404) alert("Store Not Found");
          else if (response.status === 403) alert("Forbidden");
          else if (response.status === 401) alert("Unauthorized");
          else if (errorData.error) {
            alert(`Error: ${errorData.error}`); // Display the specific backend error message
          } else {
            alert("An unexpected error occurred.");
          }
        }
      } catch (error) {
        console.error("Error deleting store:", error);
        alert("An error occurred while deleting the store.");
      }
    }
  };

  const CancelItemHandler = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <div className="bg-background h-screen overflow-y-auto">
      <div>
        <p className="font-sans text-[#797C80] text-[32px] ml-6 mt-6 font-bold">
          Update Stores
        </p>
      </div>

      <div className="w-full flex items-center justify-center">
        <div className="bg-[#F3F8FF] rounded-[46px] border border-[#00000036] px-[200px] py-11 my-10 flex flex-col items-center justify-center">
          <p className="header-1 text-center">Update Stores</p>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Store Id</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              disabled
              value={tableData.store_id}
              onChange={(e) => {
                setTableData({ ...tableData, store_id: e.target.value });
              }}
            />
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Store Name</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              value={tableData.store_name}
              onChange={(e) => {
                setTableData({ ...tableData, store_name: e.target.value });
              }}
            />
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Item Main Type</label>
            <label className="label-1">:</label>
            <select
              className="input-1 border border-[#00000036]"
              value={tableData.store_main_category}
              onChange={(e) => {
                setTableData({
                  ...tableData,
                  store_main_category: e.target.value,
                });
                fetchSubItems(e.target.value); // Fetch sub-items on main category change
              }}
            >
              <option value="" disabled>
                Select Item Type
              </option>
              {mainCategories.length > 0 ? (
                mainCategories.map((category, index) => (
                  <option key={index} value={category}>
                    {category}
                  </option>
                ))
              ) : (
                <option value="" disabled>
                  No Categories Available
                </option>
              )}
            </select>
          </div>

          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Stores Description</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              value={tableData.store_description}
              onChange={(e) => {
                setTableData({
                  ...tableData,
                  store_description: e.target.value,
                });
              }}
            />
          </div>
          <div className="w-[780px] flex items-center justify-between my-10">
            <label className="label-1">Sub Item Category</label>
            <label className="label-1">:</label>
            <div className="flex items-center">
              <select
                className="input-1 border border-[#00000036]"
                value={subItem}
                onChange={handleSubItemChange}
              >
                <option value="">Select Sub Item Category</option>
                {subItemList.map((subItem, index) => (
                  <option key={index} value={subItem.store_sub_category}>
                    {subItem.store_sub_category}
                  </option>
                ))}
              </select>
              <button
                onClick={handleAddSubItem}
                className="bg-newBackground text-white h-[30px] w-[70px] rounded-md ml-5"
                disabled={!subItem}
              >
                Add
              </button>
            </div>
          </div>

          <div className="w-[600px] flex flex-wrap gap-2 ml-40">
            {selectedSubItems.map((item, index) => (
              <div
                key={index}
                className="flex items-center ml-40 text-[12px] bg-newBackground2 justify-between rounded-lg p-1"
              >
                <div>{item}</div>
                <IoMdCloseCircle
                  onClick={() => handleDeleteSubItem(index)}
                  className="text-black cursor-pointer ml-5"
                  size={20}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className=" flex items-center justify-end gap-x-6 mb-10 px-[150px]">
        <button
          className="w-[118px] h-[45px] flex items-center justify-center bg-transparent border border-black rounded-[23px] px-4"
          onClick={CancelItemHandler}
        >
          <p className="p2 text-black">Back</p>
        </button>
        {hasPermission(1720) && (
          <button
            className="w-[118px] h-[45px] flex items-center justify-center bg-[#ab2626] border rounded-[23px] px-4"
            onClick={DeleteItemHandler}
          >
            <p className="p2 text-white">Delete</p>
          </button>
        )}
        {hasPermission(1710) && (
          <button
            className="w-[118px] h-[45px] flex items-center justify-center bg-newBackground border rounded-[23px] px-4"
            onClick={UpdateItemHandler}
          >
            <p className="p2 text-white">Update</p>
          </button>
        )}
      </div>
    </div>
  );
}
