 

import React, { useState, useEffect } from "react";
import Add_Examination from "./add_examination";
import fileIcon from "../../../../../Assets/icons/png.png";
import { GrPrevious, GrNext } from "react-icons/gr";
import usePermissions from "../../../../../components/permissions/permission";

const Completed_Examinations = () => {
  const { hasPermission } = usePermissions();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [examData, setExamData] = useState([]);
  const [fetchKey, setFetchKey] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [deleteIndexEmployee, setDeleteIndexEmployee] = useState(null);
  const [selectedDate, setSelectedDate] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const { REACT_APP_API_ENDPOINT } = process.env;

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${REACT_APP_API_ENDPOINT}/v1/hris/performance/examination/getexaminations`
        );
        const data = await response.json();
        setExamData(data);
      } catch (error) {
        console.error("Error fetching exam data:", error);
      }
    };

    fetchData();
  }, [fetchKey]);

  const handleDownload = (filePath) => {
    console.log("Attempting to download file at path:", filePath);
    if (!filePath) {
      alert("No file available for download.");
      return;
    }
    const downloadUrl = `${REACT_APP_API_ENDPOINT}/v1/hris/employees/downloadfile?file_path=${encodeURIComponent(
      filePath
    )}`;
    window.location.href = downloadUrl;
  };

  const handleDeleteEmployee = (index) => {
    console.log("Deleting exam at index:", index);
    setDeleteIndexEmployee(null);
  };

  const filteredExams = examData.filter(
    (exam) =>
      exam.examination_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      (exam.examination_purpose
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) &&
        (selectedDate
          ? new Date(exam.examination_date).toDateString() ===
            new Date(selectedDate).toDateString()
          : true))
  );

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredExams.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="bg-background h-screen overflow-y-auto">
      <p className="text-headerColor font-sans ml-6 mt-6 text-[30px] font-semibold">
        Completed Examinations
      </p>
      <div className="flex justify-between items-center">
        <div className="mt-6 ml-6">
          <div className="flex flex-col font-poppins font-bold ml-6 mt-4">
            <div className="relative font-sans group hidden sm:block">
              <input
                type="text"
                placeholder="Search by Date, Purpose, Name"
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-[200px] sm:w-[300px] h-10 duration-300 rounded-full border border-white px-2 py-1"
              />
            </div>
          </div>
        </div>
        {hasPermission(10010) && (
          <div className="bg-white rounded-[22px] h-[189px] w-[430px] mr-[5%]">
            <div className="text-[#797C80] font-bold ml-4 mt-4 text-[29px]">
              <p>Create an</p>
              <p>Upcoming Examination</p>
            </div>
            <div className="flex items-center justify-between">
              <button
                onClick={togglePopup}
                className="bg-primary mt-5 ml-4 p-2 rounded-[32px] text-white font-semibold"
              >
                Click Here
              </button>
              {isPopupOpen && (
                // eslint-disable-next-line react/jsx-pascal-case
                <Add_Examination
                  onClose={() => {
                    togglePopup();
                    setFetchKey((prevKey) => prevKey + 1);
                  }}
                  fetchKey={fetchKey}
                />
              )}
            </div>
          </div>
        )}
      </div>

      {/* <div className="flex justify-end mt-[1rem] mr-[5rem]">
        <div className="flex justify-between items-center gap-3 font-sans font-semibold">
          <div>
            <p className="text-headerColor">Select dates</p>
          </div>
          <div>
            <input
              type="date"
              required=""
              onChange={(e) => setSelectedDate(e.target.value)}
              className="input rounded-full h-10 px-8 py-3 border-2 border-transparent focus:outline-none focus:border-blue-500 placeholder-gray-400"
            />
          </div>
        </div>
      </div> */}

      <div>
        <div className="flex flex-col font-sans font-bold ml-6 mt-6">
          <table className="table-auto border-none w-[95%]">
            <thead>
              <tr className="bg-white rounded-[100px]">
                <th className="text-primary px-4 py-2">Examination Name</th>
                <th className="text-primary px-4 py-2">Date</th>
                <th className="text-primary px-4 py-2">Time</th>
                <th className="text-primary px-4 py-2">Purpose</th>
                <th className="text-primary px-4 py-2">Candidates</th>
                <th className="text-primary px-4 py-2">
                  Progress of Candidates
                </th>
                {hasPermission(10011) && (
                  <th className="text-primary px-4 py-2">Action</th>
                )}
              </tr>
            </thead>
            <tbody>
              {currentItems.map((exam, index) => (
                <tr key={exam.id}>
                  <td className="bg-white px-4 py-2">
                    {exam.examination_name}
                  </td>
                  <td className="bg-white px-4 py-2">
                    {exam.examination_date}
                  </td>
                  <td className="bg-white px-4 py-2">
                    {exam.examination_time}
                  </td>
                  <td className="bg-white px-4 py-2">
                    {exam.examination_purpose}
                  </td>
                  <td className="bg-white px-4 py-2">
                    {exam.examination_candidates}
                  </td>
                  <td
                    className="bg-white cursor-pointer flex items-center w-[100%] h-[70px] gap-2 text-[12px]"
                    onClick={() =>
                      handleDownload(exam.examination_progress_file_path)
                    }
                  >
                    {exam.examination_progress_file_path ? (
                      <>
                        <img
                          src={fileIcon}
                          alt="File Icon"
                          className="w-7 h-7 cursor-pointer"
                        />
                        <div>{exam.examination_progress_file_name}</div>
                      </>
                    ) : (
                      <span>No file available</span>
                    )}
                  </td>
                  {hasPermission(10011) && (
                    <td className="bg-white">
                      <div className="flex justify-between gap-4 cursor-pointer">
                        <button
                          onClick={() => setDeleteIndexEmployee(index)}
                          className="bg-newBackground text-white p-2 w-[95px]  rounded-[26px]"
                        >
                          Delete
                        </button>
                        {deleteIndexEmployee === index && (
                          <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
                            <div className="bg-white p-4 rounded-md shadow-lg">
                              <p className="mb-4">
                                Are you sure you want to remove this
                                Examination?
                              </p>
                              <div className="flex justify-end">
                                <button
                                  onClick={() => handleDeleteEmployee(index)}
                                  className="bg-red-600 text-white px-4 py-2 rounded-md mr-2"
                                >
                                  Delete
                                </button>
                                <button
                                  onClick={() => setDeleteIndexEmployee(null)}
                                  className="bg-gray-300 px-4 py-2 rounded-md"
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="mb-4">
        {/* Pagination */}
        <div className="flex justify-center mt-4">
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            className="bg-primary text-white px-4 py-2 mx-1 rounded-md"
          >
            <GrPrevious className="w-3 h-3" />
          </button>
          {[
            ...Array(Math.ceil(filteredExams.length / itemsPerPage)).keys(),
          ].map((pageNumber) => (
            <button
              key={pageNumber + 1}
              onClick={() => paginate(pageNumber + 1)}
              className={`px-4 py-2 mx-1 rounded-md ${
                currentPage === pageNumber + 1
                  ? "bg-primary text-white"
                  : "bg-gray-200"
              }`}
            >
              {pageNumber + 1}
            </button>
          ))}
          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={
              currentPage === Math.ceil(filteredExams.length / itemsPerPage)
            }
            className="bg-primary text-white px-4 py-2 mx-1 rounded-md"
          >
            <GrNext className="w-3 h-3" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Completed_Examinations;
