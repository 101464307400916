import React, { useEffect, useState } from "react";
import axios from "axios";
import AsyncSelect from "react-select/async";
import Alert from "../../../components/alert";
import usePermissions from "../../../components/permissions/permission";

export default function UpdateCustomer() {
  const { hasPermission } = usePermissions();
  const [alert, setAlert] = useState({
    status: "",
    alert: "",
    show: false,
  });

  const [customerDetails, setCustomerDetails] = useState({
    id: "",
    name: "",
    nic: "",
    address: "",
    contact: "",
    email: "",
    customerType: null,
    regNo: "",
    paymentMethod: "",
    creditLimit: "",
    availableCreditLimit: "",
    category: "",
  });
  const [customerTypes, setCustomerTypes] = useState([]);

  const customStyles = {
    control: (base) => ({
      ...base,
      borderColor: "#dcdcdc",
      boxShadow: "none",
      "&:hover": { borderColor: "#b3b3b3" },
    }),
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
  };

  useEffect(() => {
    axios
      .get(
        "https://back-ayurveda.erp-quortech.com/v1/inventory/customer/customer-types"
      )
      .then((response) => {
        setCustomerTypes(
          response.data.map((type) => ({
            value: type.id,
            label: type.customer_type,
          }))
        );
        console.log("Customer Types Loaded:", response.data);
      })
      .catch((error) => {
        console.error("Failed to fetch customer types", error);
      });
  }, []);
  const handleTypeChange = (selectedOption) => {
    setCustomerDetails(prevDetails => ({
      ...prevDetails,
      customerType: selectedOption // Set the whole object as the selected customer type
    }));
  };
  
  const handleCustomerSelect = async (selectedOption) => {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/customer/get/${selectedOption.value}`
    );
    const customer = response.data[0];
    const customerTypeObj = customerTypes.find(
      (ct) => ct.id === customer.customer_type_id
    );
    setCustomerDetails({
      id: customer.customer_id,
      name: customer.customer_name,
      nic: customer.customer_nic,
      address: customer.customer_address,
      contact: customer.customer_contact_no,
      email: customer.customer_email,
      customerType: customerTypeObj || null,
      regNo: customer.reg_no,
      paymentMethod: customer.customer_payment_method,
      creditLimit: customer.credit_limit,
      availableCreditLimit: customer.available_credit_limit,
      category: customer.customer_category,
    });
  };
  const handleUpdateCustomer = async (event) => {
    event.preventDefault();
    if (!customerDetails.customerType) {
      console.error("Customer type is undefined or not selected. Please select a customer type.");
      setAlert({ status: "error", alert: "Please select a customer type before updating.", show: true });
      return; // Prevent sending the request if customer type is not selected
    }

    const updateData = {
      customer_id: customerDetails.id,
      customer_name: customerDetails.name,
      customer_nic: customerDetails.nic,
      customer_address: customerDetails.address,
      customer_contact_no: customerDetails.contact,
      customer_email: customerDetails.email,
      customer_type: customerDetails.customerType.value, 
      reg_no: customerDetails.regNo,
      customer_update_user_id: "USER-000000",
      customer_update_date: new Date().toISOString().split("T")[0],
      payment_method: customerDetails.paymentMethod,
      credit_limit: Number(customerDetails.creditLimit),
      available_credit_limit: Number(customerDetails.availableCreditLimit),
      category: customerDetails.category,
    };

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/customer/update`,
        updateData
      );
      setAlert({
        status: "success",
        alert: "Customer Updated Successfully",
        show: true,
      });
    } catch (error) {
      console.error("Error updating customer:", error);
      setAlert({
        status: "error",
        alert: error.response?.data.message || "Customer Update Failed",
        show: true,
      });
    }
  };
  return (
    <div className="w-full h-full flex flex-col items-center justify-center px-20 bg-background">
      <Alert
        show={alert.show}
        alert={alert.alert}
        status={alert.status}
        ok={() => setAlert({ status: "", alert: "", show: false })}
      />
      <div className="w-full flex justify-start">
        <p className="header-1 mt-5">View & Update Customer</p>
      </div>
      <div className="w-full flex justify-start mt-10 gap-10">
        <p className="label-1">Find Customer:</p>
        <AsyncSelect
        className="w-[285px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold"
        cacheOptions
          loadOptions={(inputValue) =>
            axios
              .get(
                `${process.env.REACT_APP_BACKEND_URL}/customer/search/name/contact/status/${inputValue}/ACTIVE`
              )
              .then((response) =>
                response.data.map((customer) => ({
                  value: customer.customer_id,
                  label: customer.customer_name,
                }))
              )
          }
          onChange={handleCustomerSelect}
        />
      </div>
      <form
        className="w-full my-10 px-10 bg-[#F3F8FF] rounded-[46px] border border-[#00000036]"
        onSubmit={handleUpdateCustomer}
      >
        <p className="header-1 w-full text-center">Enter Customer Details</p>
        <div className="w-full flex justify-between flex-wrap gap-y-10 my-12">
          {Object.keys(customerDetails).map((key) => {
            if (key !== "customerType") {
              return (
                <InputField
                  key={key}
                  label={key.replace(/([A-Z])/g, " $1").trim()}
                  value={customerDetails[key]}
                  onChange={(e) =>
                    setCustomerDetails({
                      ...customerDetails,
                      [key]: e.target.value,
                    })
                  }
                  disabled={key === "id"}
                />
              );
            }
            return null;
          })}
          <SelectDropdown
            label="Customer Type"
            options={customerTypes}
            value={customerDetails.customerType}
            onChange={(selected) =>
              setCustomerDetails({ ...customerDetails, customerType: selected })
            }
          />
        </div>
        <div className="flex w-full items-center justify-end gap-x-10 my-10">
          <button
            type="button"
            className="w-[118px] h-[45px] flex items-center justify-center bg-[#AA4343] border rounded-[23px] px-4 text-white font-bold text-xl"
            onClick={() => console.log("Cancelled")}
          >
            Cancel
          </button>
          {hasPermission(1560) && (
            <button
              type="submit"
              className="w-[118px] h-[45px] flex items-center justify-center bg-newBackground border rounded-[23px] px-4 text-white font-bold text-xl"
            >
              Update
            </button>
          )}
        </div>
      </form>
    </div>
  );
}

function InputField({ label, value, onChange, disabled = false }) {
  return (
    <div className="flex items-center justify-between w-[600px]">
      <p className="label-1">{label}:</p>
      <input
        type="text"
        className="w-[285px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold"
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  );
}

function SelectDropdown({ label, options, value, onChange }) {
  return (
    <div className="flex items-center justify-between w-[600px]">
      <p className="label-1">{label}:</p>
      <select
        className="w-[285px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold"
        value={value?.value || ''}
        onChange={(e) => {
          const selectedOption = options.find(option => option.value.toString() === e.target.value);
          onChange(selectedOption);
        }}
      >
        <option value="">Select</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}
