/** @format */

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { GrPrevious, GrNext } from "react-icons/gr";
import { FiFile } from "react-icons/fi";

const Decisions_Supplier_Pending = () => {
  const [data, setdata] = useState([]);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [items, setItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [selectComStatus, setSelectComStatus] = useState("APPROVED");
  const [selectComType, setSelectComType] = useState("TENDER COMMITTEE");
  const navigate = useNavigate();

  useEffect(() => {
    refreshData();
  }, []);

  const refreshData = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_ENDPOINT}/v1/supplyChain/tender_evaluation_criteria/TEC-Approved-suppliers`
      );
      setdata(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setdata([]);
    }
  };
  const openDialog = async (item) => {
    // Prepare the request body
    const requestBody = {
      quotation_id: item.qoutation_id,
      authorized_by: item.status,
      committee_id: item.tenderCom,
    };

    try {
      // Send the PATCH request to the API
      const response = await axios.post(
        `${REACT_APP_API_ENDPOINT}/v1/supplyChain/tender_evaluation_criteria/pass-for-approve-by-suppmanager`,
        requestBody
      );

      // Handle the responsereqDate
      console.log("Response:", response.data);
      alert("Pending Purchase Request successfully sent for approvals");
      refreshData();
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
      alert("Failed to send approvals Purchase Request");
    }
  };

  // Pagination Logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const filteredEmployees = data.filter((item) => {
    const searchMatch =
      !searchQuery ||
      item.inventory_request_by
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      item.inventory_request_id
        .toLowerCase()
        .includes(searchQuery.toLowerCase());

    return searchMatch;
  });

  const currentItems = filteredEmployees.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleStatusChange = (index, newStatus) => {
    const updatedData = [...data];
    updatedData[index].status = newStatus;
    setdata(updatedData);
  };

  const handleTenderCom = (index, tenderCom) => {
    const updatedData = [...data];
    updatedData[index].tenderCom = tenderCom;
    setdata(updatedData);
  };

  useEffect(() => {
    if (selectComStatus !== null) {
      fetchItemData();
    }
  }, [selectComStatus, selectComType]);

  const fetchItemData = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/supplyChain/committee/members/all/committees?status=${selectComStatus}&committee_title=${selectComType}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setItems(data);
      console.log("Data", data);
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  return (
    <div className="bg-background h-screen overflow-y-auto">
      <p className="text-[25px] font-sans ml-6 mt-6 font-bold text-[#797C80]">
        Decisions
      </p>
      <div className="flex justify-between overflow-hidden pb-6">
        <div>
          <div className="flex flex-col font-poppins font-bold ml-6 mt-4">
            <div className="relative font-sans group hidden sm:block">
              <input
                type="text"
                placeholder="Search by Purchase Request No"
                // value={searchQuery}
                // onChange={handleSearchChange}
                className="w-[200px] sm:w-[300px] h-10 duration-300 rounded-full border border-white px-2 py-1"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="overflow-y-auto max-h-[calc(100vh-200px)] ml-5 mr-5 rounded-[22px] ">
        <table className="table-auto border-none overflow-x-auto w-full">
          <thead>
            <tr className="bg-white font-sans text-[16px]">
              <th className="text-primary px-6 py-4">Purchase Request No</th>
              <th className="text-primary px-6 py-4">Quotation No</th>
              <th className="text-primary px-6 py-4">Supplier No</th>
              <th className="text-primary px-6 py-4">Supplier Name</th>
              <th className="text-primary px-6 py-4">Net Total</th>
              <th className="text-primary px-6 py-4">Decision Doc</th>
              <th className="text-primary px-6 py-4">Quotation Doc</th>
              <th className="text-primary px-6 py-4">Approval Person</th>
              {/* Conditionally show Select Committee header */}
              {data.some((item) => item.status === "TENDER") && (
                <th className="text-primary px-6 py-4">Select Committee</th>
              )}
              <th className="text-primary px-6 py-4">Action</th>
            </tr>
          </thead>
          <tbody className="font-bold">
            {data && data.length > 0 ? (
              data.map((item, index) => (
                <tr key={index}>
                  <td className="bg-white px-6 py-4 text-center">
                    {item.pr_id}
                  </td>
                  <td className="bg-white px-6 py-4 text-center">
                    {item.qoutation_id}
                  </td>
                  <td className="bg-white px-6 py-4 text-center">
                    {item.supplier_id}
                  </td>
                  <td className="bg-white px-6 py-4 text-center">
                    {item.supplier_name}
                  </td>
                  <td className="bg-white px-6 py-4 text-center">
                    {item.net_total}
                  </td>
                  <td className="bg-white px-6 py-4 text-center">
                    {item.decision_file_path ? (
                      <div className="flex items-center justify-center bg-[#E7F1FF] rounded-lg p-2">
                        <a
                          href={`${REACT_APP_API_ENDPOINT}/v1/supplyChain/tender_evaluation_criteria/get-desition-doc-by-name/${item.decision_file_path}`}
                          download={item.decision_file_path}
                          className="flex items-center cursor-pointer"
                        >
                          <FiFile className="text-gray-600 mr-2" />
                          <span>
                            {item.decision_file_path.split("/").pop()}
                          </span>
                        </a>
                      </div>
                    ) : (
                      <span>No decision document</span>
                    )}
                  </td>
                  <td className="bg-white px-6 py-4 text-center">
                    {item.qoutation_document_path ? (
                      <div className="flex items-center justify-center bg-[#E7F1FF] rounded-lg p-2">
                        <a
                          href={`${REACT_APP_API_ENDPOINT}/v1/supplyChain/openingcommittee/files/${item.qoutation_document_path}`}
                          download={item.qoutation_document_path}
                          className="flex items-center cursor-pointer"
                        >
                          <FiFile className="text-gray-600 mr-2" />
                          <span>
                            {item.qoutation_document_path.split("/").pop()}
                          </span>
                        </a>
                      </div>
                    ) : (
                      <span>No Quotation document</span>
                    )}
                  </td>
                  <td className="bg-white px-6 py-4 text-center">
                    <select
                      className="border border-gray-300 rounded-lg px-2 py-2 w-full text-xl"
                      value={item.status || ""}
                      onChange={(e) =>
                        handleStatusChange(index, e.target.value)
                      }
                    >
                      <option value="" className="text-gray-400">
                        Approval Person
                      </option>

                      {/* Show options based on item.net_total */}
                      {item.net_total > 500000 && (
                        <option value="TENDER">Tender</option>
                      )}

                      {item.net_total <= 500000 && item.net_total > 300000 && (
                        <>
                          <option value="TENDER">Tender</option>
                          <option value="CHAIRMAN">Chairman</option>
                        </>
                      )}

                      {item.net_total <= 300000 && item.net_total > 0 && (
                        <>
                          <option value="TENDER">Tender</option>
                          <option value="CHAIRMAN">Chairman</option>
                          <option value="GM">General Manager</option>
                        </>
                      )}
                    </select>
                  </td>
                  {/* Conditionally render the Select Committee cell */}
                  {item.status === "TENDER" && (
                    <td className="bg-white px-6 py-4 text-center">
                      <select
                        value={item.tenderCom || ""}
                        onChange={(e) => handleTenderCom(index, e.target.value)}
                        className="border border-gray-300 rounded-lg px-2 py-2 w-full text-xl"
                      >
                        <option>Select the Committee</option>
                        {items && items.length > 0 ? (
                          items.map((committeeItem, idx) => (
                            <option
                              key={idx}
                              value={committeeItem.committee_id}
                            >
                              {committeeItem.committee_id} -{" "}
                              {committeeItem.committee_title}
                            </option>
                          ))
                        ) : (
                          <option value="">No approval tender committee</option>
                        )}
                      </select>
                    </td>
                  )}
                  <td className="bg-white px-6 py-4 text-center">
                    <button
                      className="bg-newBackground hover:bg-[#495e33] p-2 text-white font-sans items-center rounded-md w-[150px]"
                      onClick={() => openDialog(item)}
                    >
                      Send For Approvals
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7" className="bg-white px-6 py-4 text-center">
                  No pending purchase request
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="flex justify-center mt-4">
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
          className="bg-primary text-white px-4 py-2 mx-1 rounded-md"
        >
          <GrPrevious className="w-3 h-3" />
        </button>
        {[...Array(Math.ceil(filteredEmployees.length / itemsPerPage))].map(
          (page, index) => (
            <button
              key={index}
              onClick={() => paginate(index + 1)}
              className={`${
                currentPage === index + 1 ? "bg-gray-300" : "bg-white"
              } text-primary px-4 py-2 mx-1 rounded-md`}
            >
              {index + 1}
            </button>
          )
        )}
        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={
            currentPage === Math.ceil(filteredEmployees.length / itemsPerPage)
          }
          className="bg-primary text-white px-4 py-2 mx-1 rounded-md"
        >
          <GrNext className="w-3 h-3" />
        </button>
      </div>
    </div>
  );
};

export default Decisions_Supplier_Pending;
