 

import React, { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import IdGenerate from "../../../utils/id_generate";
import axios from "axios";
import usePermissions from "../../../components/permissions/permission";

export default function CreditClearance() {
  const { hasPermission } = usePermissions();

  const currentDate = new Date(); // Get the current date and time
  const formattedDate = currentDate.toISOString(); // Format the date to ISO string
  const [userId, setUserId] = useState("USER-000000");

  const [paymentId, setPaymentId] = useState(IdGenerate("invoice"));

  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [limit, setLimit] = useState(0);
  const [available, setAvalilable] = useState(0);
  const [payable, setPayable] = useState(0);
  const [payment, setPayment] = useState(0);

  const [customers, setCustomers] = useState([]);
  const CustomerSearchHandler = async (inputValue) => {
    console.log(inputValue);
    // setCustomerName(inputValue);
    if (inputValue !== "") {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/customer/search/name/contact/status/${inputValue}/ACTIVE`
        );
        console.log(res.data);
        const options = res.data.map((customer) => ({
          value: customer.customer_id,
          label: customer.customer_name,
        }));
        setCustomers(options);
        return options;
      } catch (error) {
        console.error(error);
        return [];
      }
    }
  };
  const CustomerSelectHandler = async (customer) => {
    console.log(customer);
    GetCustomer(customer.value);
  };
  const GetCustomer = async (customerId) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/customer/get/${customerId}`
      );
      console.log(res.data);
      setId(res.data[0].customer_id);
      setName(res.data[0].customer_name);
      setContact(res.data[0].customer_contact_no);
      setLimit(res.data[0].credit_limit);
      setAvalilable(res.data[0].available_credit_limit);
      setPayable(res.data[0].credit_limit - res.data[0].available_credit_limit);
    } catch (error) {}
    
  };

  const customStyles = {
    control: (baseStyles, state) => ({
      // class attribute : class=" css-i32vvf-control"
      ...baseStyles,
      background: "white",
      borderRadius: "100px",
      borderColor: state.isFocused ? "grey" : "red",
      border: state.isFocused ? "none" : "none",
    }),
    valueContainer: (provided) => ({
      // class attribute : class=" css-i32vvf-valueContainer"
      ...provided,
      background: "transparent",
    }),
    input: (provided) => ({
      // class attribute : class=" css-i32vvf-input"
      ...provided,
      background: "transparent",
      placeholder: "Search",
    }),
    singleValue: (provided) => ({
      // class attribute : class=" css-i32vvf-singleValue"
      ...provided,
      background: "transparent",
    }),
    indicatorSeparator: (provided) => ({
      // class attribute : class=" css-i32vvf-indicatorSeparator"
      ...provided,
      background: "transparent",

      borderRadius: "100px",
    }),
    dropdownIndicator: (provided) => ({
      // class attribute : class=" css-i32vvf-dropdownIndicator"
      ...provided,
      background: "transparent",
    }),

    menu: (provided) => ({
      // 'menu' is from the div class too.
      ...provided,
      background: "white",
    }),
    Option: (provided) => ({
      ...provided,
      background: "red",
    }),
  };

  const SubmitHandler = async () => {
    const data = {
      customerId: id,
      payment: payment,
      paymentId: paymentId,
      paymentDate: formattedDate,
      paymentUser: userId,
    };
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/customer/credit/pay`,
        data
      );
      console.log(res.data);
      alert("Payment Added Successfully");
      setId("");
      setName("");
      setContact("");
      setLimit(0);
      setAvalilable(0);
      setPayable(0);
    } catch (error) {
      console.error(error);
      alert("Payment Added Failed");
    }
  };
  return (
    <div className="p-10 bg-background">
      <p className="header-1">Credit Clearance</p>

      <div className="flex items-center gap-10 my-7">
        <p className="label-1">Customer</p>
        <p className="label-1">:</p>
        {hasPermission(1570) && (
          <AsyncSelect
            className="w-80"
            styles={customStyles}
            cacheOptions
            loadOptions={CustomerSearchHandler}
            // defaultOptions
            onChange={CustomerSelectHandler}
            value={customers}
          />
        )}
      </div>

      <div className="w-full flex items-center justify-center">
        <div className="w-full py-16 px-20 bg-[#F3F8FF] rounded-[46px] flex flex-col items-center gap-y-10  my-10 mx-[130px]">
          <p className="header-1 mt-[-20px]">Enter Credit Clearance Details</p>
          <div className="flex items-center justify-between w-full">
            <p className="label-1">Customer Name : </p>
            <p className="w-[255px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold bg-[#FFFFFF]  flex items-center">
              {name}
            </p>
          </div>
          <div className="flex items-center justify-between w-full">
            <p className="label-1">Customer Contact No. :</p>
            <p className="w-[255px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold bg-[#FFFFFF] flex items-center">
              {contact}
            </p>
          </div>
          <div className="flex items-center justify-between w-full">
            <p className="label-1">Credit Limit :</p>
            <p className="w-[255px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold bg-[#FFFFFF] flex items-center">
              {limit}
            </p>
          </div>
          <div className="flex items-center justify-between w-full">
            <p className="label-1">Available Credit Limit :</p>
            <p className="w-[255px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold bg-[#FFFFFF] flex items-center">
              {available}
            </p>
          </div>
          <div className="flex items-center justify-between w-full">
            <p className="label-1">Credit Payable :</p>
            <p className="w-[255px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold bg-[#FFFFFF] flex items-center">
              {payable}
            </p>
          </div>
          <div className="flex items-center justify-between w-full">
            <p className="label-1">Payment :</p>
            <input
              type="number"
              className="w-[255px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold"
              value={payment}
              onChange={(e) => setPayment(e.target.value)}
              max={payable}
            />
          </div>
        </div>
      </div>

      <div className="flex w-full items-center justify-end gap-x-10 my-10">
        <button className="w-[118px] h-[45px] flex items-center justify-center bg-[#AA4343] border rounded-[23px] px-4 text-white font-bold text-xl">
          Cancel
        </button>
        {hasPermission(1580) && (
          <button
            className="w-[118px] h-[45px] flex items-center justify-center bg-newBackground border rounded-[23px] px-4 text-white font-bold text-xl"
            onClick={SubmitHandler}
          >
            Pay
          </button>
        )}
      </div>
    </div>
  );
}
