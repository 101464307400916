 

import React, { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import "./StoreKeeperRelease.css";
import Arrow from "../../../../Assets/icons/down-arrow.png";
import axios from "axios";

import IdGenerate from "../../../../utils/id_generate";
import usePermissions from "../../../../components/permissions/permission";

export default function StoreKeeperRelease() {
  const { hasPermission } = usePermissions();
  const currentDate = new Date(); // Get the current date and time
  const formattedDate = currentDate.toISOString(); // Format the date to ISO string
  const [userId, setUserId] = useState("USER-000000");

  const [releaseInvoiceId, setReleaseInvoiceId] = useState(
    IdGenerate("RELEASE")
  );

  const [dropDownShow, setDropDownShow] = useState(false);

  const [invoice, setInvoice] = useState([]);
  const DropDown2SearchHandler = async (e) => {
    console.log(e);

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/inventoryRequest/search/request/${e}/PENDING`
      );
      console.log(res.data);
      setInvoice(res.data);
      const options = res.data.map((item) => ({
        value: item.inventory_request_id,
        label: item.inventory_request_id, // Customize label as needed
      }));
      return options;
    } catch (error) {}
  };

  const [invoiceData, setInvoiceData] = useState({
    invoice_id: "res.data[0].invoice_id",
    request_user: "res.data[0].inventory_request_user",
    request_type: "res.data[0].inventory_request_type",
    request_items: [],
  });
  const DropDown2SelectHandler = async (invoice_id) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/inventoryRequest/request/${invoice_id.value}`
      );
      console.log(res.data);
      setInvoiceData({
        invoice_id: res.data[0].inventory_request_id,
        inventory_request_date: res.data[0].inventory_request_date,
        request_items: res.data.map((item) => {
          //here should handle array with length
          return {
            inventory_item_id: item.inventory_batch_id,
            item_name: item.item_name,
            item_current_qty: item.current_qty,
            item_requested_qty: item.inventory_request_item_qty,
            item_location: item.location,
            item_measure_unit: item.item_measure_unit,
            item_description: "",
          };
        }),
      });
    } catch (error) {}
  };
  useEffect(() => {
    let inventoryItems = invoiceData.request_items;
    if (inventoryItems.length > 0) {
    }
  }, [invoiceData.request_items.length]);

  const ReleaseHandler = async () => {
    const data = {
      release_invoice_id: releaseInvoiceId,
      request_invoice_id: invoiceData.invoice_id,
      release_user: userId,
      release_date: formattedDate,
      release_items: invoiceData.request_items,
    };
    console.log(data);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/inventoryRelease/add`,
        data
      );
      console.log(res.data);
      if (res.status === 200 || res.status === 201) {
        alert("Release Success");
      }
    } catch (error) {
      if (error.response.status === 409) {
        alert("Already Exist");
      } else if (error.response.status === 400) {
        alert("Not Added");
      } else if (error.response.status === 500) {
        alert("Internal Server Error");
      } else if (error.response.status === 404) {
        alert(" Not Found");
      } else if (error.response.status === 403) {
        alert("Forbidden");
      } else if (error.response.status === 401) {
        alert("Unauthorized");
      }
    }
  };

  const customStyles = {
    control: (baseStyles, state) => ({
      // class attribute : class=" css-i32vvf-control"
      ...baseStyles,
      background: "white",
      borderRadius: "100px",
      //   borderColor: state.isFocused ? 'grey' : 'red',
      //   border:state.isFocused ? 'none' : 'none',
      //   borderColor:'black'
    }),
    valueContainer: (provided) => ({
      // class attribute : class=" css-i32vvf-valueContainer"
      ...provided,
      background: "transparent",
    }),
    input: (provided) => ({
      // class attribute : class=" css-i32vvf-input"
      ...provided,
      background: "transparent",
      placeholder: "Search",
    }),
    singleValue: (provided) => ({
      // class attribute : class=" css-i32vvf-singleValue"
      ...provided,
      background: "transparent",
    }),
    indicatorSeparator: (provided) => ({
      // class attribute : class=" css-i32vvf-indicatorSeparator"
      ...provided,
      background: "transparent",

      borderRadius: "100px",
    }),
    dropdownIndicator: (provided) => ({
      // class attribute : class=" css-i32vvf-dropdownIndicator"
      ...provided,
      background: "transparent",
    }),

    menu: (provided) => ({
      // 'menu' is from the div class too.
      ...provided,
      background: "white",
    }),
    Option: (provided) => ({
      ...provided,
      background: "red",
    }),
  };

  return (
    <div className="p-10 bg-background">
      <p className="header-1">Inventory Release</p>
      {/* <div className='w-full flex justify-start gap-x-10 my-10'>
            <p className='label-1'>Release Id :</p>
            <input className='input-1 border border-[#797979]'/>
        </div> */}
      <div className="w-full flex justify-start items-center mt-10 ">
        <div className="w-[670px] h-[82px] flex justify-between items-center gap-x-10 bg-[#F3F8FF] px-10 rounded-[46px] border border-[#00000036]">
          <p className="label-1">Request Id :</p>
          <AsyncSelect
            className="w-80"
            styles={customStyles}
            loadOptions={DropDown2SearchHandler}
            onChange={DropDown2SelectHandler}
            value={invoice.inventory_request_id}
          />
        </div>
      </div>
      <p className="header-1 mt-10">Details</p>

      <div className="flex flex-col justify-start  bg-[#F3F8FF] rounded-[46px] border border-[#00000036] mt-10">
        <div className="w-full h-[86px] flex items-center justify-around bg-[#BFD8AE] rounded-[46px] mr-5">
          <p className="w-10 p1 text-[#656565] text-center">#</p>
          <div className="w-[350px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">Item Name</p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[200px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">Location</p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[200px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">
              Available Qty
            </p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[200px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">
              Requested Qty
            </p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[200px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">Remarks</p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
        </div>
        <div className="flex flex-col gap-y-5 my-3 h-[250px] overflow-y-scroll">
          {invoiceData.request_items.length > 0
            ? invoiceData.request_items.map((item, index) => (
                <div key={index} className="w-full  flex justify-around ">
                  <div className="w-10 flex items-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {index + 1}
                    </p>
                  </div>
                  <div className="w-[350px] flex  items-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {item.item_name}
                    </p>
                  </div>
                  <div className="w-[200px] flex items-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {item.item_location}
                    </p>
                  </div>
                  <div className="w-[200px] flex  items-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {item.item_current_qty}
                    </p>
                  </div>
                  <div className="w-[200px] flex  items-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {item.item_requested_qty}
                    </p>
                  </div>
                  <div className="w-[200px] flex  items-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      <textarea
                        className="w-full border border-[#797979] rounded-[40px] h-[60px]"
                        type="text"
                        value={item.item_description}
                        onChange={(e) => {
                          let newReleaseItems = { ...invoiceData };
                          newReleaseItems.request_items[
                            index
                          ].item_description = e.target.value;
                          setInvoiceData(newReleaseItems);
                        }}
                      />
                    </p>
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>

      <div className="w-full flex justify-end items-center gap-x-10 my-10">
        {hasPermission(1231) && (
          <button className="w-[118px] h-[45px] flex items-center justify-center bg-newBackground border rounded-[23px] px-4">
            <p className="p2 text-white" onClick={ReleaseHandler}>
              Release
            </p>
          </button>
        )}
        <button className="w-[118px] h-[45px] flex items-center justify-center bg-[#912020] border rounded-[23px] px-4">
          <p className="p2 text-white">Cancel</p>
        </button>
      </div>
    </div>
  );
}
