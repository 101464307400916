import React, { useState, useEffect } from "react";
import { jsPDF } from "jspdf";
import { IoIosCloseCircleOutline } from "react-icons/io";

const CheckPDFPreview = ({ row,viewCheckPopup, isCrossCheque }) => {
  console.log(row)
  const [pdfSrc, setPdfSrc] = useState(null); // For storing PDF Blob URL


  // Create and preview the PDF when the component mounts
  useEffect(() => {
    handleCheckView();
  }, []);

  const handleCheckView = () => {
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "pt",
      format: [595, 250], // Check size: 595pt x 250pt (landscape check)
    });
    const formattedDate = new Date(row.cheque_date).toLocaleDateString();
  
    // Set up fonts and styles
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
  
    // Date (top-left corner)
    doc.text(formattedDate, 30, 40);
  
    // Payee name (top-left)
    doc.text(row.payee_name, 30, 80);
  
    // Check number (top-right)
    doc.text("1 0 1 0 2 4", 500, 40);
  
    // Payee name again (middle-left)
    doc.text(row.payee_name, 180, 80);
  
    // Payment details and amount in words
    doc.setFontSize(10);
    doc.text(row.payment_id, 30, 120); // PAY id
    doc.setFontSize(12);
    doc.text(`**** ${row.amount_in_words}`, 180, 120);
    doc.text("ONLY", 180, 135,  ); // Aligned 'ONLY'
  
    // Amount in numbers (right side, aligned)
    doc.text(parseFloat(row.amount).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }), 500, 120);
  
    // Amount repeated at bottom-left
    doc.setFontSize(12);
    doc.text(parseFloat(row.amount).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }), 30, 200);
  
    // Company name (bottom-right)
    doc.setFontSize(11)
    doc.text("Corporation name", 350, 170);

    // Conditionally add "A/C PAYEE ONLY" section
    if (isCrossCheque) {
      doc.setFontSize(10);
      doc.line(150, 165, 280, 165); // Line under A/C PAYEE ONLY
      doc.text("A/C PAYEE ONLY", 160, 180);
      doc.line(150, 185, 280, 185); // Line under A/C PAYEE ONLY

      // "30 days only" section
      doc.line(150, 200, 280, 200); // Line above "30 days only"
      doc.text("30 days only", 160, 215);
      doc.line(150, 220, 280, 220); // Line below "30 days only"
    }
  
    // Authorized Signature (bottom-right)
    doc.setFontSize(8);
    doc.text(
      ".......................................................................................",
      350, 210
    );
    doc.text("Authorised Signatory(1) / Authorised Signatory(2)", 350, 220);
  
    // Save or display the PDF
    const pdfBlob = doc.output("blob");
    const pdfUrl = URL.createObjectURL(pdfBlob);
    setPdfSrc(pdfUrl); // Assuming you use this to display the PDF
  };
  
  
  const printPDF = () => {
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "in",
      format: [6, 2.75], // Standard check size
    });

    // Recreate the PDF with the same content for printing
    handleCheckView();

    // Open the PDF in a new window for printing
    doc.output('dataurlnewwindow');
  };


  return (
    <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
      <div className="w-[80%] h-[60%] overflow-y-auto p-8 bg-white rounded-lg">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-2xl font-semibold">View Cheque</h2>
          <button
            type="button"
            onClick={viewCheckPopup}
            className="p-4 text-4xl text-gray-500 transition duration-300 hover:text-gray-700"
          >
            <IoIosCloseCircleOutline />
          </button>
        </div>

        {/* PDF Preview */}
        {pdfSrc && (
          <div className="mb-6">
            <iframe 
              src={pdfSrc} 
              width="100%" 
              height="500px" 
              title="Check PDF Preview" 
            />
          </div>
        )}

        {/* Print Button */}
        {/* <div className="flex justify-end">
          <button
            onClick={printPDF}
            className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
          >
            Print Check PDF
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default CheckPDFPreview;