import React, { useState } from "react";
import WarehouseTable from "./WarehouseTable";
import WarehouseStageData from "../warehouse/WarehouseStageData";

function WarehouseMangement() {
  const [selectedProductionId, setSelectedProductionId] = useState(null);
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [selectedJobName, setSelectedJobName] = useState(null);
  

  const handleRowClick = (productionId, jobId, jobname) => {
    setSelectedProductionId(productionId);
    setSelectedJobId(jobId);
    setSelectedJobName(jobname);
  };

  return (
    <div>
      <p className="text-[32px] ml-6 mt-5 bg-newBackground2 w-[100%] rounded-xl">
        Warehouse
      </p>
      <div className="grid grid-flow-row-dense grid-cols-5 h-screen overflow-y-auto">
        <div className="col-span-2">
          <WarehouseTable setSelectedProductionId={handleRowClick} />
        </div>
        <div className="col-span-3 overflow-y">
          <WarehouseStageData
            productionId={selectedProductionId}
            jobId={selectedJobId}
            jobname={selectedJobName}
          />
        </div>
      </div>
    </div>
  );
}

export default WarehouseMangement;
