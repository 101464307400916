/** @format */

import React, { useState, useEffect } from "react";
import folder from "../../../Assets/icons/folder.png";
import { RiDeleteBin3Fill } from "react-icons/ri";
import { IoReturnUpBack } from "react-icons/io5";
import { IoMdCloseCircle } from "react-icons/io";
import AsyncSelect from "react-select/async";
import Cookies from "js-cookie";

function ConfirmationDialog({ message, onConfirm, onCancel }) {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
      <div className="bg-white p-4 rounded-md shadow-lg">
        <p className="mb-4">{message}</p>
        <div className="flex justify-end">
          <button
            onClick={onConfirm}
            className="bg-red-600 text-white px-4 py-2 rounded-md mr-2"
          >
            Yes
          </button>
          <button
            onClick={onCancel}
            className="bg-gray-300 px-4 py-2 rounded-md"
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
}

function StartStage({ onBackToTable, stageId }) {
  const [stageData, setStageData] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [employee, setEmployee] = useState("");
  const [file, setFile] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [involeemployeeData, setInvoleemployeeData] = useState([]);
  console.log("involeemployeeData00", involeemployeeData);
  const [deleteIndexEmployee, setDeleteIndex] = useState(null);
  const [deleteIndexforfile, setDeleteIndexforfile] = useState(null);
  const [selectedOption, setSelectedOption] = useState("");
  const [showApproveConfirmation, setShowApproveConfirmation] = useState(false);
  const [showRejectConfirmation, setShowRejectConfirmation] = useState(false);
  const [rejectReason, setRejectReason] = useState(""); // State to store rejection reason
  const { REACT_APP_API_ENDPOINT } = process.env;

  const [hours, setHours] = useState(0); // Initialize with default value if needed
  const [minutes, setMinutes] = useState(0);
  const userId = Cookies.get("employee_no");

  const handleStatusChange = (e) => {
    setSelectedOption(e.target.value);
    if (e.target.value === "Approve") {
      setShowApproveConfirmation(true);
    } else if (e.target.value === "Reject") {
      setShowRejectConfirmation(true);
    }
  };
  // Function to handle input change for reject reason
  const handleRejectReasonChange = (event) => {
    setRejectReason(event.target.value);
  };

  const handleApproveConfirmation = () => {
    setShowApproveConfirmation(true);
  };

  const handleRejectConfirmation = () => {
    setShowRejectConfirmation(true);
  };

  const handleApprove = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/production/stage/stageApprove?_id=${stageId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            stage_status: "COMPLETE",
            stage_reject_reason: "",
          }),
        }
      );
      if (response.ok) {
        console.log("Stage approved successfully");
        // Do additional actions after approval if needed
      } else {
        console.error("Failed to approve stage");
      }
    } catch (error) {
      console.error("Error approving stage:", error);
    } finally {
      setShowApproveConfirmation(false);
    }
  };

  const handleReject = async () => {
    // Show input field for reject reason
    if (rejectReason !== null) {
      try {
        const response = await fetch(
          `${REACT_APP_API_ENDPOINT}/v1/production/stage/stageApprove?_id=${stageId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              stage_status: "REJECT",
              stage_reject_reason: rejectReason,
            }),
          }
        );
        if (response.ok) {
          console.log("Stage rejected successfully");
          // Do additional actions after rejection if needed
        } else {
          console.error("Failed to reject stage");
        }
      } catch (error) {
        console.error("Error rejecting stage:", error);
      } finally {
        setShowRejectConfirmation(false);
      }
    } else {
      // User canceled reject operation
      setShowRejectConfirmation(false);
    }
  };

  const handleCancelApprove = () => {
    setShowApproveConfirmation(false);
  };

  const handleCancelReject = () => {
    setShowRejectConfirmation(false);
  };
  // Function to handle input change for reject reason

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${REACT_APP_API_ENDPOINT}/v1/production/stagemenu/getstagemenu?stage_id=${stageId}`
        );
        const data = await response.json();
        setStageData(data.stageData);
        setInvoleemployeeData(data.involeemployeeData);
        console.log("involeemployeeData", data);
      } catch (error) {
        console.error("Error fetching stage data:", error);
      }
    };

    // Fetch data initially
    fetchData();

    // Fetch data every 10 seconds (adjust the interval as needed)
    const intervalId = setInterval(fetchData, 10000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, [stageId]);

  // Function to format date and time
  function formatDateTime(dateTimeString) {
    if (!dateTimeString) {
      return ""; // Return empty string if dateTimeString is empty or null
    }

    const dateTime = new Date(dateTimeString);

    // Options for date and time formatting
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };

    // Format the date and time
    const formattedDateTime = dateTime.toLocaleDateString("en-US", options);
    return formattedDateTime;
  }
  const handleStart = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/production/stage/starttime?_id=${stageId}`,
        {
          method: "PUT",
        }
      );
      console.log("Stage start time updated successfully", response);
      // After successfully starting, fetch updated data
      const updatedResponse = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/production/stagemenu/getstagemenu?stage_id=${stageId}`
      );
      const updatedData = await updatedResponse.json();
      setStageData(updatedData.stageData);
    } catch (error) {
      console.error("Error starting stage:", error);
    }
  };

  const handleStop = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/production/stage/stoptime?_id=${stageId}`,
        {
          method: "PUT",
        }
      );
      console.log("Stage Stop time updated successfully", response);
      // After successfully stopping, fetch updated data
      const updatedResponse = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/production/stagemenu/getstagemenu?stage_id=${stageId}`
      );
      const updatedData = await updatedResponse.json();
      setStageData(updatedData.stageData);
    } catch (error) {
      console.error("Error stopping stage:", error);
    }
  };
  // Check if the buttons should be disabled
  const isStartButtonDisabled =
    stageData.length === 0 || stageData[0].stage_start_at;
  const isStopButtonDisabled =
    stageData.length === 0 ||
    !stageData[0].stage_start_at ||
    stageData[0].stage_end_at;

  //remove file function
  const handleRemoveFile = async (_id) => {
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/production/stagemenu/filedelete?reportId=${_id}`,
        {
          method: "DELETE",
        }
      );
      if (response.ok) {
        setUploadedFiles((prevFiles) =>
          prevFiles.filter((file) => file._id !== _id)
        );
        console.log("File deleted successfully");
        setDeleteIndexforfile(null);
      } else {
        console.error("Failed to delete file");
      }
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  };

  // Function to delete an employee
  const handleDeleteEmployee = async (_id) => {
    try {
      // Send DELETE request to delete the employee
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/production/stagemenu/deleteemployee?_id=${_id}`,
        {
          method: "DELETE",
        }
      );
      if (response.ok) {
        setInvoleemployeeData((prevData) =>
          prevData.filter((emp) => emp._id !== _id)
        );
        // If the request is successful, update the UI to remove the deleted employee
        console.log("Employee deleted successfully");
        // Close the popup by setting deleteIndexEmployee to null
        setDeleteIndex(null);
      } else {
        console.error("Failed to delete employee");
      }
    } catch (error) {
      console.error("Error deleting employee:", error);
    }
  };

  const handleAssignEmployee = async () => {
    // Make a POST request to assign employees to the stage
    const requestBody = selectedEmployees.map((selectedEmployee) => ({
      stage_employee_stage_id: stageId,
      stage_employee_employee_id: selectedEmployee.value,
      stage_employee_create_by: userId,
    }));

    try {
      // Make the POST request
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/production/stagemenu/addemployee`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to assign employees");
      }
      setSelectedEmployees([]);

      // Fetch updated data
      const updatedResponse = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/production/stagemenu/getstagemenu?stage_id=${stageId}`
      );
      const updatedData = await updatedResponse.json();
      setInvoleemployeeData(updatedData.involeemployeeData);

      // Log the response data
      console.log("Assign employees response:", updatedData);
    } catch (error) {
      console.error("Error assigning employees:", error);
    }
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleAddEmployee = () => {
    console.log("employee", employee);
    if (
      employee &&
      !selectedEmployees.some((emp) => emp.value === employee.value)
    ) {
      setSelectedEmployees((prev) => [...prev, employee]);
      setEmployee(null); // Reset selection after adding
    }
  };

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const response = await fetch(
          `${REACT_APP_API_ENDPOINT}/v1/production/stagemenu/getfiledetails?stageId=${stageId}`
        );
        if (response.ok) {
          const data = await response.json();
          setUploadedFiles(data);
        } else {
          console.error("Failed to fetch files:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching files:", error);
      }
    };

    fetchFiles();
  }, []);

  const handleUploadFile = async () => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("qa_report_upload_employee_id", "EMP-0003"); // Replace employeeId with the appropriate value
      formData.append("qa_report_stage_id", stageId); // Replace stageId with the appropriate value

      try {
        // Make the POST request to upload the file
        const uploadResponse = await fetch(
          `${REACT_APP_API_ENDPOINT}/v1/production/stagemenu/filesave`,
          {
            method: "POST",
            body: formData,
          }
        );

        if (uploadResponse.ok) {
          // If upload is successful, fetch updated files
          const filesResponse = await fetch(
            `${REACT_APP_API_ENDPOINT}/v1/production/stagemenu/getfiledetails?stageId=${stageId}`
          );

          if (filesResponse.ok) {
            const filesData = await filesResponse.json();
            setUploadedFiles(filesData);
            console.log("File uploaded successfully");
            setShowSuccessMessage(true);
            setTimeout(() => {
              setShowSuccessMessage(false);
            }, 2000);
            setFile(null);
          } else {
            // Handle error in fetching files
            console.error("Failed to fetch files:", filesResponse.statusText);
          }
        } else {
          // Handle error in uploading file
          console.error("Failed to upload file");
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    } else {
      // Handle case where no file is selected
      console.error("No file selected");
    }
  };

  // Function to handle downloading the file
  const handleDownload = async (filePath) => {
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/production/stagemenu/getFile?file_path=${filePath}`
      );
      if (response.ok) {
        // Convert the response to a blob
        const blob = await response.blob();
        // Create a temporary URL for the blob
        const url = URL.createObjectURL(blob);
        // Create an anchor element
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filePath.split("/").pop());
        // Append the anchor element to the body
        document.body.appendChild(link);
        // Click the anchor element to trigger the download
        link.click();
        // Remove the anchor element from the body
        document.body.removeChild(link);
      } else {
        console.error("Failed to download file");
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handleEmployeeSearch = async (inputValue) => {
    if (!inputValue) return []; // Return empty array if input is empty

    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_API_ENDPOINT
        }/v1/hris/employees/get-by-name?employee_fullname=${encodeURIComponent(
          inputValue
        )}`
      );

      if (!response.ok) {
        throw new Error(`Error fetching data: ${response.statusText}`);
      }

      const data = await response.json();
      console.log("Fetched Employees:", data);

      // Transform data into select options
      return data.map((employee) => ({
        value: employee.employee_no,
        label: `${employee.employee_no} - ${employee.employee_name_initial}`,
      }));
    } catch (error) {
      console.error("Error:", error);
      return [];
    }
  };

  // Handle selection change
  const handleDoctorChange = (selectedOption) => {
    setEmployee(selectedOption);
    console.log("selectedOption", selectedOption);
  };

  const handleAddMachineHours = async () => {
    try {
      // Format hours and minutes into "HH:MM" string
      const machineHours = `${String(hours).padStart(2, "0")}:${String(
        minutes
      ).padStart(2, "0")}`;

      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/v1/production/stage/set-machine-hours-stage?_id=${stageId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json", // Ensure the request is JSON
          },
          body: JSON.stringify({ machine_hours: machineHours }), // Send the request body
        }
      );

      console.log("Machine hours updated successfully", response);
      window.alert("Machine hours updated successfully");
      // After successfully stopping, fetch updated data
      const updatedResponse = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/production/stagemenu/getstagemenu?stage_id=${stageId}`
      );
      const updatedData = await updatedResponse.json();
      setStageData(updatedData.stageData);
    } catch (error) {
      console.error("Error updating machine hours:", error);
    }
  };

  const convertHoursToHM = (decimalHours) => {
    const hours = Math.floor(decimalHours); // Get the whole hours part
    const minutes = Math.round((decimalHours - hours) * 60); // Get the remaining minutes
    return `${hours}h ${minutes}m`;
  };

  return (
    <div>
      <div className="h-screen overflow-y-auto overflow-x-hidden relative">
        <button
          onClick={onBackToTable}
          className="fixed right-8 bottom-8 z-10 p-2 bg-newBackground rounded-lg"
        >
          <IoReturnUpBack size={30} />
        </button>

        <div className="bg-white p-2 rounded-md mb-8 w-[97%] overflow-y-auto h-screen">
          {" "}
          <div class="separator"></div>
          {stageData.length > 0 && (
            <>
              <div className="grid grid-flow-row-dense grid-cols-5 overflow-y-auto">
                <div className="col-span-2">
                  <h2 className="text-2xl font-semibold mb-2 rounded-lg ">
                    {stageData[0].stage_name}
                  </h2>
                  <label className="font-semibold mb-2">
                    Stage ID : {stageId}
                  </label>
                  <h3
                    className="mt-2 font-semibold mb-2"
                    style={{
                      color:
                        stageData[0].stage_status === "COMPLETE"
                          ? "green"
                          : stageData[0].stage_status === "REJECT"
                          ? "red"
                          : "inherit",
                    }}
                  >
                    Status: {stageData[0].stage_status}
                    {stageData[0].stage_status === "REJECT" && (
                      <span>, Reason: {stageData[0].stage_reject_reason}</span>
                    )}
                  </h3>
                  <div className="text-sm font-semibold mb-1 mt-5 text-[#1e0e4b]">
                    Estimate Time: <p>{stageData[0].stage_estimate_time} </p>
                  </div>
                  <div className="text-sm font-semibold mb-4 mt-5 text-[#1e0e4b]">
                    Supervisor Note: <p>{stageData[0].stage_supervisor_note}</p>
                  </div>
                  <div className="mt-6 mb-6 flex items-center">
                    <div className="font-semibold">Select Status</div>
                    <div>
                      <select
                        className="w-full ml-4 py-1 px-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-200 font-bold"
                        value={selectedOption}
                        onChange={handleStatusChange}
                      >
                        <option value="">Select an option</option>
                        <option value="Approve">Complete</option>
                        <option value="Reject">Reject</option>
                      </select>
                    </div>
                  </div>

                  {/* Conditionally display machine hours */}
                  <div className="text-sm font-semibold mb-4 mt-5 text-[#1e0e4b]">
                    Machine Hours:
                  </div>
                  {stageData[0].machine_hours ? (
                    <p>{convertHoursToHM(stageData[0].machine_hours)}</p>
                  ) : (
                    <div className="flex flex-row w-full items-center">
                      <div className="w-1/2 mr-2">
                        <label className="block font-medium mb-1">Hours</label>
                        <input
                          type="number"
                          value={hours}
                          onChange={(e) => setHours(e.target.value)}
                          className="w-full border border-gray-300 rounded-md p-2"
                          min="0"
                          placeholder="HH"
                        />
                      </div>
                      <div className="w-1/2 ml-2">
                        <label className="block font-medium mb-1">
                          Minutes
                        </label>
                        <input
                          type="number"
                          value={minutes}
                          onChange={(e) => setMinutes(e.target.value)}
                          className="w-full border border-gray-300 rounded-md p-2"
                          min="0"
                          max="59"
                          placeholder="MM"
                        />
                      </div>
                      <button
                        onClick={handleAddMachineHours}
                        className="bg-newBackground text-white h-[30px] w-[70px] rounded-md ml-5"
                      >
                        Add
                      </button>
                    </div>
                  )}

                  {showApproveConfirmation && (
                    <ConfirmationDialog
                      message="Are you sure you want to complete?"
                      onConfirm={handleApprove}
                      onCancel={() => setShowApproveConfirmation(false)}
                    />
                  )}
                  {showRejectConfirmation && (
                    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
                      <div className="bg-white p-4 rounded-md shadow-lg">
                        <p className="mb-4">Are you sure you want to Reject?</p>
                        <input
                          type="text"
                          value={rejectReason}
                          onChange={handleRejectReasonChange}
                          placeholder="Enter reject reason"
                          className="border border-gray-300 rounded-md px-2 py-1 mb-2 w-full"
                        />
                        <div className="flex justify-end">
                          <button
                            onClick={handleReject}
                            className="bg-red-600 text-white px-4 py-2 rounded-md mr-2"
                          >
                            Yes
                          </button>
                          <button
                            onClick={() => setShowRejectConfirmation(false)}
                            className="bg-gray-300 px-4 py-2 rounded-md"
                          >
                            No
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="col-span-3">
                  <div className=" justify-end ml-5">
                    {stageData.length > 0 && (
                      <>
                        <div className="justify-end mt-5">
                          <button
                            className="bg-newBackground text-white px-4 py-2 rounded-md mr-2"
                            onClick={handleStart}
                            disabled={isStartButtonDisabled}
                          >
                            {isStartButtonDisabled ? "Started" : "Start"}
                          </button>
                          <label className="text-[14px] ml-2">
                            {formatDateTime(stageData[0].stage_start_at)}
                          </label>
                        </div>
                        <div className="justify-end mt-5">
                          <button
                            className="bg-gray-300 px-4 py-2 rounded-md"
                            onClick={handleStop}
                            disabled={isStopButtonDisabled}
                          >
                            {isStopButtonDisabled ? "Ended" : "End"}
                          </button>
                          <label className="text-[14px] ml-2">
                            {formatDateTime(stageData[0].stage_end_at)}
                          </label>
                        </div>
                      </>
                    )}
                  </div>
                  {/*  */}
                </div>
              </div>
            </>
          )}{" "}
          <div className="justify-center flex">
            <hr class="line border-t-2 border-gray-300 w-[80%]"></hr>
          </div>
          <br />
          <div className="grid grid-flow-row-dense grid-cols-5 overflow-y-auto">
            <div className="col-span-3">
              <div className="overflow-y-auto max-h-96 mb-4">
                <label className="block mb-2 font-semibold">
                  Add Employee to particular stage
                </label>
                <div className="flex flex-row items-center">
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    loadOptions={handleEmployeeSearch}
                    value={employee}
                    onChange={handleDoctorChange}
                    placeholder="Search and select an Employee"
                    className="w-full"
                  />
                  <button
                    onClick={handleAddEmployee}
                    className="bg-newBackground text-white h-[30px] w-[70px] rounded-md mr-2 ml-5"
                  >
                    Add
                  </button>
                </div>
                <div>
                  <div>
                    {selectedEmployees.length > 0 && (
                      <>
                        <label className="block mb-2 font-semibold">
                          Selected Employees
                        </label>
                        <div>
                          {selectedEmployees.map((selectedEmployee, index) => (
                            <div
                              key={index}
                              className="flex items-center mt-2 text-[12px] bg-newBackground2 justify-between rounded-lg w-[200px] p-1 "
                            >
                              <div>{selectedEmployee.label} </div>{" "}
                              <IoMdCloseCircle
                                onClick={() =>
                                  setSelectedEmployees((prevEmployees) =>
                                    prevEmployees.filter((_, i) => i !== index)
                                  )
                                }
                                className="text-black cursor-pointer ml-2 h-3 w-3"
                                size={20}
                              />
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <button
                  onClick={handleAssignEmployee}
                  className="bg-newBackground text-white px-4 py-2 rounded-md mr-2 mt-5"
                >
                  Assign Employee
                </button>
              </div>
            </div>
            <div className="col-span-2">
              <div>
                <label className="block mb-2 font-semibold">
                  Assigned Employees
                </label>
                <div className="w-full max-w-md rounded-lg p-6">
                  <div>
                    {involeemployeeData.map((employeeData, index) => (
                      <div
                        key={employeeData._id}
                        className="flex items-center mt-2 text-[12px] bg-newBackground2 justify-between rounded-lg w-[250px] p-1 "
                      >
                        <div>
                          {employeeData.stage_employee_employee_id} -{" "}
                          {employeeData.employee_name_initial}
                        </div>{" "}
                        <IoMdCloseCircle
                          onClick={() => setDeleteIndex(index)}
                          className="text-black cursor-pointer ml-2 h-3 w-3"
                          size={20}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {deleteIndexEmployee !== null && (
            <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
              <div className="bg-white p-4 rounded-md shadow-lg">
                <p className="mb-4">
                  Are you sure you want to remove this Employee?
                </p>
                <div className="flex justify-end">
                  <button
                    onClick={() =>
                      handleDeleteEmployee(
                        involeemployeeData[deleteIndexEmployee]._id
                      )
                    }
                    className="bg-red-600 text-white px-4 py-2 rounded-md mr-2"
                  >
                    Delete
                  </button>
                  <button
                    onClick={() => setDeleteIndex(null)} // Reset deleteIndexEmployee
                    className="bg-gray-300 px-4 py-2 rounded-md"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}
          <div className="flex justify-center">
            {" "}
            <hr class="line border-t-2 border-gray-300 w-[90%] mt-15"></hr>
          </div>
          <div className="grid grid-flow-row-dense grid-cols-5 overflow-y-auto">
            <div className="col-span-3">
              <label className="block mb-2 font-semibold">
                Upload QA Reports
              </label>
              <div className="bg-white p-2 rounded-md w-[97%] flex flex-row items-center">
                <div className="overflow-y-auto max-h-96 mb-4 flex ">
                  <label className="block mb-2">Upload File:</label>
                  <div>
                    <div>
                      <input
                        id="file-input"
                        type="file"
                        accept=".pdf, .jpg, .jpeg, .png"
                        className="border border-gray-300 rounded-md px-2 w-[150px]"
                        onChange={handleFileChange}
                      />
                    </div>
                  </div>
                  {showSuccessMessage && (
                    <div className="text-red-600 text-sm mt-2">
                      File submitted successfully!
                    </div>
                  )}
                  <button
                    className="bg-newBackground text-white h-[30px] w-[70px] rounded-md mr-2 ml-5"
                    onClick={handleUploadFile}
                  >
                    Submit
                  </button>
                </div>
              </div>
              {file && (
                <div className="flex  flex-row ml-2">
                  <img src={folder} alt="Folder Icon" className="w-5 h-5" />
                  <p className="ml-2 text-sm">{file.name}</p>
                </div>
              )}
            </div>
            <div className="col-span-2">
              <label className="block mb-2 font-semibold">
                Uploaded QA Reports
              </label>

              <div className="overflow-y-auto max-h-96 mb-4">
                {uploadedFiles.map((file, index) => (
                  <div
                    key={file._id}
                    className="flex items-center justify-between border-b border-gray-300 py-2"
                  >
                    <div
                      className="flex flex-row ml-2"
                      onClick={() => handleDownload(file.qa_report_path)}
                    >
                      <img
                        src={folder}
                        alt="Folder Icon"
                        className="w-5 h-5 cursor-pointer"
                      />
                      <p className="ml-2 text-[10px]">{file.qa_report_name}</p>
                    </div>
                    <RiDeleteBin3Fill
                      onClick={() => setDeleteIndexforfile(index)}
                      className="text-red-600 cursor-pointer mr-3 flex justify-between"
                      size={15}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {deleteIndexforfile !== null && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
            <div className="bg-white p-4 rounded-md shadow-lg">
              <p className="mb-4">Are you sure you want to delete this file?</p>
              <div className="flex justify-end">
                <button
                  onClick={() =>
                    handleRemoveFile(uploadedFiles[deleteIndexforfile]._id)
                  }
                  className="bg-red-600 text-white px-4 py-2 rounded-md mr-2"
                >
                  Delete
                </button>

                <button
                  onClick={() => setDeleteIndexforfile(null)}
                  className="bg-gray-300 px-4 py-2 rounded-md"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default StartStage;
