/** @format */

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";

export default function CreateUser() {
  const [selectedUserType, setSelectedUserType] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [employees, setEmployees] = useState([]);
  const [roles, setRoles] = useState([]);
  const [branches, setBranches] = useState([]);
  const [customers, setCustomers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch employees, roles, branches, customers for dropdowns
    async function fetchData() {
      try {
        const employeeResponse = await axios.get(
          `https://back-ayurveda.erp-quortech.com/v1/hris/employees/getemployeebasicdetails`
        );
        setEmployees(employeeResponse.data);

        const rolesResponse = await axios.get(
          `https://back-ayurveda.erp-quortech.com/v1/hris/user/getRoles`
        );
        setRoles(rolesResponse.data);

        const branchesResponse = await axios.get(
          `https://back-ayurveda.erp-quortech.com/v1/inventory/branch/all/Branch/ACTIVE`
        );
        setBranches(branchesResponse.data);

        const customersResponse = await axios.get(
          `https://back-ayurveda.erp-quortech.com/v1/inventory/customer`
        );
        setCustomers(customersResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let url = "";
      let data = {};

      if (selectedUserType === "POS") {
        url =
          "https://back-ayurveda.erp-quortech.com/v1/hris/user/createUserWithBranch";
        data = {
          employee_id: selectedEmployee,
          username: username,
          password: password,
          role: selectedRole,
          branch_id: selectedBranch,
        };
        console.log("pos", data);
      } else if (
        selectedUserType === "DOCTOR" ||
        selectedUserType === "AGENT"
      ) {
        url =
          "https://back-ayurveda.erp-quortech.com/v1/hris/user/createMobileUser";
        data = {
          username: username,
          password: password,
          type: selectedUserType,
          id: selectedEmployee,
        };
      } else {
        console.error("Invalid user type selected");
        return;
      }

      const response = await axios.post(url, data);

      if (response.status === 201 || response.status === 200) {
        alert("User created successfully");
        handleReset();
      } else {
        alert("Failed to create user");
      }
    } catch (error) {
      console.error("Error creating user:", error);
      alert("Error creating user");
    }
  };

  const handleReset = () => {
    setSelectedUserType("");
    setSelectedEmployee("");
    setUsername("");
    setPassword("");
    setSelectedRole("");
    setSelectedBranch("");
  };

  const HandleCancel = () => {
    navigate(-1);
  };

  return (
    <div className="p-10 bg-background h-screen">
      <div className="bg-[#F3F8FF] rounded-[46px] border border-[#00000036] px-[200px] py-11 my-10 flex flex-col items-center justify-center">
        <div>
          <p className="font-sans text-[#797C80] text-[32px] ml-6 mt-6 font-bold">
            Create POS User
          </p>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-2 grid-flow-row gap-x-4 mt-8 gap-y-6">
            <div>
              <div>
                <label className="font-semibold text-[20px]">User Type*</label>
              </div>
              <div>
                <select
                  className="rounded-[20px] h-[40px] w-[300px] p-2 border border-black"
                  value={selectedUserType}
                  onChange={(e) => setSelectedUserType(e.target.value)}
                >
                  <option value="" disabled>
                    Select User Type
                  </option>
                  <option value="DOCTOR">DOCTOR</option>
                  <option value="AGENT">AGENT</option>
                  <option value="POS">POS</option>
                </select>
              </div>
            </div>
            {(selectedUserType === "AGENT" || selectedUserType === "POS") && (
              <div>
                <div>
                  <label className="font-semibold">Employee ID*</label>
                </div>
                <div>
                  <select
                    className="rounded-[20px] h-[40px] w-[300px] p-2 border border-black"
                    value={selectedEmployee}
                    onChange={(e) => setSelectedEmployee(e.target.value)}
                  >
                    <option value="" disabled>
                      Select Employee ID
                    </option>
                    {employees.map((employee) => (
                      <option
                        key={employee.employee_no}
                        value={employee.employee_no}
                      >
                        {employee.employee_no} -{" "}
                        {employee.employee_name_initial}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}
            {selectedUserType === "DOCTOR" && (
              <div>
                <div>
                  <label className="font-semibold">Customer ID*</label>
                </div>
                <div>
                  <select
                    className="rounded-[20px] h-[40px] w-[300px] p-2 border border-black"
                    value={selectedEmployee}
                    onChange={(e) => setSelectedEmployee(e.target.value)}
                  >
                    <option value="" disabled>
                      Select Customer ID
                    </option>
                    {customers.map((customer) => (
                      <option
                        key={customer.customer_id}
                        value={customer.customer_id}
                      >
                        {customer.customer_id} - {customer.customer_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}
            <div className="mr-10">
              <div>
                <label className="font-semibold text-[20px]">User Name*</label>
              </div>
              <div>
                <input
                  className="rounded-[20px] h-[40px] w-[300px] p-2 border border-black"
                  placeholder="User Name"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
            </div>
            <div>
              <div>
                <label className="font-semibold text-[20px]">Password*</label>
              </div>
              <div>
                <input
                  className="rounded-[20px] h-[40px] w-[300px] p-2 border border-black"
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
            {selectedUserType === "POS" && (
              <>
                <div>
                  <div>
                    <label className="font-semibold">Role*</label>
                  </div>
                  <div>
                    <select
                      className="rounded-[20px] h-[40px] w-[300px] p-2 border border-black"
                      value={selectedRole}
                      onChange={(e) => setSelectedRole(e.target.value)}
                    >
                      <option value="" disabled>
                        Select Role
                      </option>
                      {roles.map((role) => (
                        <option key={role.id} value={role.id}>
                          {role.role_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div>
                  <div>
                    <label className="font-semibold">Branch*</label>
                  </div>
                  <div>
                    <select
                      className="rounded-[20px] h-[40px] w-[300px] p-2 border border-black"
                      value={selectedBranch}
                      onChange={(e) => setSelectedBranch(e.target.value)}
                    >
                      <option value="" disabled>
                        Select Branch
                      </option>
                      {branches.map((branch) => (
                        <option key={branch.branch_id} value={branch.branch_id}>
                          {branch.branch_id} - {branch.branch_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="flex w-full justify-center gap-x-10 my-10 ml-30">
            <button
              type="button"
              className="w-[118px] h-[45px] flex items-center justify-center bg-[#AA4343] border rounded-[23px] px-4 text-white font-bold text-xl"
              onClick={HandleCancel}
            >
              Cancel
            </button>

            <button
              type="submit"
              className="w-[118px] h-[45px] flex items-center justify-center bg-newBackground border rounded-[23px] px-4 text-white font-bold text-xl"
            >
              Create
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
