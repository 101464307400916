import React, { useState } from "react";
import "./updateUser.css";
import Arrow from "./../../../Assets/icons/down-arrow.png";
import axios from "axios";

export default function UpdateUser() {
  const [resultShow, setResultShow] = useState(false);

  const [users, setUsers] = useState([]);
  const SearchUser = async (e) => {
    if (e.target.value !== "") {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/user/search/name/status/${e.target.value}/ACTIVE`
        );
        console.log(res.data);
        setUsers(res.data);
      } catch (error) {}
    }
  };

  return (
    <div className="p-10 bg-background h-screen overflow-y-auto">
      <p className="header-1">Update User</p>

      <div className="flex flex-col font-poppins font-bold ml-6 mt-4">
        <div className="relative font-sans group hidden sm:block">
          <label className="label-1">Find user</label>
          <label className=" px-10 label-1">:</label>
          <input
            className="input-1 border border-[#00000036]"
            type="text"
            placeholder="Search by EMP NO, EPF NO, NIC"
            //value={searchQuery}
            //onChange={handleSearchChange}
          />
        </div>
      </div>

      <div className="w-full flex items-center justify-center">
        <div className="bg-[#F3F8FF] rounded-[46px] border border-[#00000036] px-[200px] py-11 my-10 flex flex-col items-center justify-center">
          <p className="header-1 text-center">Update User details</p>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">User Id</label>
            <label className="label-1">:</label>
            <input className="input-1 border border-[#00000036]" disabled />
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">User Name</label>
            <label className="label-1">:</label>
            <input className="input-1 border border-[#00000036]" />
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">User Password</label>
            <label className="label-1">:</label>
            <input className="input-1 border border-[#00000036]" />
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">User Branch</label>
            <label className="label-1">:</label>
            <select className="input-1 border border-[#00000036]">
              <option>select branch</option>
            </select>
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">User Status</label>
            <label className="label-1">:</label>
            <select className="input-1 border border-[#00000036]">
              <option>select status</option>
            </select>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-end gap-x-6 mb-10 px-[280px]">
        <button className="w-[118px] h-[45px] flex items-center justify-center bg-newBackground border rounded-[23px] px-4">
          <p className="text-white">Update</p>
        </button>
        <button className="w-[118px] h-[45px] flex items-center justify-center bg-[#ab2626] border rounded-[23px] px-4">
          <p className="text-white">CANCEL</p>
        </button>
        <button className="w-[118px] h-[45px] flex items-center justify-center border rounded-[23px] border-black solid-black px-4">
          <p className="text-black">EDIT</p>
        </button>
      </div>
    </div>
  );
}
