import React, { useState, useEffect } from "react";
import folder from "../../../Assets/icons/folder.png";
import { RiDeleteBin3Fill } from "react-icons/ri";
import { IoReturnUpBack } from "react-icons/io5";
import { IoMdCloseCircle } from "react-icons/io";
import { MdOutlineFileDownload } from "react-icons/md";
import { IoCheckmarkDoneCircle } from "react-icons/io5";

function WarehouseStages({ onBackToTable, stageId }) {
  const [stageData, setStageData] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [file, setFile] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [involeemployeeData, setInvoleemployeeData] = useState([]);
  const [deleteIndexEmployee, setDeleteIndex] = useState(null);
  const [deleteIndexforfile, setDeleteIndexforfile] = useState(null);
  const { REACT_APP_API_ENDPOINT } = process.env;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${REACT_APP_API_ENDPOINT}/v1/production/stagemenu/getstagemenu?stage_id=${stageId}`
        );
        const data = await response.json();
        setStageData(data.stageData);
        setInvoleemployeeData(data.involeemployeeData);
      } catch (error) {
        console.error("Error fetching stage data:", error);
      }
    };

    fetchData();
  }, [stageId]);

  useEffect(() => {
    fetch(`${REACT_APP_API_ENDPOINT}/v1/production/employee/getyemployee-list`)
      .then((response) => response.json())
      .then((data) => {
        setEmployeeList(data);
      })
      .catch((error) => console.error("Error fetching employee list:", error));
  }, []);

  const handleRemoveFile = async (_id) => {
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/production/stagemenu/filedelete?reportId=${_id}`,
        {
          method: "DELETE",
        }
      );
      if (response.ok) {
        setUploadedFiles((prevFiles) =>
          prevFiles.filter((file) => file._id !== _id)
        );
        console.log("File deleted successfully");
        setDeleteIndex(null);
      } else {
        console.error("Failed to delete file");
      }
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  };

  // Function to delete an employee
  const handleDeleteEmployee = async (_id) => {
    try {
      // Send DELETE request to delete the employee
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/production/stagemenu/deleteemployee?_id=${_id}`,
        {
          method: "DELETE",
        }
      );
      if (response.ok) {
        // If the request is successful, update the UI to remove the deleted employee
        setInvoleemployeeData((prevData) =>
          prevData.filter((employee) => employee._id !== _id)
        );
        console.log("Employee deleted successfully");
      } else {
        console.error("Failed to delete employee");
      }
    } catch (error) {
      console.error("Error deleting employee:", error);
    }
  };

  const handleAssignEmployee = async () => {
    // Make a POST request to assign employees to the stage
    const requestBody = selectedEmployees.map((selectedEmployee) => ({
      stage_employee_stage_id: stageId,
      stage_employee_employee_id: selectedEmployee.employee_id,
      stage_employee_create_by: "EMP-005",
    }));

    try {
      // Make the POST request
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/production/stagemenu/addemployee`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to assign employees");
      }

      // Fetch updated data
      const updatedResponse = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/production/stagemenu/getstagemenu?stage_id=${stageId}`
      );
      const updatedData = await updatedResponse.json();
      setInvoleemployeeData(updatedData.involeemployeeData);

      // Log the response data
      console.log("Assign employees response:", updatedData);
    } catch (error) {
      console.error("Error assigning employees:", error);
    }
  };

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const response = await fetch(
          `${REACT_APP_API_ENDPOINT}/v1/production/stagemenu/getfiledetails?stageId=${stageId}`
        );
        if (response.ok) {
          const data = await response.json();
          setUploadedFiles(data);
        } else {
          console.error("Failed to fetch files:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching files:", error);
      }
    };

    fetchFiles();
  }, []);

  const handleUploadFile = async () => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("qa_report_upload_employee_id", "EMP-0003"); // Replace employeeId with the appropriate value
      formData.append("qa_report_stage_id", stageId); // Replace stageId with the appropriate value

      try {
        // Make the POST request to upload the file
        const uploadResponse = await fetch(
          `${REACT_APP_API_ENDPOINT}/v1/production/stagemenu/filesave`,
          {
            method: "POST",
            body: formData,
          }
        );

        if (uploadResponse.ok) {
          // If upload is successful, fetch updated files
          const filesResponse = await fetch(
            `${REACT_APP_API_ENDPOINT}/v1/production/stagemenu/getfiledetails?stageId=${stageId}`
          );

          if (filesResponse.ok) {
            const filesData = await filesResponse.json();
            setUploadedFiles(filesData);
            console.log("File uploaded successfully");
            setShowSuccessMessage(true);
            setTimeout(() => {
              setShowSuccessMessage(false);
            }, 2000);
          } else {
            // Handle error in fetching files
            console.error("Failed to fetch files:", filesResponse.statusText);
          }
        } else {
          // Handle error in uploading file
          console.error("Failed to upload file");
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    } else {
      // Handle case where no file is selected
      console.error("No file selected");
    }
  };

  // Function to handle downloading the file
  const handleDownload = async (filePath) => {
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/production/stagemenu/getFile?file_path=${filePath}`
      );
      if (response.ok) {
        // Convert the response to a blob
        const blob = await response.blob();
        // Create a temporary URL for the blob
        const url = URL.createObjectURL(blob);
        // Create an anchor element
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filePath.split("/").pop());
        // Append the anchor element to the body
        document.body.appendChild(link);
        // Click the anchor element to trigger the download
        link.click();
        // Remove the anchor element from the body
        document.body.removeChild(link);
      } else {
        console.error("Failed to download file");
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <div>
      <div className="h-[550px] overflow-y-hidden overflow-x-hidden relative">
        <button
          onClick={onBackToTable}
          className="fixed right-8 bottom-8 z-10 p-2 bg-newBackground rounded-lg"
        >
          <IoReturnUpBack size={30} />
        </button>

        <div className="bg-white p-2 rounded-md mb-8 w-[97%] h-screen">
          {" "}
          <div class="separator"></div>
          {stageData.length > 0 && (
            <>
              {" "}
              <div className="flex  ">
                <h2 className="text-2xl font-semibold mb-4 rounded-lg ">
                  {stageData[0].stage_name} <label>ID :</label> {stageId}
                </h2>
              </div>
              <div className="text-sm font-normal mb-1 text-[#1e0e4b]">
                Estimate Time: {stageData[0].stage_estimate_time}
              </div>
              <div className="text-sm font-normal mb-4 text-[#1e0e4b]">
                Supervisor Note: {stageData[0].stage_supervisor_note}
              </div>
              <div className="text-sm font-normal mb-4 text-[#1e0e4b]">
                {/* _id : {involeemployeeData[0]._id} */}
              </div>
            </>
          )}{" "}
          <hr class="line border-t-2 border-gray-300 w-[100%]"></hr>
          <br />
          <div className="grid grid-flow-row-dense grid-cols-5 overflow-y-auto">
            <div className="col-span-2">
              <div>
                <label className="block font-semibold">
                  Assigned Employees
                </label>
                <div className="w-full max-w-md rounded-lg p-3">
                  <div>
                    {involeemployeeData.map((employeeData, index) => (
                      <div
                        key={employeeData._id}
                        className="flex items-center mt-2 text-[10px] bg-newBackground2 justify-between rounded-lg h-[30px] w-[100px] p-1 "
                      >
                        <div className="ml-2">
                          {employeeData.stage_employee_employee_id}{" "}
                        </div>{" "}
                        <IoCheckmarkDoneCircle
                          className="text-black ml-2 h-3 w-3"
                          size={10}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-3">
              <label className="block mb-2 font-semibold">
                Uploaded QA Reports
              </label>

              <div className="overflow-y-auto max-h-96 mb-4">
                {uploadedFiles.map((file, index) => (
                  <div
                    key={file._id}
                    className="flex items-center justify-between border-b border-gray-300 py-2"
                  >
                    <div
                      className="flex flex-row ml-2"
                      onClick={() => handleDownload(file.qa_report_path)}
                    >
                      <img
                        src={folder}
                        alt="Folder Icon"
                        className="w-5 h-5 cursor-pointer"
                      />
                      <p className="ml-2 text-[10px]">{file.qa_report_name}</p>
                    </div>
                    <RiDeleteBin3Fill
                      onClick={() => setDeleteIndexforfile(index)}
                      className="text-red-600 cursor-pointer mr-3 flex justify-between"
                      size={15}
                    />
                  </div>
                ))}
              </div>
              {deleteIndexforfile !== null && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
                  <div className="bg-white p-4 rounded-md shadow-lg">
                    <p className="mb-4">
                      Are you sure you want to delete this file?
                    </p>
                    <div className="flex justify-end">
                      <button
                        onClick={() =>
                          handleRemoveFile(
                            uploadedFiles[deleteIndexforfile]._id
                          )
                        }
                        className="bg-red-600 text-white px-4 py-2 rounded-md mr-2"
                      >
                        Delete
                      </button>

                      <button
                        onClick={() => setDeleteIndexforfile(null)}
                        className="bg-gray-300 px-4 py-2 rounded-md"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {deleteIndexEmployee !== null && (
            <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
              <div className="bg-white p-4 rounded-md shadow-lg">
                <p className="mb-4">
                  Are you sure you want to remove this Employee?
                </p>
                <div className="flex justify-end">
                  <button
                    onClick={() =>
                      handleDeleteEmployee(
                        involeemployeeData[deleteIndexEmployee]._id
                      )
                    }
                    className="bg-red-600 text-white px-4 py-2 rounded-md mr-2"
                  >
                    Delete
                  </button>
                  <button
                    onClick={() => setDeleteIndex(null)} // Reset deleteIndexEmployee
                    className="bg-gray-300 px-4 py-2 rounded-md"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default WarehouseStages;
