/** @format */

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TableSupplier from "./supplierTable";
import axios from "axios";
import Cookies from "js-cookie";
import IdGenerate from "../../../../utils/id_generate";
import usePermissions from "../../../../components/permissions/permission";
import { IoMdCloseCircle } from "react-icons/io";

const Supply_Edit = () => {
  const { hasPermission } = usePermissions();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [table, setTable] = useState([]);
  const [supplierNumber, setSupplierNumber] = useState(IdGenerate("SUP"));
  const [supplierName, setSupplierName] = useState("");
  const [address, setAddress] = useState("");
  const [nic, setNIC] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [faxNo, setFaxNo] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [vatNo, setVatNo] = useState("");
  const [tinNo, setTinNo] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [date, setDate] = useState(new Date());
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [itemCategoryData, setItemCategoryData] = useState([]);
  const [subItem, setSubItem] = useState("");
  const [selectedSubItems, setSelectedSubItems] = useState([]);
  const [subItemList, setSubItemList] = useState([]);
  const userId = Cookies.get("employee_no");
  const [supllierCategory, setSupCategory] = useState("");

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/inventory/itemCategory/get-couple-category`
      );
      const data = await response.json();

      // Filter the data to only include items with item_category_name = "RAW"
      const filteredData = data.filter(
        (item) => item.item_category_name === "RAW"
      );

      setItemCategoryData(filteredData);
      console.log("Filtered Items:", filteredData);
    } catch (error) {
      console.error("Error fetching Item Category data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Add sub-item when button is clicked
  const handleAddSubItem = () => {
    if (subItem) {
      // Find the selected item by its sub_category_id
      const selectedSubItem = itemCategoryData.find(
        (item) => item.sub_category_id === subItem
      );

      // Ensure the item exists and hasn't been added already
      if (
        selectedSubItem &&
        !selectedSubItems.some((item) => item.sub_category_id === subItem)
      ) {
        // Add the selected item to the selectedSubItems array
        setSelectedSubItems((prevSelectedSubItems) => [
          ...prevSelectedSubItems,
          {
            sub_category_id: subItem,
            label: `${selectedSubItem.item_category_name} - ${selectedSubItem.item_sub_category_name}`,
          },
        ]);
        setSubItem(""); // Reset the select input
      }
    }
  };

  // Handle select change for the sub-item
  const handleSubItemChange = (event) => {
    setSubItem(event.target.value);
  };

  // Remove sub-item when the delete icon is clicked
  const handleDeleteSubItem = (index) => {
    setSelectedSubItems((prevSelectedSubItems) =>
      prevSelectedSubItems.filter((_, i) => i !== index)
    );
  };

  const tableDate = async () => {
    try {
      const tdata = await axios.get(
        `${REACT_APP_API_ENDPOINT}/v1/supplyChain/supplier/status/ACTIVE`
      );
      setTable(tdata.data.length > 0 ? tdata.data : []);
    } catch (error) {
      console.error("Error fetching data:", error);
      setTable([]);
    }
  };

  const postFormData = async () => {
    // Extract only the sub_category_id from selectedSubItems
    const supplier_category_ids = selectedSubItems.map(
      (item) => item.sub_category_id
    );

    const body = {
      supplier_name: supplierName,
      supplier_type: supllierCategory,
      supplier_address: address,
      supplier_nic: nic,
      supplier_contact_no: phoneNo,
      supplier_fax: faxNo,
      supplier_email: emailAddress,
      supplier_vat_no: vatNo,
      TIN: tinNo,
      supplier_update_date: date.toLocaleDateString("en-GB"),
      supplier_update_user_id: userId,
      supplier_category_ids, // Now only includes sub_category_id list
    };

    console.log("send body", body);
    try {
      const response = await axios.post(
        `${REACT_APP_API_ENDPOINT}/v1/supplyChain/supplier/add`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response);
      alert("Supplier successfully added");
      reset();
      tableDate();
    } catch (error) {
      console.error("Error:", error);

      // Check if the error has a response and a message
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        // Display the specific error message from the response
        alert(error.response.data.message);
      } else {
        // Default error message if the specific one is not available
        alert("Failed to add Supplier. Please try again.");
      }
    }
  };

  const searchSuppliers = async (query) => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_ENDPOINT}/v1/supplyChain/supplier/search/${query}`
      );
      setSearchResults(response.data.length > 0 ? response.data : []);
    } catch (error) {
      console.error("Error fetching search results:", error);
      setSearchResults([]);
    }
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    if (e.target.value.trim() !== "") {
      searchSuppliers(e.target.value.trim());
    } else {
      setSearchResults([]);
    }
  };

  const reset = () => {
    setSupplierNumber(IdGenerate("SUP"));
    setSupplierName("");
    setAddress("");
    setPhoneNo("");
    setFaxNo("");
    setEmailAddress("");
    setVatNo("");
    setSelectedCategory("");
    setDate(new Date());
    setSearch("");
    setSearchResults([]);
  };

  return (
    <div className="bg-background overflow-y-auto h-screen">
      <p className="text-[32px] font-sans ml-6 mt-6 font-bold text-[#071C50] ">
        Suppliers
      </p>
      <p className="text-[32px] font-sans ml-24 mt-4 font-bold text-[#071C50] ">
        Add Suppliers
      </p>

      <div className="flex justify-between mt-10 mx-6">
        <div className="bg-white rounded-[32px] p-4 shadow-md flex flex-col justify-between card min-w-[25%] ml-[65px] w-[90%] h-[20%]">
          <div className="min-h-[110px] min-w-[150px] ">
            <h2 className="text-[26px] text-[#797C80] font-semibold relative">
              Enter Supplier Details
            </h2>
          </div>
          <div className="grid grid-cols-2 gap-6 ">
            <div className="flex items-center">
              <label className="mr-[10%] text-black font-poppins font-bold text-xl">
                Supplier Name:
              </label>
              <input
                type="text"
                placeholder="Enter Supplier Name"
                value={supplierName}
                onChange={(e) => setSupplierName(e.target.value)}
                className="border border-gray-300 rounded-lg px-2 py-2 w-full text-xl font-bold"
              />
            </div>
            <div className="flex items-center">
              <label className="mr-[10%] text-black font-poppins font-bold text-xl">
                Supplier Category:
              </label>
              <select
                className="border border-gray-300 rounded-lg px-2 py-2 w-full text-xl font-bold"
                value={supllierCategory}
                onChange={(e) => setSupCategory(e.target.value)}
              >
                <option value="">Supplier Category Type</option>
                <option value="REGISTERED SUPPLIER">Registered Supplier</option>
                <option value="OPEN SUPPLIER">Open Supplier</option>
              </select>
            </div>
            <div className="flex items-center">
              <label className="mr-[10%] text-black font-poppins font-bold text-xl">
                Address:
              </label>
              <input
                type="text"
                placeholder="Enter Address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                className="border border-gray-300 rounded-lg px-2 py-2 w-full text-xl font-bold"
              />
            </div>
            <div className="flex items-center">
              <label className="mr-[10%] text-black font-poppins font-bold text-xl">
                NIC:
              </label>
              <input
                type="text"
                placeholder="Enter NIC"
                value={nic}
                onChange={(e) => setNIC(e.target.value)}
                className="border border-gray-300 rounded-lg px-2 py-2 w-full text-xl font-bold"
              />
            </div>
            <div className="flex items-center">
              <label className="mr-[10%] text-black font-poppins font-bold text-xl">
                Phone Number:
              </label>
              <input
                type="text"
                placeholder="Enter Phone No"
                value={phoneNo}
                onChange={(e) => setPhoneNo(e.target.value)}
                className="border border-gray-300 rounded-lg px-2 py-2 w-full text-xl font-bold"
              />
            </div>
            <div className="flex items-center">
              <label className="mr-[10%] text-black font-poppins font-bold text-xl">
                FAX No:
              </label>
              <input
                type="text"
                placeholder="Enter FAX No"
                value={faxNo}
                onChange={(e) => setFaxNo(e.target.value)}
                className="border border-gray-300 rounded-lg px-2 py-2 w-full text-xl font-bold"
              />
            </div>
            <div className="flex items-center">
              <label className="mr-[10%] text-black font-poppins font-bold text-xl">
                Email Address:
              </label>
              <input
                type="text"
                placeholder="Enter Email Address"
                value={emailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
                className="border border-gray-300 rounded-lg px-2 py-2 w-full text-xl font-bold"
              />
            </div>
            <div className="flex items-center">
              <label className="mr-[10%] text-black font-poppins font-bold text-xl">
                VAT No:
              </label>
              <input
                type="text"
                placeholder="Enter VAT No"
                value={vatNo}
                onChange={(e) => setVatNo(e.target.value)}
                className="border border-gray-300 rounded-lg px-2 py-2 w-full text-xl font-bold"
              />
            </div>
            <div className="flex items-center">
              <label className="mr-[10%] text-black font-poppins font-bold text-xl">
                TIN No:
              </label>
              <input
                type="text"
                placeholder="Enter TIN No"
                value={tinNo}
                onChange={(e) => setTinNo(e.target.value)}
                className="border border-gray-300 rounded-lg px-2 py-2 w-full text-xl font-bold"
              />
            </div>
            <div className="flex items-center">
              <label className="mr-[10%] text-black font-poppins font-bold text-xl">
                Category:
              </label>
              <select
                className="border border-gray-300 rounded-lg px-2 py-2 w-full text-xl font-bold"
                value={subItem}
                onChange={handleSubItemChange}
              >
                <option value="">Item Category Type</option>
                {itemCategoryData.map((items, index) => (
                  <option key={index} value={items.sub_category_id}>
                    {items.item_category_name} - {items.item_sub_category_name}
                  </option>
                ))}
              </select>
              <button
                onClick={handleAddSubItem}
                className="bg-newBackground text-white h-[30px] w-[70px] rounded-md ml-5"
              >
                Add
              </button>
            </div>
          </div>
          <div className="w-[600px] flex flex-wrap ml-44 gap-2 mt-4">
            {selectedSubItems.map((item, index) => (
              <div
                key={index}
                className="flex items-center justify-between text-[12px] bg-newBackground2 rounded-lg p-2 w-[70%]"
              >
                <div>{item.label}</div>{" "}
                {/* Show the item_category_name - item_sub_category_name */}
                <IoMdCloseCircle
                  onClick={() => handleDeleteSubItem(index)}
                  className="text-black cursor-pointer ml-2"
                  size={20}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="flex justify-end mr-6 mt-6">
        <button
          className="text-lime-500 bg-transparent border border-neutral-800 rounded-[22px] font-bold min-w-20 mr-2"
          onClick={reset}
        >
          Reset
        </button>
        {hasPermission(2700) && (
          <button
            className=" text-white bg-primary rounded-[22px] font-bold min-w-20"
            onClick={postFormData}
          >
            Add
          </button>
        )}
      </div>
      <div className="flex flex-col font-poppins font-bold mt-10 ml-[90px]">
        <form className="form relative">
          <button className="absolute left-2 -translate-y-1/2 top-1/2 p-1">
            <svg
              className="w-5 h-5 text-gray-700"
              aria-labelledby="search"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              height="16"
              width="17"
            >
              <path
                stroke-linejoin="round"
                stroke-linecap="round"
                stroke-width="1.333"
                stroke="currentColor"
                d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9"
              ></path>
            </svg>
          </button>
          <input
            type="text"
            required=""
            placeholder="Search..."
            value={search}
            onChange={handleSearchChange}
            className="input rounded-full w-[300px] h-10 px-8 py-3 border-2 border-transparent focus:outline-none focus:border-blue-500 placeholder-gray-400"
          />
          <button
            className="btn btn-primary text-[#797C80] hover:bg-gray-100"
            onClick={reset}
          >
            RESET
          </button>
        </form>
        <TableSupplier
          data={searchResults.length > 0 ? searchResults : table}
          category={data}
          tableDate={() => tableDate()}
        />
      </div>
    </div>
  );
};

export default Supply_Edit;
