/** @format */

import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import AsyncSelect from "react-select/async";
import Arrow from "./../../../../Assets/icons/down-arrow.png";
import cloudiccon from "../../../../Assets/icons/cloud-add.png";
import Cookies from "js-cookie";

const Update_Tender = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const selectedRecord = location.state?.selectedRecord || {};
  const [conId, setconId] = useState(selectedRecord.contract_id || "");
  const [tName, settName] = useState(selectedRecord.tender_name || "");
  const [tCategory, settCategory] = useState(selectedRecord.category || "");
  const [DeadName, setDeadName] = useState(selectedRecord.deadline_name || "");
  const [startDate, setStartDate] = useState(
    selectedRecord.deadline_date
      ? new Date(selectedRecord.deadline_date)
      : new Date()
  );
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [selectedItem, setSelectedItem] = useState(null);
  const [itemArrow, setItemArrow] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [files, setFiles] = useState(
    selectedRecord.tender_file
      ? [{ name: selectedRecord.tender_file, size: null }]
      : []
  );
  const [adjustedQty, setAdjustedQty] = useState([]);
  const userId = Cookies.get("employee_no");
  const currentDate = new Date(); // Get the current date and time
  const formattedDate = currentDate.toISOString().split("T")[0];

  // Effect to set files when selectedRecord.tender_file changes
  useEffect(() => {
    if (selectedRecord.tender_file) {
      setFiles([{ name: selectedRecord.tender_file, size: null }]);
    } else {
      setFiles([]);
    }
  }, [selectedRecord.tender_file]);

  // Define a function to fetch Procument item data
  const fetchProItemData = async () => {
    try {
      // Extract item IDs from procurement_items
      const itemIds = selectedRecord.procurement_items.map(
        (item) => item.item_id
      );
      console.log("item", selectedRecord.procurement_items);

      // Fetch data for each item ID
      const fetchPromises = itemIds.map((itemId) =>
        fetch(`${REACT_APP_API_ENDPOINT}/v1/inventory/item/item/${itemId}`)
      );

      // Await all fetches
      const responses = await Promise.all(fetchPromises);

      // Parse all responses
      const dataPromises = responses.map((response) => response.json());
      const dataArray = await Promise.all(dataPromises);
      console.log("data Array", dataArray);

      // Prepare table data based on fetched data
      const newTableData = dataArray.map((itemData, index) => {
        const procurementItem = selectedRecord.procurement_items[index];
        return {
          item_id: itemData.item_id,
          item_name: itemData.item_name,
          item_measure_unit: itemData.item_measure_unit,
          item_category_name: itemData.item_category_name,
          item_sub_category_name: itemData.item_sub_category_name,
          total_shadow_qty: itemData.total_shadow_qty,
          item_qty: procurementItem.item_qty || 0, // Use item_qty from procurement_items if available
        };
      });

      // Update tableData state
      setTableData(newTableData);
    } catch (error) {
      console.error("Error fetching item data:", error);
    }
  };

  useEffect(() => {
    if (selectedRecord.procurement_items.length > 0) {
      fetchProItemData();
    }
  }, [selectedRecord.procurement_items]);

  const handleItemSearch = async (inputValue) => {
    if (inputValue !== "") {
      try {
        const response = await fetch(
          `${
            process.env.REACT_APP_API_ENDPOINT
          }/v1/inventory/item/search?search=${encodeURIComponent(inputValue)}`
        );

        if (!response.ok) {
          throw new Error(`Error fetching data: ${response.statusText}`);
        }

        const data = await response.json();
        console.log("Get items:", data);

        // Extract relevant data from response to populate options
        const options = data.items.map((item) => ({
          value: item.item_id,
          label: `${item.item_id} - ${item.item_name}`, // Combining inventory_batch_id and item_name
          item_measure_unit: item.item_measure_unit,
          item_category_name: item.item_category_name,
          item_sub_category_name: item.item_sub_category_name,
          total_shadow_qty: item.total_shadow_qty,
          item_name: item.item_name,
          item_qty: 0,
        }));

        return options;
      } catch (error) {
        console.error(error);
      }
    } else {
      return [];
    }
  };

  const handleItemSelect = (selectedOption) => {
    setSelectedItem(selectedOption);

    // Check if the item already exists in the tableData
    const itemExists = tableData.some(
      (item) => item.value === selectedOption.value
    );

    if (!itemExists) {
      // Add selected item to tableData
      setTableData((prevTableData) => [
        ...prevTableData,
        {
          item_name: selectedOption.item_name,
          item_id: selectedOption.value,
          item_category_name: selectedOption.item_category_name,
          item_sub_category_name: selectedOption.item_sub_category_name,
          total_shadow_qty: selectedOption.total_shadow_qty,
          item_measure_unit: selectedOption.item_measure_unit,
          item_qty: 0, // Default value; should be updated based on actual available qty
        },
      ]);
    }
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();

      // Add text data to the formData
      formData.append("tender_id", selectedRecord.tender_id);
      formData.append("contract_id", conId);
      formData.append("tender_name", tName);
      formData.append("category", tCategory);
      formData.append("deadline_name", DeadName);
      formData.append("deadline_date", startDate.toISOString().split("T")[0]); // YYYY-MM-DD format
      formData.append("tender_create_user_id", userId);
      formData.append("tender_create_date", formattedDate);

      // Convert procurement items to JSON string
      const procurementItems = tableData.map((item) => ({
        item_id: item.item_id,
        item_qty: item.item_qty,
      }));
      formData.append("procurement_item", JSON.stringify(procurementItems));

      // Add files to the formData
      Object.values(files).forEach((file) => {
        formData.append("tender_document", file);
      });

      // Log formData content for debugging
      for (let pair of formData.entries()) {
        console.log(`${pair[0]}: ${pair[1]}`);
      }

      // Make the PUT request using Axios
      const response = await axios.put(
        `${REACT_APP_API_ENDPOINT}/v1/supplyChain/tender/${selectedRecord.tender_id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Handle the response
      console.log("Response:", response.data);
      alert("Tender successfully updated");

      navigate("/supply/dashboard");
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
      alert("Failed to update tender");
    }
  };

  const handleFileInputChange = (e) => {
    const fileList = e.target.files;
    setFiles((prevFiles) => {
      return [...(prevFiles || []), ...Array.from(fileList)];
    });
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const fileList = e.dataTransfer.files;
    setFiles((prevFiles) => {
      return [...(prevFiles || []), ...Array.from(fileList)];
    });
  };

  // Reset function
  const handleReset = () => {
    setconId("");
    settName("");
    setDeadName("");
    setStartDate("");
    setFiles("");
  };

  const customStylesItems = {
    control: (baseStyles, state) => ({
      // class attribute : class=" css-i32vvf-control"
      ...baseStyles,
      background: "transparent",
      borderRadius: "100px",
      width: "900px",
      borderColor: state.isFocused ? "red" : "blacks",
      border: state.isFocused ? "none" : "none",
    }),
    valueContainer: (provided) => ({
      // class attribute : class=" css-i32vvf-valueContainer"
      ...provided,
      background: "transparent",
    }),
    input: (provided) => ({
      // class attribute : class=" css-i32vvf-input"
      ...provided,
      background: "transparent",
      placeholder: "item name",
      color: "white",
    }),
    singleValue: (provided) => ({
      // class attribute : class=" css-i32vvf-singleValue"
      ...provided,
      background: "transparent",
    }),
    indicatorSeparator: (provided) => ({
      // class attribute : class=" css-i32vvf-indicatorSeparator"
      ...provided,
      background: "transparent",

      borderRadius: "100px",
    }),
    dropdownIndicator: (provided) => ({
      // class attribute : class=" css-i32vvf-dropdownIndicator"
      ...provided,
      background: "transparent",
      display: "none",
    }),

    menu: (provided) => ({
      // 'menu' is from the div class too.
      ...provided,
      background: "white",
      width: "122%",
      position: "absolute",
      zIndex: "100",
      top: "30px",
      left: "-160px",
      borderRadius: "20px",
      overflow: "hidden",
    }),
    Option: (provided) => ({
      ...provided,
      background: "red",
    }),
  };

  const handleDeleteFile = (index) => {
    setFiles((prevFiles) => {
      const updatedFiles = [...(prevFiles || [])];
      updatedFiles.splice(index, 1);
      return updatedFiles;
    });
  };

  return (
    <div className="bg-background overflow-y-auto h-screen relative">
      <div>
        <p className="text-[40px] font-sans ml-6 mt-[1%] font-bold text-[#071C50] ">
          Update Tender
        </p>
      </div>
      <div className="w-full flex items-center justify-center">
        <div className="bg-[#F3F8FF] rounded-[46px] border border-[#00000036] px-[200px] py-11 my-10 flex flex-col items-center justify-center">
          <p className="header-1 text-center">Update Tender</p>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Tender ID</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              type="text"
              value={selectedRecord.tender_id}
              disabled
            />
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Contract ID</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              type="text"
              value={conId}
              disabled
            />
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Tender Name</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              type="text"
              value={tName}
              onChange={(e) => settName(e.target.value)}
            />
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Tender Category</label>
            <label className="label-1">:</label>
            <select
              className="input-1 border border-[#00000036]"
              value={tCategory}
              onChange={(e) => settCategory(e.target.value)}
            >
              <option value="" disabled>
                Select Tender Category
              </option>
              <option value="Production">Production</option>
              <option value="Service">Service</option>
            </select>
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Deadline Name</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              type="text"
              value={DeadName}
              onChange={(e) => setDeadName(e.target.value)}
              placeholder="Enter Deadline Name"
            />
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Deadline Date</label>
            <label className="label-1">:</label>
            <DatePicker
              className="input-1 border border-[#00000036]"
              placeholderText="Enter Deadline Date"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="dd/MM/yyyy"
              minDate={new Date()}
              maxDate={new Date("9999-12-31")}
            />
          </div>
        </div>
      </div>

      <p className="header-1 mb-10">Tender Item Details</p>

      <div className="w-full flex items-center justify-center px-12">
        <div className="bg-PrimaryColor1 w-full h-[82px] rounded-[100px] mx-20 flex items-center justify-between">
          <p className="label-1 ml-10 text-white">Item Name :</p>
          <AsyncSelect
            styles={customStylesItems}
            cacheOptions
            loadOptions={handleItemSearch}
            onMenuOpen={() => setItemArrow(true)}
            onMenuClose={() => setItemArrow(false)}
            onChange={handleItemSelect}
            value={selectedItem}
          />
          <img
            src={Arrow}
            alt="arrow"
            className={
              itemArrow
                ? "mr-7 size-6 transition duration-500 invert"
                : "mr-7 size-6 transition rotate-180 duration-500 invert"
            }
          />
        </div>
      </div>

      <div className="flex flex-col justify-start  bg-[#F3F8FF] rounded-[46px] border border-[#00000036] mt-10">
        <div className="w-full h-[86px] flex items-center justify-around bg-[#BFD8AE] rounded-[46px] mr-5">
          <p className="w-10 p1 text-[#656565] text-center">#</p>
          <div className="w-[350px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center "> Item Name</p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[200px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">
              Available Qty
            </p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[200px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">
              Tender Quantity
            </p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[200px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">
              Item Category
            </p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[200px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">
              Item Sub Category
            </p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[200px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">
              Item Measure Unit
            </p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[200px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">Action</p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
        </div>
        <div className="flex flex-col gap-y-5 my-3 h-[250px] overflow-y-scroll">
          {tableData.length > 0
            ? tableData.map((item, index) => (
                <div key={index} className="w-full  flex justify-around ">
                  <div className="w-10 flex text-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {index + 1}
                    </p>
                  </div>
                  <div className="w-[350px] flex  text-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {item.item_name}
                    </p>
                  </div>
                  <div className="w-[200px] flex  text-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {item.total_shadow_qty}
                    </p>
                  </div>
                  <div className="w-[200px] flex text-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      <input
                        type="number"
                        className="w-full border border-[#797979] rounded-[40px] px-[10px]"
                        value={item.item_qty}
                        onChange={(e) => {
                          let value = e.target.value;

                          let temp = [...tableData];
                          temp[index].item_qty = value;
                          setAdjustedQty(temp);
                        }}
                      />
                    </p>
                  </div>
                  <div className="w-[200px] flex  text-center">
                    <p className="w-full p1 text-[#656565] text-centel ">
                      {item.item_category_name}
                    </p>
                  </div>
                  <div className="w-[200px] flex text-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {item.item_sub_category_name}
                    </p>
                  </div>

                  <div className="w-[200px] flex  text-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {item.item_measure_unit}
                    </p>
                  </div>

                  <div className="w-[200px] flex justify-center text-center">
                    <button
                      className="w-[full] h-[45px] flex items-center justify-center bg-[#912020] border rounded-[23px] px-4"
                      onClick={() => {
                        let temp = [...tableData];
                        temp.splice(index, 1);
                        setTableData(temp);
                      }}
                    >
                      <p className="p2 text-white">delete</p>
                    </button>
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>

      {/* Upload Document part */}
      <p className="text-[40px] font-sans ml-6 mt-[8%] font-bold text-[#656565] ">
        Upload Tender Document
      </p>
      <div className="w-full flex items-center justify-center">
        <div className="bg-white justify-center items-center rounded-[26px] min-h-[400px] w-[40%] h-[80%] flex-shrink-0 mt-5 ml-6">
          <main className="container mx-auto max-w-screen-lg h-full">
            {/* File upload modal */}
            <article className="relative h-full flex flex-col rounded-md">
              {/* Scroll area */}
              <section className="h-full overflow-auto p-8 w-full flex flex-col">
                <div className="flex items-center">
                  <img
                    src={cloudiccon}
                    alt="Cloud Icon"
                    className="h-10 w-10 rounded-full border border-gray-600"
                  />

                  <div>
                    <p className="text-gray-800 font-inter font-medium text-lg ml-2">
                      Upload files
                    </p>
                    <p className="text-dialogSubmenu-500 text-gray-400 font-inter font-medium text-sm ml-2">
                      Select and upload the files of your choice
                    </p>
                  </div>
                </div>
                <hr className="  w-[100%] border-solid border-b-2 border-grayColor mt-2"></hr>

                <header
                  className="border-dashed border-2 bg-dialogboxFileup border-gray-400 py-12 flex flex-col justify-center items-center mt-2 rounded-[26px]"
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                >
                  <p className="mb-3 font-semibold text-gray-900 flex flex-wrap justify-center items-center flex-col">
                    <div>
                      <img
                        src={cloudiccon}
                        alt="Cloud Icon"
                        className="h-10 w-10 rounded-full"
                      />
                    </div>
                    <span>Choose a PDF file or drag & drop it here</span>
                    &nbsp;
                    <span className="text-gray-400">files anywhere or</span>
                  </p>
                  <input
                    id="hidden-input"
                    type="file"
                    multiple
                    className="hidden"
                    onChange={handleFileInputChange}
                  />
                  <button
                    id="button"
                    className="mt-2 rounded-[16px] px-3 py-1 bg-white hover:bg-gray-300 focus:shadow-outline focus:outline-none"
                    onClick={() =>
                      document.getElementById("hidden-input").click()
                    }
                  >
                    Browse File
                  </button>
                </header>

                {/* Uploaded files list */}
                <h1 className="pt-8 pb-3 font-semibold sm:text-lg text-gray-900">
                  Uploaded Files
                </h1>

                <ul className="flex flex-1 flex-wrap -m-1">
                  {Object.values(files).map((file, index) => (
                    <li
                      key={index}
                      className="block p-1 w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/6 xl:w-1/8 h-24"
                    >
                      <article
                        tabIndex="0"
                        className="group w-full h-full rounded-md focus:outline-none focus:shadow-outline elative bg-gray-100 cursor-pointer relative shadow-sm"
                      >
                        <section className="flex flex-col rounded-md text-xs break-words w-full h-full z-20 absolute top-0 py-2 px-3">
                          <h1 className="flex-1 group-hover:text-blue-800">
                            <a
                              href={`${REACT_APP_API_ENDPOINT}/v1/supplyChain/tender/file?filePath=${file.name}`}
                              target="_blank" // Opens the link in a new tab
                              rel="noopener noreferrer" // For security reasons
                              className="flex-1 group-hover:text-blue-800"
                            >
                              {file.name}
                            </a>
                          </h1>
                          <div className="flex">
                            <span className="p-1 text-blue-800">
                              <i>
                                <svg
                                  className="fill-current w-4 h-4 ml-auto pt-1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M15 2v5h5v15h-16v-20h11zm1-2h-14v24h20v-18l-6-6z" />
                                </svg>
                              </i>
                            </span>
                            <p className="p-1 size text-xs text-gray-700">
                              {file.size > 1024
                                ? file.size > 1048576
                                  ? Math.round(file.size / 1048576) + "mb"
                                  : Math.round(file.size / 1024) + "kb"
                                : file.size + "b"}
                            </p>
                          </div>
                          <button
                            onClick={() => handleDeleteFile(index)}
                            className="text-red-500 hover:text-red-700 focus:outline-none"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414L11.414 13l2.293 2.293a1 1 0 01-1.414 1.414L10 14.414l-2.293 2.293a1 1 0 01-1.414-1.414L8.586 13 6.293 10.707a1 1 0 010-1.414z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </button>
                        </section>
                      </article>
                    </li>
                  ))}
                </ul>
              </section>
            </article>
          </main>
        </div>
      </div>
      <div className="w-full flex items-center justify-end gap-x-6 my-10 px-16">
        <button
          className="text-white font-bold text-2xl py-2 px-4 rounded-lg bg-[#AA4343]"
          onClick={handleReset}
        >
          Cancel
        </button>
        <button
          onClick={handleSubmit}
          className="bg-newBackground hover:bg-[#495e33] text-white font-bold text-2xl py-2 px-4 rounded-lg"
        >
          Update
        </button>
      </div>
    </div>
  );
};

export default Update_Tender;
