import React, { useState } from "react";

function AddNewUploadFile({ employee_no, setFetchData, onClose }) {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [showAlertMessage, setShowAlertMessage] = useState(false);
  const { REACT_APP_API_ENDPOINT } = process.env;

  const handleFileUpload = (event) => {
    const files = event.target.files;
    const newUploadedFiles = [...uploadedFiles];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.type === "application/pdf") {
        newUploadedFiles.push(file);
      } else {
        // Show alert message if a file is not a PDF
        setShowAlertMessage(true);
      }
    }

    // Update state with new uploaded files
    setUploadedFiles(newUploadedFiles);
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();

      // Append employee number
      formData.append("employee_no", employee_no);

      // Append each file to formData
      uploadedFiles.forEach((file) => {
        formData.append("employee_upload_files", file);
      });

      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/hris/employees/uploadfiles`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        // File(s) uploaded successfully
        const responseData = await response.json();
        console.log("Files uploaded successfully:", responseData);
        onClose();
        setFetchData((prevKey) => prevKey + 1);
      }
    } catch (error) {
      console.error("Error uploading files", error);
    }
  };

  return (
    <div>
      <div className="flex items-center justify-center  ">
        <div className="w-[100%]  bg-[#ffffff] rounded-xl p-8 mt-5">
          <p className="text-4xl   font-bold text-[#797C80] ">
            Upload Personal Documents
          </p>
          <hr className="line border-t border-gray-300 w-[25%] "></hr>
          <div className="flex items-center justify-center  ">
            <div className="w-[70%] text-sm h-screen-auto bg-white rounded-xl p-8 mt-5 ">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "1rem",
                }}
              >
                <svg
                  viewBox="0 0 24 24"
                  style={{
                    height: "40px",
                    fill: "#000000",
                    marginRight: "1rem",
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10 1C9.73478 1 9.48043 1.10536 9.29289 1.29289L3.29289 7.29289C3.10536 7.48043 3 7.73478 3 8V20C3 21.6569 4.34315 23 6 23H7C7.55228 23 8 22.5523 8 22C8 21.4477 7.55228 21 7 21H6C5.44772 21 5 20.5523 5 20V9H10C10.5523 9 11 8.55228 11 8V3H18C18.5523 3 19 3.44772 19 4V9C19 9.55228 19.4477 10 20 10C20.5523 10 21 9.55228 21 9V4C21 2.34315 19.6569 1 18 1H10ZM9 7H6.41421L9 4.41421V7ZM14 15.5C14 14.1193 15.1193 13 16.5 13C17.8807 13 19 14.1193 19 15.5V16V17H20C21.1046 17 22 17.8954 22 19C22 20.1046 21.1046 21 20 21H13C11.8954 21 11 20.1046 11 19C11 17.8954 11.8954 17 13 17H14V16V15.5ZM16.5 11C14.142 11 12.2076 12.8136 12.0156 15.122C10.2825 15.5606 9 17.1305 9 19C9 21.2091 10.7909 23 13 23H20C22.2091 23 24 21.2091 24 19C24 17.1305 22.7175 15.5606 20.9844 15.122C20.7924 12.8136 18.858 11 16.5 11Z"
                      fill=""
                    ></path>
                  </g>
                </svg>
                <div>
                  <p className="text-1xl mb-1 font-bold text-[#797C80]">
                    Upload File
                  </p>
                  <p className="text-1xl mb-2 font-bold text-[#797C80] opacity-30">
                    Select and upload the files of your choice
                  </p>
                </div>
              </div>

              <hr className="line border-t  border-gray-300 w-[100%] items-center justify-center"></hr>
              <div className="flex items-center justify-center">
                <label
                  htmlFor="file"
                  style={{
                    height: "300px",
                    width: "97%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                    cursor: "pointer",
                    border: "2px dashed #000000",
                    backgroundColor: "#E1E6DC",
                    opacity: "53%",
                    padding: "1.5rem",
                    borderRadius: "10px",
                    boxShadow: "0px 48px 35px -48px #e8e8e8",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <svg
                      viewBox="0 0 24 24"
                      style={{ height: "80px", fill: "#000000" }}
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      {/* SVG Path */}
                    </svg>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ marginBottom: "10px" }}>
                        <span
                          style={{
                            fontWeight: "400",
                            color: "#000000",
                          }}
                        >
                          Choose a PDF file or drag & drop it here
                        </span>
                      </div>
                      <div>
                        <span
                          style={{
                            fontWeight: "400",
                            color: "#000000",
                            opacity: "50%",
                          }}
                        >
                          Up to 50MB
                        </span>
                      </div>
                      <input
                        id="file"
                        type="file"
                        style={{ display: "none" }}
                        multiple
                        onChange={handleFileUpload}
                      />
                      <button
                        type="button"
                        className="rounded-full bg-[#FFFFFF] mb-5 px-3 py-2 text-sm text-black shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 font-sans"
                        style={{ borderRadius: "45px" }}
                        onClick={() => document.getElementById("file").click()}
                      >
                        Browse File
                      </button>
                      {showAlertMessage && (
                        <div className="text-red-600 text-sm2 mt-2  ">
                          Please upload only PDF files.
                        </div>
                      )}
                    </div>
                  </div>
                </label>
              </div>
              <div className="mt-5">
                {uploadedFiles.map((file, index) => (
                  <div key={index} className="flex items-center mt-2">
                    {file.type === "application/pdf" && (
                      <svg
                        height="50px"
                        width="50px"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 56 56"
                      >
                        <path
                          style={{ fill: "#E9E9E0" }}
                          d="M36.985,0H7.963C7.155,0,6.5,0.655,6.5,1.926V55c0,0.345,0.655,1,1.463,1h40.074
      c0.808,0,1.463-0.655,1.463-1V12.978c0-0.696-0.093-0.92-0.257-1.085L37.607,0.257C37.442,0.093,37.218,0,36.985,0z"
                        />
                        <path
                          style={{ fill: "#CC4B4C" }}
                          d="M19.514,33.324L19.514,33.324c-0.348,0-0.682-0.113-0.967-0.326
      c-1.041-0.781-1.181-1.65-1.115-2.242c0.182-1.628,2.195-3.332,5.985-5.068c1.504-3.296,2.935-7.357,3.788-10.75
      c-0.998-2.172-1.968-4.99-1.261-6.643c0.248-0.579,0.557-1.023,1.134-1.215c0.228-0.076,0.804-0.172,1.016-0.172
      c0.504,0,0.947,0.649,1.261,1.049c0.295,0.376,0.964,1.173-0.373,6.802c1.348,2.784,3.258,5.62,5.088,7.562
      c1.311-0.237,2.439-0.358,3.358-0.358c1.566,0,2.515,0.365,2.902,1.117c0.32,0.622,0.189,1.349-0.39,2.16
      c-0.557,0.779-1.325,1.191-2.22,1.191c-1.216,0-2.632-0.768-4.211-2.285c-2.837,0.593-6.15,1.651-8.828,2.822
      c-0.836,1.774-1.637,3.203-2.383,4.251C21.273,32.654,20.389,33.324,19.514,33.324z M22.176,28.198
      c-2.137,1.201-3.008,2.188-3.071,2.744c-0.01,0.092-0.037,0.334,0.431,0.692C19.685,31.587,20.555,31.19,22.176,28.198z
      M35.813,23.756c0.815,0.627,1.014,0.944,1.547,0.944c0.234,0,0.901-0.01,1.21-0.441c0.149-0.209,0.207-0.343,0.23-0.415
      c-0.123-0.065-0.286-0.197-1.175-0.197C37.12,23.648,36.485,23.67,35.813,23.756z M28.343,17.174
      c-0.715,2.474-1.659,5.145-2.674,7.564c2.09-0.811,4.362-1.519,6.496-2.02C30.815,21.15,29.466,19.192,28.343,17.174z
      M27.736,8.712c-0.098,0.033-1.33,1.757,0.096,3.216C28.781,9.813,27.779,8.698,27.736,8.712z"
                        />
                        <path
                          style={{ fill: "#CC4B4C" }}
                          d="M48.037,56H7.963C7.155,56,6.5,55.345,6.5,54.537V39h43v15.537C49.5,55.345,48.845,56,48.037,56z"
                        />
                        <g>
                          <path
                            style={{ fill: "#FFFFFF" }}
                            d="M17.385,53h-1.641V42.924h2.898c0.428,0,0.852,0.068,1.271,0.205
      c0.419,0.137,0.795,0.342,1.128,0.615c0.333,0.273,0.602,0.604,0.807,0.991s0.308,0.822,0.308,1.306
      c0,0.511-0.087,0.973-0.26,1.388c-0.173,0.415-0.415,0.764-0.725,1.046c-0.31,0.282-0.684,0.501-1.121,0.656
      s-0.921,0.232-1.449,0.232h-1.217V53z M17.385,44.168v3.992h1.504c0.2,0,0.398-0.034,0.595-0.103
      c0.196-0.068,0.376-0.18,0.54-0.335c0.164-0.155,0.296-0.371,0.396-0.649c0.1-0.278,0.15-0.622,0.15-1.032
      c0-0.164-0.023-0.354-0.068-0.567c-0.046-0.214-0.139-0.419-0.28-0.615c-0.142-0.196-0.34-0.36-0.595-0.492
      c-0.255-0.132-0.593-0.198-1.012-0.198H17.385z"
                          />
                          <path
                            style={{ fill: "#FFFFFF" }}
                            d="M32.219,47.682c0,0.829-0.089,1.538-0.267,2.126s-0.403,1.08-0.677,1.477s-0.581,0.709-0.923,0.937
      s-0.672,0.398-0.991,0.513c-0.319,0.114-0.611,0.187-0.875,0.219C28.222,52.984,28.026,53,27.898,53h-3.814V42.924h3.035
      c0.848,0,1.593,0.135,2.235,0.403s1.176,0.627,1.6,1.073s0.74,0.955,0.95,1.524C32.114,46.494,32.219,47.08,32.219,47.682z
       M27.352,51.797c1.112,0,1.914-0.355,2.406-1.066s0.738-1.741,0.738-3.09c0-0.419-0.05-0.834-0.15-1.244
      c-0.101-0.41-0.294-0.781-0.581-1.114s-0.677-0.602-1.169-0.807s-1.13-0.308-1.914-0.308h-0.957v7.629H27.352z"
                          />
                          <path
                            style={{ fill: "#FFFFFF" }}
                            d="M36.266,44.168v3.172h4.211v1.121h-4.211V53h-1.668V42.924H40.9v1.244H36.266z"
                          />
                        </g>
                        <polygon
                          style={{ fill: "#D9D7CA" }}
                          points="37.5,0.151 37.5,12 49.349,12 	"
                        />
                      </svg>
                    )}

                    <div>{file.name}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="flex gap-5 justify-end mr-5 mb-10">
            <button
              onClick={onClose}
              className="rounded-full bg-[rgb(171,175,166)] w-[100px] h-[40px]  text-sm text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 font-sans"
            >
              Cancel
            </button>
            <button
              onClick={handleSubmit}
              className="rounded-full bg-newBackground w-[100px] h-[40px]  text-sm text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 font-sans"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddNewUploadFile;
