import React, { useRef, useState } from "react";

function ProfileImageUploader({ onUploadComplete, onClose, employee_no }) {
  const [imagePreview, setImagePreview] = useState(null);
  const [file, setFile] = useState(null);
  const [isImageSelected, setIsImageSelected] = useState(false);
  const fileInputRef = useRef(null);
  const { REACT_APP_API_ENDPOINT } = process.env;

  const handleImageChange = (e) => {
    const file = e.target.files[0]; // Handle files from input
    if (file && file.type.startsWith("image/")) {
      setFile(file);
      setIsImageSelected(true);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUpload = async () => {
    if (!file) {
      alert("Please select an image to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("employee_profile", file); // Ensure the key matches what the backend expects
    formData.append("employee_no", employee_no); // Add employee number to FormData

    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/hris/employees/uploadprofile`,
        {
          method: "POST",
          body: formData,
        }
      );
      const result = await response.json();
      if (response.ok) {
        alert("Upload Complete: " + response.message);

        setIsImageSelected(false); // Reset after upload
        onClose(); // Close the uploader after successful upload
      } else {
        throw new Error(result.message);
      }
    } catch (error) {
      alert("Upload failed: " + error.message);
    }
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="flex items-center justify-center mt-5">
      <div className="bg-white rounded-xl p-8 w-[500px] h-[500px]">
        <p className="text-4xl font-bold text-[#797C80] mb-3">
          Upload Profile Picture
        </p>
        <hr className="border-t border-gray-300 w-[25%]" />
        <div className="relative w-[350px] h-[300px] ml-10">
          {imagePreview ? (
            <>
              <img
                src={imagePreview}
                alt="Profile Preview"
                className="w-[250px] h-[250px] ml-10 mt-5 rounded-full object-cover cursor-pointer"
                onClick={handleImageClick}
              />
              <div
                className="absolute inset-0 w-[250px] h-[250px] ml-10 flex items-center justify-center bg-black bg-opacity-50 rounded-full cursor-pointer opacity-0 transition-opacity duration-300 hover:opacity-100"
                onClick={handleImageClick}
              >
                <p className="text-white text-xl font-semibold">Change Image</p>
              </div>
            </>
          ) : (
            <p className="text-center mt-5 text-sm">No image selected</p>
          )}
          <input
            ref={fileInputRef}
            className="hidden"
            type="file"
            accept="image/*"
            onChange={handleImageChange}
          />
          {!isImageSelected && (
            <div className="flex flex-col items-center mt-5">
              <label className="text-xl font-bold text-[#797C80] mb-2">
                Choose Image
              </label>
              <input
                className="w-48 h-10 rounded-md border border-gray-300 bg-white text-sm text-gray-400 file:border-0 file:bg-[#9CAC8B] file:text-white file:text-sm file:font-medium file:h-10"
                type="file"
                accept="image/*"
                onChange={handleImageChange}
              />
            </div>
          )}
        </div>

        <div className="flex justify-end gap-5 mt-7">
          <button
            onClick={onClose}
            className="rounded-full bg-gray-400 w-24 h-10 text-sm text-white shadow-sm"
          >
            Cancel
          </button>
          <button
            onClick={handleUpload}
            className="rounded-full bg-newBackground w-24 h-10 text-sm text-white shadow-sm"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default ProfileImageUploader;
