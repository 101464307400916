import React, { useState } from "react";
import axios from "axios"; // Import axios for making HTTP requests
import { FaCalendarAlt } from "react-icons/fa";
import DatePicker from "react-datepicker";
import { Link, useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from "react-time-picker";
import cloudIcon from "../../../../../Assets/icons/cloud-add.png";
import { IoCalendarOutline } from "react-icons/io5";
import { format } from "date-fns";

const Opening_Price_Report_Form = () => {
  const [signatureFile, setSignatureFile] = useState(null);
  const [instituteNames, setInstituteNames] = useState([""]);
  const [imagePreview, setImagePreview] = useState(null);
  const [tenderID, setTenderID] = useState(""); // State for Tender ID input
  const [tenderName, setTenderName] = useState(""); // State for Tender Name input
  const [section, setsection] = useState("");
  const [dateOfBid, setdateOfBid] = useState("");
  const [bidTime, setbidTime] = useState("");
  const [reasonTender, setreasonTender] = useState("");
  const [noOfInstitutions, setnoOfInstitutions] = useState("");
  const [noOfFirms, setnoOfFirms] = useState("");
  const [letterFile, setletterFile] = useState("");
  const [corrospondFileNo, setcorrospondFileNo] = useState("");
  const [chequesTobe, setchequesTobe] = useState("");
  const [addressofpayee, setaddressofpayee] = useState("");
  const [name, setname] = useState("");
  const [commiteerole, setcommiteerole] = useState("");
  const [note, setnote] = useState("");
  const { REACT_APP_API_ENDPOINT } = process.env;
  const handleAddInput = () => {
    setInstituteNames([...instituteNames, ""]);
  };

  const handleReset = () => {
    setSignatureFile(null);
    setInstituteNames([""]); // Reset to a single empty input
    setImagePreview(null);
    setTenderID("");
    setTenderName("");
    setsection("");
    setdateOfBid("");
    setbidTime("");
    setreasonTender("");
    setnoOfInstitutions("");
    setnoOfFirms("");
    setletterFile("");
    setcorrospondFileNo("");
    setchequesTobe("");
    setaddressofpayee("");
    setname("");
    setcommiteerole("");
    setnote("");
  };

  const handleInputChange = (index, value) => {
    const updatedInstituteNames = [...instituteNames];
    updatedInstituteNames[index] = value;
    setInstituteNames(updatedInstituteNames);
  };

  const handleRemoveInput = (index) => {
    const updatedInstituteNames = [...instituteNames];
    updatedInstituteNames.splice(index, 1);
    setInstituteNames(updatedInstituteNames);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setSignatureFile(file); // Save the file to state
    const reader = new FileReader();

    reader.onload = () => {
      setImagePreview(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const fetchTenderName = async (id) => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_ENDPOINT}/v1/inventory/tender/search/tender/${id}`
      );
      if (response.data && response.data.length > 0) {
        setTenderName(response.data[0].tender_name);
      } else {
        setTenderName("");
      }
    } catch (error) {
      console.error("Error fetching tender name:", error);
      setTenderName("");
    }
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      const formattedTime = format(bidTime, "HH:mm aa");
      // Add text data to the formData
      formData.append("tender_id", tenderID);
      // formData.append("procurement_item", tenderName);
      formData.append("section", section);
      formData.append(
        "bid_opening_date",
        dateOfBid.toLocaleDateString("en-GB")
      );
      formData.append("bid_opening_time", formattedTime);
      formData.append("reason", reasonTender);
      formData.append("no_of_institutions", noOfInstitutions);
      formData.append("no_of_firms_submitted_prices", noOfFirms);
      formData.append("letter_file", letterFile);
      formData.append("correspondence_file_no", corrospondFileNo);
      formData.append("name_of_cheques", chequesTobe);
      formData.append("name_and_address_of_payee", addressofpayee);
      formData.append("committee_role", commiteerole);
      formData.append("committee_user_name", name);
      instituteNames.forEach((instituteName, index) => {
        formData.append(`institute[${index}][name]`, instituteName);
      });
      formData.append("committee_signature", signatureFile);
      formData.append("note", note);
      formData.append("tender_opening_form_status", "PENDING");
      formData.append("tender_opening_price_form_update_user_id", "EMP-0001");
      formData.append("tender_opening_price_form_update_date", "22/04/2024");

      // Make the POST request using Axios
      const response = await axios.post(
        `${REACT_APP_API_ENDPOINT}/v1/inventory/tender_opening_price_form/add`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Handle the response
      console.log("Response:", response.data);
      alert("Tender successfully added");
      handleReset();
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
      alert("Failed to add tender");
    }
  };

  const handleTenderIDChange = (e) => {
    const id = e.target.value;
    setTenderID(id);
    if (id) {
      fetchTenderName(id);
    } else {
      setTenderName("");
    }
  };

  const [selectedCurrentDate, setSelectedCurrentDate] = useState(new Date());

  return (
    <div className="bg-[#E5EDF9] overflow-y-auto h-screen ">
      <p className="text-[40px] font-sans ml-6 mt-6 font-bold text-[#071C50] ">
        Opening Price Form
      </p>

      <div className="bg-[#F3F8FF] w-[95%]  mt-6 ml-6 rounded-[46px]">
        <div className="mt-4 ml-6 flex">
          <p className=" text-[22px] text-[black] font-bold mt-2 mb-2">
            Tender ID:
          </p>
          <input
            className="rounded-lg border border-gray-300 w-[20%] ml-10 mt-2 mb-2"
            value={tenderID}
            onChange={handleTenderIDChange}
          />
          <p className=" text-[22px] text-[black] font-bold mt-2 mb-2 ml-[20%]">
            Tender Name:
          </p>
          <input
            className="rounded-lg border border-gray-300 w-[20%] ml-10 mt-2 mb-2"
            value={tenderName}
            readOnly
          />
        </div>
      </div>

      {/* Third form part */}
      <div className=" flex">
        {" "}
        <p className="text-[32px] font-sans ml-6 mt-6 font-bold text-[#071C50] ">
          Create Form
        </p>
      </div>
      <div className="bg-[#F3F8FF] w-[95%]  mt-6 ml-6 rounded-[46px] grid grid-cols-2">
        <div className="flex items-center justify-between">
          <p className="text-[22px] text-[#8691AB] font-bold ml-6">Section</p>
          <select
            className="rounded-lg border border-gray-300 w-[40%] ml-10 h-8"
            onChange={(e) => setsection(e.target.value)}
            value={section}
          >
            <option value="option1">Option 1</option>
            <option value="option2">Option 2</option>
            <option value="option3">Option 3</option>
          </select>
        </div>
        <div className="flex items-center justify-between">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6">
            Date of Bid Opening:
          </p>
          <DatePicker
            showIcon
            icon={<IoCalendarOutline className="right-0" />}
            selected={dateOfBid}
            onChange={(date) => setdateOfBid(date)}
          />
        </div>
        <div className="flex items-center justify-between mt-6">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">
            Time of Bid Opening:
          </p>
          <DatePicker
            selected={bidTime}
            onChange={(time) => setbidTime(time)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="h:mm aa"
            className="rounded-lg border border-gray-300 w-[40%] ml-10 h-8"
          />
        </div>
        <div className="flex items-center justify-between mt-6">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">
            Reason of Tender Calling
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[40%] ml-10 h-8"
            onChange={(e) => setreasonTender(e.target.value)}
            value={reasonTender}
          ></input>
        </div>
        <div className="flex items-center justify-between mt-6">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">
            No.of Institutions that call for prices:
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[40%] ml-10 h-8"
            onChange={(e) => setnoOfInstitutions(e.target.value)}
            value={noOfInstitutions}
          ></input>
        </div>
        <div className="flex items-center justify-between mt-6">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">
            Number of Firms submitted prices:
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[40%] ml-10 h-8 mb-6"
            onChange={(e) => setnoOfFirms(e.target.value)}
            value={noOfFirms}
          ></input>
        </div>
        <div className="flex items-center justify-between mt-6">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">
            Letter File:
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[40%] ml-10 h-8 mb-6"
            onChange={(e) => setletterFile(e.target.value)}
            value={letterFile}
          ></input>
        </div>
        <div className="flex items-center justify-between mt-6">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">
            Correspondence File No:
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[40%] ml-10 h-8 mb-6"
            onChange={(e) => setcorrospondFileNo(e.target.value)}
            value={corrospondFileNo}
          ></input>
        </div>
        <div className="flex items-center justify-between mt-6">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">
            Name of the Cheques are to be Written:
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[40%] ml-10 h-8 mb-6"
            onChange={(e) => setchequesTobe(e.target.value)}
            value={chequesTobe}
          ></input>
        </div>
        <div className="flex items-center justify-between mt-6">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">
            Name and the Address of the Payee:
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[40%] ml-10 h-8 mb-6"
            onChange={(e) => setaddressofpayee(e.target.value)}
            value={addressofpayee}
          ></input>
        </div>
      </div>

      {/* Add Institutes */}
      <p className="text-[32px] font-sans ml-6 mt-6 font-bold text-[#071C50] ">
        Add Institues
      </p>

      <div className="bg-[#F3F8FF] w-[95%] mt-6 ml-6 rounded-[46px]">
        {instituteNames.map((instituteName, index) => (
          <div key={index} className="flex items-center mt-4 ml-6">
            <p className="text-[22px] text-black font-bold mt-2 mb-2">
              Institute Name:
            </p>
            <input
              className="rounded-lg border border-gray-300 w-[50%] ml-10 mt-2 mb-2"
              value={instituteName}
              onChange={(e) => handleInputChange(index, e.target.value)}
            />
            {index === instituteNames.length - 1 && (
              <button
                className="bg-newBackground rounded-[33px] h-10 w-20 text-white font-bold ml-2"
                onClick={handleAddInput}
              >
                Add
              </button>
            )}
            {index !== 0 && (
              <button
                className="bg-red-500 rounded-lg h-10 w-20 text-white font-bold ml-2"
                onClick={() => handleRemoveInput(index)}
              >
                Remove
              </button>
            )}
          </div>
        ))}
      </div>

      {/* Fourth form part */}
      <>
        {" "}
        <p className="text-[32px] font-sans ml-6 mt-6 font-bold text-[#071C50] ">
          Tender Opening Committee
        </p>
      </>

      <div className="bg-[#F3F8FF] w-[95%]  mt-6 ml-6 rounded-[46px] justify-between">
        <div className="flex ">
          <div className="min-w-[50%]">
            <div className="flex">
              <p className=" text-[22px] text-[#8691AB] font-bold ml-6 mt-6">
                Name
              </p>
              <input
                className=" rounded-lg border border-gray-300 w-[90%] ml-10 h-8 mt-6"
                onChange={(e) => setname(e.target.value)}
                value={name}
              ></input>
            </div>
            <div className="flex items-center">
              <p className=" text-[22px] text-[#8691AB] font-bold ml-6 mt-6">
                Committee Role
              </p>
              <input
                className=" rounded-lg border border-gray-300 w-[90%] ml-10 h-8 mt-6"
                onChange={(e) => setcommiteerole(e.target.value)}
                value={commiteerole}
              ></input>
            </div>
          </div>
          {/* Yes NO  */}

          <div className=" ml-[10%]">
            {/* Drag and drop area */}
            <div
              className="flex flex-col items-center justify-center mt-6"
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              {imagePreview ? (
                <img
                  src={imagePreview}
                  alt="Preview"
                  className="w-40 h-40 rounded-lg mb-4"
                />
              ) : (
                <div className="bg-white border border-gray-300 w-50 h-60 rounded-lg flex flex-col items-center justify-center mb-6">
                  <p className="text-black font-bold ml-2 mt-2 text-[24px]">
                    Add Signature
                  </p>
                  <img
                    src={cloudIcon}
                    alt="Cloud Icon"
                    className="w-16 h-16 mb-2"
                  />
                  <p className="text-[#8691AB] font-bold ml-4">
                    Drag and Drop Digital Signature
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <p className="text-[32px] font-sans ml-6 mt-6 font-bold text-[#071C50] ">
        Other Note
      </p>

      <div className="bg-[#F3F8FF] w-[95%]  mt-6 ml-6 rounded-[46px]">
        <div className="mt-4 ml-6 flex">
          <p className=" text-[22px] text-[#8691AB] font-bold mt-2 mb-2">
            Note:
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[80%] ml-10 mt-2 mb-2"
            onChange={(e) => setnote(e.target.value)}
            value={note}
          ></input>
        </div>
      </div>

      <div className="mb-6 mt-6 mr-10 flex justify-end">
        <button
          className="bg-newBackground text-[20px] font-bold text-white rounded-[33px] w-[8%] h-8"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default Opening_Price_Report_Form;
