import React, { useState } from "react";
import axios from "axios";

export default function AddTraningType() {
  const [formData, setFormData] = useState({
    training_type: "",
    training_type_description: "",
  });
  const { REACT_APP_API_ENDPOINT } = process.env;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleAddTraningType = async () => {
    try {
      const response = await axios.post(
        `${REACT_APP_API_ENDPOINT}/v1/hris/trainingtype/addtrainingtype`,
        formData
      );
      console.log("Traning Type added successfully:", response.data);
      // Optionally, you can perform additional actions upon successful addition
    } catch (error) {
      console.error("Error adding Traning Type:", error);
      // Handle error (e.g., show error message to user)
    }
  };

  return (
    <div className="p-10 bg-background h-screen overflow-y-auto">
      <p className="header-1">Add Designation</p>
      <div className="w-full flex items-center justify-center">
        <div className="bg-[#F3F8FF] rounded-[46px] border border-[#00000036] px-[200px] py-11 my-10 flex flex-col items-center justify-center">
          <p className="header-1 text-center">Enter Training Type Details</p>

          <div className="w-[600px] flex items-center justify-between my-10">
            <p className="label-1">Training Type</p>
            <p className="label-1">:</p>
            <input
              className="input-1 border border-[#00000036]"
              name="training_type"
              onChange={handleInputChange}
            />
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <p className="label-1">Training Type Description</p>
            <p className="label-1">:</p>
            <input
              className="input-1 border border-[#00000036]"
              name="training_type_description"
              onChange={handleInputChange}
            />
          </div>
        </div>
      </div>
      <div className="flex items-center justify-end gap-x-6 mb-10 px-[280px]">
        <button
          className="w-[118px] h-[45px] flex items-center justify-center bg-[#ab2626] border rounded-[23px] px-4"
          onClick={() => {
            // Optionally handle cancel action here
          }}
        >
          <p className="p2 text-white">Cancel</p>
        </button>
        <button
          className="w-[118px] h-[45px] flex items-center justify-center bg-newBackground border rounded-[23px] px-4"
          onClick={handleAddTraningType}
        >
          <p className="p2 text-white">Add</p>
        </button>
      </div>
    </div>
  );
}
